


















































































































import { getFormStepMixin } from '@/mixins/formStepMixin';
import { CheckAndSendFormStep } from '@/types/forms/formSteps/checkAndSendFormStep';
import { CheckAndSendFormStepContent } from '@/api/interfaces/content/form/steps/checkAndSendFormStepContent';
import { FormStepConfiguration } from '@/api/interfaces/configuration/form/base/formStepConfiguration';
import ButtonSpacer from '@/components/molecules/buttonSpacer/ButtonSpacer.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormInputMultiLineText from '@/components/atoms/formInputMultiLineText/FormInputMultiLineText.vue';
import DocumentDownload from '@/components/atoms/documentDownload/DocumentDownload.vue';
import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import { LabelContent } from '@/api/interfaces/content/form/base/labelContent';
import { CheckboxContent } from '@/api/interfaces/content/form/base/checkboxContent';
import { isLowVoltage } from '@/services/form/rules/application/common';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';

export default getFormStepMixin<
    CheckAndSendFormStep,
    CheckAndSendFormStepContent,
    FormStepConfiguration
>().extend({
    name: 'CheckAndSendForm',
    components: {
        FormField,
        ButtonSpacer,
        FormInputText,
        FormInputMultiLineText,
        DocumentDownload,
        FormBooleanCheckbox,
        FormTextBlock,
    },
    computed: {
        generalTermsConsentContent(): LabelContent & CheckboxContent {
            return isLowVoltage(
                (this.formModel as ApplicationForm).powerConnection,
            )
                ? this.content.generalTermsConsentNS
                : this.content.generalTermsConsentMS;
        },
    },
});
