











































import { PropertyOwnerFormStepContent } from '@/api/interfaces/content/form/steps/propertyOwnerFormStepContent';
import { getFormStepMixin } from '@/mixins/formStepMixin';
import FormField from '@/components/molecules/formField/FormField.vue';
import ButtonSpacer from '@/components/molecules/buttonSpacer/ButtonSpacer.vue';
import OwnerFormList from '@/components/organisms/ownerFormList/OwnerFormList.vue';
import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import type { PropertyOwnerFormStep } from '@/types/forms/formSteps/propertyOwnerFormStep';
import { FormStepConfiguration } from '@/api/interfaces/configuration/form/base/formStepConfiguration';

export default getFormStepMixin<
    PropertyOwnerFormStep,
    PropertyOwnerFormStepContent,
    FormStepConfiguration
>().extend({
    name: 'PropertyOwnerForm',
    components: {
        FormField,
        FormBooleanCheckbox,
        ButtonSpacer,
        OwnerFormList,
    },
});
