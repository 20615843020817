import { CheckboxListContent } from '@/api/interfaces/content/form/base/checkboxListContent';
import { LabelContent } from '@/api/interfaces/content/form/base/labelContent';
import { CheckboxesField } from '@/types/forms/fields/checkboxesField';

export function getCheckboxListSummaryValue<TFieldValue extends string>(
    field: CheckboxesField<TFieldValue>,
    content: LabelContent & CheckboxListContent,
): string {
    const selectedOptions = field.value;
    if (!selectedOptions) {
        return '';
    }

    return Object.keys(content.options)
        .filter((option) => selectedOptions.includes(option as TFieldValue))
        .map((option) => content.options[option].label)
        .join(', ');
}
