var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.model.disabled)?_c('div',[_c('FormField',{staticClass:"storage-manufacturer-storage-type",attrs:{"model":_vm.model.storageManufacturerAndStorageType,"content":_vm.content.storageManufacturerAndStorageType,"validations":_vm.validationProps.storageManufacturerAndStorageType,"show-summary":_vm.showSummary,"summary-value":_vm._f("concat")([
                _vm.model.storageManufacturerAndStorageType.manufacturer.value,
                ', ',
                _vm.model.storageManufacturerAndStorageType.storageType.value ])}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/2 mr-4 md:mr-10"},[_c('FormInputText',{attrs:{"model":_vm.model.storageManufacturerAndStorageType.manufacturer,"content":_vm.content.storageManufacturerAndStorageType
                            .manufacturer,"validations":_vm.storageManufacturerAndStorageTypeValidations.manufacturer}})],1),_c('div',{staticClass:"w-1/2"},[_c('FormInputText',{attrs:{"model":_vm.model.storageManufacturerAndStorageType.storageType,"content":_vm.content.storageManufacturerAndStorageType
                            .storageType,"validations":_vm.storageManufacturerAndStorageTypeValidations.storageType}})],1)])]),_c('FormField',{staticClass:"storage-quantity",attrs:{"model":_vm.model.storageQuantity,"content":_vm.content.storageQuantity,"validations":_vm.validationProps.storageQuantity,"show-summary":_vm.showSummary}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/2 mr-4 md:mr-10"},[_c('FormInputCount',{attrs:{"model":_vm.model.storageQuantity,"content":_vm.content.storageQuantity,"validations":_vm.validationProps.storageQuantity}})],1),_c('div',{staticClass:"w-1/2"})])]),_c('FormField',{staticClass:"storage-discharge-power-and-storage-capacity",attrs:{"model":_vm.model.storageDischargePowerAndStorageCapacity,"content":_vm.content.storageDischargePowerAndStorageCapacity,"validations":_vm.validationProps.storageDischargePowerAndStorageCapacity,"show-summary":_vm.showSummary,"summary-value":_vm._f("concat")([
                _vm.model.storageDischargePowerAndStorageCapacity.dischargePower
                    .value,
                ' ',
                _vm.content.storageDischargePowerAndStorageCapacity
                    .dischargePower.unit,
                ', ',
                _vm.model.storageDischargePowerAndStorageCapacity
                    .storageCapacity.value,
                ' ',
                _vm.content.storageDischargePowerAndStorageCapacity
                    .storageCapacity.unit ])}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/2 mr-4 md:mr-10"},[_c('FormInputText',{attrs:{"model":_vm.model.storageDischargePowerAndStorageCapacity
                            .dischargePower,"content":_vm.content.storageDischargePowerAndStorageCapacity
                            .dischargePower,"validations":_vm.storageDischargePowerAndStorageCapacityValidations.dischargePower,"align-text":"right"}})],1),_c('div',{staticClass:"w-1/2"},[_c('FormInputText',{attrs:{"model":_vm.model.storageDischargePowerAndStorageCapacity
                            .storageCapacity,"content":_vm.content.storageDischargePowerAndStorageCapacity
                            .storageCapacity,"validations":_vm.storageDischargePowerAndStorageCapacityValidations.storageCapacity,"align-text":"right"}})],1)])]),_c('FormField',{staticClass:"storage-total-discharge-power",attrs:{"model":_vm.model.storageTotalDischargePower,"content":_vm.content.storageTotalDischargePower,"validations":_vm.validationProps.storageTotalDischargePower,"show-summary":_vm.showSummary,"summary-value":_vm.totalDischargePowerDisplayValue}},[_c('span',{staticClass:"storage-total-discharge-power-display"},[_vm._v(" "+_vm._s(_vm.totalDischargePowerDisplayValue)+" ")])]),_c('FormField',{staticClass:"storage-total-storage-capacity",attrs:{"model":_vm.model.storageTotalStorageCapacity,"content":_vm.content.storageTotalStorageCapacity,"validations":_vm.validationProps.storageTotalStorageCapacity,"show-summary":_vm.showSummary,"summary-value":_vm.totalStorageCapacityDisplayValue}},[_c('span',{staticClass:"storage-total-storage-capacity-display"},[_vm._v(" "+_vm._s(_vm.totalStorageCapacityDisplayValue)+" ")])]),(!_vm.showSummary && _vm.showRemoveButton)?_c('FunctionalityButton',{staticClass:"storage-remove-button",attrs:{"content":_vm.content.storageAddRemoveModule,"value":_vm.functionalityButtonValue},on:{"click":function($event){return _vm.$emit('functionality-button-click', $event)}}}):_vm._e(),(_vm.showSummary)?_c('div',{staticClass:"h-4"}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }