






















import Vue from 'vue';
import ApplicationFormTemplate from '@/components/templates/applicationFormTemplate/ApplicationFormTemplate.vue';
import { modelService } from '@/services/form/modelService';
import { rules } from '@/services/form/rules';
import { ApplicationFormContent } from '@/api/interfaces/content/form/applicationFormContent';
import {
    createFormValidations,
    ValidationsCommonType,
    ValidationsValueType,
} from '@/services/form/validations';
import { contentServices } from '@/services/contentService';
import { configurationServices } from '@/services/configurationService';
import SpinnerArea from '@/components/molecules/spinnerArea/SpinnerArea.vue';
import { SpinnerState } from '@/enums/spinnerState';
import { Forms } from '@/types/forms/forms';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { mergeWith as _mergeWith, mapValues as _mapValues } from 'lodash';
import { formsConfig } from '@/services/form/config';
import { applicationRepository } from '@/api';
import { Validation } from 'vuelidate';
import { ValidationProperties } from 'vue/types/vue';
import { validate as uuidValidate, v4 as uuidv4 } from 'uuid';
import { ApplicationFormConfiguration } from '@/api/interfaces/configuration/form/applicationFormConfiguration';
import { FormStepConfiguration } from '@/api/interfaces/configuration/form/base/formStepConfiguration';
import { FormFieldConfiguration } from '@/api/interfaces/configuration/form/base/formFieldConfiguration';
import { FileUploadConfiguration } from '@/api/interfaces/configuration/form/base/fileUploadConfiguration';
import { appConfiguration } from '@/services/form/appConfiguration';
import { debugDto } from '@/debug/form/application';
import { convertFormConfigToFormDto, FormDto } from '@/services/form/dto';
import { authService } from '@/services/authService';
import { ServiceSuccess } from '@/api/interfaces/serviceSuccess';
import { ServiceError } from '@/api/interfaces/serviceError';
import { dialogueMessageService } from '@/services/dialogueMessageService';
import router from '@/router';
import { MessageType } from '@/enums/messageType';
import { MessageDialogueActions } from '@/enums/messageDialogueActions';
import { mkfServices } from '@/services/mkfService';
import { Mkf } from '@/api/interfaces/mkf/mkf';
import { applyApplicationEditActions } from '@/services/form/editActions/application';
import { alertService } from '@/services/alertService';

export default Vue.extend({
    name: 'ApplicationForm',
    components: {
        ApplicationFormTemplate,
        SpinnerArea,
    },
    data() {
        return {
            spinner: SpinnerState.opaque,
            applicationFormContent: null as ApplicationFormContent | null,
            applicationFormConfiguration: null as ApplicationFormConfiguration | null,
            rawEditModel: {} as Forms,
            rawEditValidations: {} as ValidationsCommonType,
            mkf: null as Mkf | null,
            contentLoaded: false,
        };
    },
    computed: {
        model(): ApplicationForm {
            return modelService.model.application;
        },
        debugDto(): FormDto {
            return authService.isAuthenticated &&
                authService.hasRoles('DEA Debug')
                ? debugDto
                : {};
        },
        dto(): FormDto {
            return convertFormConfigToFormDto(this.model);
        },
        formsConfig(): ApplicationForm {
            return formsConfig.application;
        },
        editModel(): ApplicationForm {
            return this.rawEditModel.application;
        },
        rules() {
            return rules.application;
        },
        validations(): Validation & ValidationProperties<unknown> {
            return modelService.validations.application;
        },
        editValidations(): ValidationsValueType {
            return this.rawEditValidations.application;
        },
        currentStepIndex(): number {
            return this.model.enabledSteps.indexOf(this.model.currentStep);
        },
        maxReachedStepIndex(): number {
            return this.model.enabledSteps.indexOf(this.model.maxReachedStep);
        },
    },
    created(): void {
        modelService.resetModel(this);
        this.resetEditModels();
    },
    async mounted(): Promise<void> {
        alertService.clear();

        try {
            const content = await contentServices.form.requestContentOnce();
            this.applicationFormContent = content.applicationFormContent;

            const configuration = await configurationServices.form.requestConfigurationOnce();
            this.applicationFormConfiguration =
                configuration.applicationFormConfiguration;
            this.prepareUploadFieldValidations();

            this.mkf = await mkfServices.mkf.requestDataOnce();

            this.contentLoaded =
                !!this.applicationFormContent &&
                !!this.applicationFormConfiguration &&
                !!this.mkf;
        } catch (error) {
            router.push('/fehler');
        }

        if (
            !this.$route.params.applicationId &&
            this.model.currentStep === '_init_'
        ) {
            this.model.applicationId = uuidv4();
            this.$router.replace(
                `${this.$route.path}/${this.model.applicationId}`,
            );
            await applicationRepository.saveApplication(this.model);
        }

        if (this.$route.params.applicationId) {
            if (uuidValidate(this.$route.params.applicationId)) {
                await applicationRepository
                    .getApplicationData(this.$route.params.applicationId)
                    .then(async (response) => {
                        response = modelService.migrateApplicationFormData(
                            response,
                        );
                        modelService.loadFormIntoModel(response, 'application');

                        // wait for rules to possibly disable steps
                        await this.rules.general(this.model);

                        this.advanceStep();
                    })
                    .catch((error: ServiceError) => {
                        this.showErrorMessageInDialogue(error.messageCode);
                    });
            } else {
                this.showErrorMessageInDialogue('applications.notFound');
            }
        }

        this.spinner = SpinnerState.off;
        this.scrollToStep();
    },
    methods: {
        prepareUploadFieldValidations(): void {
            _mapValues(
                this.applicationFormConfiguration,
                (formStep: FormStepConfiguration) => {
                    _mapValues(formStep, (field: FormFieldConfiguration) => {
                        const fileUploadConfiguration =
                            ((field.configurations.find(
                                (c) => c.type === 'fileUploadConfiguration',
                            ) as unknown) as FileUploadConfiguration) ?? null;
                        if (fileUploadConfiguration) {
                            appConfiguration.fileUploadConfigurations[
                                fileUploadConfiguration.scId
                            ] = fileUploadConfiguration.validations;
                        }
                    });
                },
            );
        },
        selectStep(stepId: string): void {
            this.model.currentStep = stepId;
            if (this.currentStepPassesMaxReachedStep()) {
                this.model.maxReachedStep = this.model.currentStep;
            }
        },
        currentStepPassesMaxReachedStep(): boolean {
            return this.currentStepIndex > this.maxReachedStepIndex;
        },
        resetEditModels(): void {
            this.rawEditModel = modelService.getModelClone();
            this.rawEditValidations = createFormValidations(
                this.rawEditModel,
                this,
            );
        },
        async submitForm(
            cb: (promise: Promise<ServiceSuccess>) => void,
        ): Promise<void> {
            if (this.model.editStep) {
                // apply edit actions, like calculating values after editing etc.
                applyApplicationEditActions({
                    formModel: this.model,
                    editModel: this.editModel,
                });

                // load data for edited step into real model
                _mergeWith(
                    this.model[this.model.editStep as keyof ApplicationForm],
                    this.editModel[
                        this.model.editStep as keyof ApplicationForm
                    ],
                );

                // // remove all data after current step
                // modelService.removeDataAfterCurrentStep(
                //     this.model.editStep,
                //     'application',
                // );

                // reset disabled state after current step
                modelService.resetStateAfterCurrentStep(
                    this.model.editStep,
                    'application',
                    this,
                );

                // reset current step
                this.model.currentStep = this.model.editStep;
                this.model.editStep = null;

                // reset edit model and validations, so we could edit again
                this.resetEditModels();
            }

            await this.rules.general(this.model);

            const saveServiceResponse = applicationRepository.saveApplication(
                this.model,
            );
            saveServiceResponse
                .then(async () => {
                    if (!this.advanceStep()) {
                        const sendServiceResponse = applicationRepository.sendApplication(
                            this.model,
                        );
                        sendServiceResponse
                            .then(() => {
                                // if there is no next step, call cb here to handle sendService success
                                cb(sendServiceResponse);

                                router.push('/danke');
                            })
                            .catch(() => {
                                // if there is no next step, call cb here to handle sendService error
                                cb(sendServiceResponse);
                            });
                    } else {
                        // if there is a next step, call cb here to handle saveService success
                        cb(saveServiceResponse);
                    }
                })
                .catch(() => {
                    // if saveService fails, we call cb here, to handle saveService error.
                    cb(saveServiceResponse);
                })
                .finally(this.scrollToStep);
        },
        advanceStep(): boolean {
            if (!this.hasNextStep()) {
                return false;
            }

            const nextStepId = this.model.enabledSteps[
                this.currentStepIndex + 1
            ];

            this.selectStep(nextStepId);
            return true;
        },
        hasNextStep(): boolean {
            return this.model.enabledSteps.length > this.currentStepIndex + 1;
        },
        editStep(identifier: string): void {
            this.model.editStep = identifier;
            this.resetEditModels();
        },
        cancelEdit(): void {
            this.model.editStep = null;
            this.resetEditModels();
        },
        showErrorMessageInDialogue(identifier: string) {
            dialogueMessageService.show(
                identifier,
                MessageType.error,
                MessageDialogueActions.toSafety |
                    MessageDialogueActions.newApplication,
            );
        },
        async scrollToStep() {
            await this.$nextTick();
            const step = document.querySelector(
                '.scroll-to-step',
            ) as HTMLElement;

            if (step) {
                step.scrollIntoView();
            }
        },
    },
});
