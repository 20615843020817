import Vue from 'vue';
import { mkfRepository } from '@/api';
import { Mkf } from '@/api/interfaces/mkf/mkf';

class MkfService<T> {
    private state = Vue.observable({
        currentPromise: null as Promise<T> | null,
        loadedData: null as T | null,
    });

    public constructor(private readonly getDataPromise: () => Promise<T>) {}

    public get hasDataLoaded(): boolean {
        return !!this.state.loadedData;
    }

    public get isDataLoading(): boolean {
        return !this.state.loadedData && !this.state.currentPromise;
    }

    public get data(): T | null {
        this.requestDataOnce();
        return this.state.loadedData;
    }

    public async requestDataOnce(): Promise<T> {
        if (this.state.loadedData) {
            return this.state.loadedData;
        }

        if (this.state.currentPromise) {
            return await this.state.currentPromise;
        }

        return await this.requestData();
    }

    public async requestData(): Promise<T> {
        this.state.currentPromise = this.getDataPromise();
        this.state.loadedData = await this.state.currentPromise;
        this.state.currentPromise = null;
        return this.state.loadedData;
    }
}

export const mkfServices = {
    mkf: new MkfService<Mkf>(() => mkfRepository.getMkfData()),
};
