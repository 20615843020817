
























import Vue from 'vue';
import FormLabel from '@/components/atoms/formLabel/FormLabel.vue';
import FormInputCheckbox from '@/components/atoms/formInputCheckbox/FormInputCheckbox.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { FormOption } from '@/types/forms/formOption';
import { CheckboxesField } from '@/types/forms/fields/checkboxesField';
import { CheckboxListContent } from '@/api/interfaces/content/form/base/checkboxListContent';
import { LabelContent } from '@/api/interfaces/content/form/base/labelContent';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';

export default getFormInputMixin<
    CheckboxesField<string>,
    CheckboxListContent
>().extend({
    name: 'FormCheckboxList',
    components: {
        FieldWithInfoIcon,
        FormLabel,
        FormInputCheckbox,
    },
    computed: {
        activeOptionsWithContent(): (FormOption<string> & {
            content: LabelContent;
        })[] {
            return this.model.options
                .filter((option) => !option.disabled)
                .map((option) => ({
                    ...option,
                    content: this.content.options[option.key],
                }));
        },
        /*
         * This computed property is used as a model for the checkboxes instead
         * of this.model to ensure that the checkboxes work on a list, even if
         * the model value is null in case the user hasn't made any inputs yet.
         *
         * The watcher then passes changes on to the actual model.
         */
        modelWithListValue(): CheckboxesField<string> {
            if (this.model.value !== null) {
                return this.model;
            }

            const fallbackModel = Vue.observable(
                new CheckboxesField<string>({
                    ...this.model,
                    value: [],
                }),
            );

            this.$watch(
                () => fallbackModel.value,
                () => (this.model.value = fallbackModel.value),
            );

            return fallbackModel;
        },
    },
});
