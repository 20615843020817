
































import { GeneratorModuleFormListContent } from '@/api/interfaces/content/form/steps/plantPerformance/generatorContent';
import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import GeneratorModuleFormGroup from '@/components/organisms/plantPerformance/generatorModuleFormGroup/GeneratorModuleFormGroup.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { GeneratorModuleFormGroup as GeneratorModuleFormGroupType } from '@/types/forms/formGroups/plantPerformance/generator/generatorModuleFormGroup';
import { GeneratorModuleFormList } from '@/types/forms/formGroups/plantPerformance/generator/generatorModuleFormList';

export default getFormInputMixin<
    GeneratorModuleFormList,
    GeneratorModuleFormListContent
>().extend({
    name: 'GeneratorModuleFormList',
    components: {
        FunctionalityButton,
        GeneratorModuleFormGroup,
    },
    props: {
        applicationId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            functionalityButtonValue: 'add' as FunctionalityButtonValueType,
        };
    },
    computed: {
        canAddItem(): boolean {
            return this.model.canAddItem();
        },
        canRemoveItem(): boolean {
            return this.model.canRemoveItem();
        },
    },
    methods: {
        handleFunctionalityButtonClick(): void {
            this.model.createItem();
        },
        handleModuleFunctionalityButtonClick(
            value: FunctionalityButtonValueType,
            generator: GeneratorModuleFormGroupType,
        ): void {
            if (value === 'remove') {
                this.model.removeItem(generator);
            }
        },
    },
});
