































import Vue, { PropType } from 'vue';
import FormRow from '@/components/molecules/formRow/FormRow.vue';
import InfoIconWrapper from '@/components/molecules/infoIconWrapper/InfoIconWrapper.vue';
import { FunctionalityButtonContent } from '@/api/interfaces/content/form/base/functionalityButtonContent';

export type FunctionalityButtonValueType = 'add' | 'remove';

export default Vue.extend({
    name: 'FunctionalityButton',
    components: {
        FormRow,
        InfoIconWrapper,
    },
    props: {
        content: {
            type: Object as PropType<FunctionalityButtonContent>,
            required: true,
        },
        value: {
            type: String as PropType<FunctionalityButtonValueType>,
            required: true,
        },
        showInfoIcon: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        label(): string {
            return this.value === 'add'
                ? this.content.addLabel
                : this.content.removeLabel;
        },
    },
    methods: {
        handleClick(): void {
            this.$emit('click', this.value);
        },
    },
});
