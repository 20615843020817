import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { measuringConceptsEditActions } from './application/measuringConcepts';

export type EditActionContext = {
    formModel: ApplicationForm;
    editModel: ApplicationForm;
};

export const applyApplicationEditActions = (
    context: EditActionContext,
): void => {
    measuringConceptsEditActions(context);
};
