




































































































































































































































































































































































































































































































































































































































































































import { PlantDataFormStepContent } from '@/api/interfaces/content/form/steps/plantDataFormStepContent';
import { getFormStepMixin } from '@/mixins/formStepMixin';
import { PlantDataFormStep } from '@/types/forms/formSteps/plantDataFormStep';
import { FormStepConfiguration } from '@/api/interfaces/configuration/form/base/formStepConfiguration';
import ButtonSpacer from '@/components/molecules/buttonSpacer/ButtonSpacer.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormRadioGroup from '@/components/molecules/formRadioGroup/FormRadioGroup.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import DatePicker from '@/components/molecules/datePicker/DatePicker.vue';
import FormFileUpload from '@/components/molecules/formFileUpload/FormFileUpload.vue';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import FormSelect from '@/components/molecules/formSelect/FormSelect.vue';
import { LabelContent } from '@/api/interfaces/content/form/base/labelContent';
import { RadioButtonContent } from '@/api/interfaces/content/form/base/radioButtonContent';
import { isStorage } from '@/services/form/rules/application/common';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import InfeedManagementFormGroup from '@/components/organisms/plantDataForm/infeedManagementFormGroup/InfeedManagementFormGroup.vue';

export default getFormStepMixin<
    PlantDataFormStep,
    PlantDataFormStepContent,
    FormStepConfiguration
>().extend({
    name: 'PlantDataForm',
    components: {
        FormField,
        FormRadioGroup,
        ButtonSpacer,
        FormInputText,
        FormBooleanCheckbox,
        DatePicker,
        FormFileUpload,
        FormTextBlock,
        FormSelect,
        InfeedManagementFormGroup,
    },
    computed: {
        getFilteredPlantOptions(): LabelContent & RadioButtonContent {
            if (isStorage(this.formModel as ApplicationForm)) {
                return this.content.newOrExistingPlantStorage;
            } else {
                return this.content.newOrExistingPlant;
            }
        },
        prefillStorageChargingAndDischarging(): unknown[] {
            return [
                this.model.storageCoupling.value,
                this.model.storageConnectionConcept.value,
            ];
        },
    },
    watch: {
        prefillStorageChargingAndDischarging: {
            handler(): void {
                if (
                    this.model.storageCoupling.value == 'dcCoupled' &&
                    this.model.storageConnectionConcept.value ==
                        'powerGenerationPlantWithStorageFacilityInTheGenerationPathAndConsumptionPlant' &&
                    !this.model.storageCharge.value &&
                    !this.model.storageDischarge.value
                ) {
                    this.model.storageCharge.value = 'onlyFromEeg';
                    this.model.storageDischarge.value =
                        'onlyIntoCustomersGridSelfSupply';
                }
            },
            immediate: true,
        },
    },
});
