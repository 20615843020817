








import Vue from 'vue';

export default Vue.extend({
    name: 'FormLabel',
    props: {
        text: {
            type: String,
            default: null,
        },
    },
});
