

































import { PropType } from 'vue';
import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import InverterModuleFormGroup from '@/components/organisms/plantPerformance/inverterModuleFormGroup/InverterModuleFormGroup.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import type { InverterModuleFormList } from '@/types/forms/formGroups/plantPerformance/inverter/inverterModuleFormList';
import type { InverterModuleFormListContent } from '@/api/interfaces/content/form/steps/plantPerformance/inverterContent';
import type { InverterModuleFormGroup as InverterModuleFormGroupType } from '@/types/forms/formGroups/plantPerformance/inverter/inverterModuleFormGroup';
import { ApplicationType } from '@/types/forms/specific/applicationForm';

export default getFormInputMixin<
    InverterModuleFormList,
    InverterModuleFormListContent
>().extend({
    name: 'InverterModuleFormList',
    components: {
        FunctionalityButton,
        InverterModuleFormGroup,
    },
    props: {
        applicationId: {
            type: String,
            required: true,
        },
        applicationType: {
            type: String as PropType<ApplicationType>,
            required: true,
        },
        isPluginGeneratingPlant: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            functionalityButtonValue: 'add' as FunctionalityButtonValueType,
        };
    },
    computed: {
        canAddItem(): boolean {
            return this.model.canAddItem() && !this.isPluginGeneratingPlant;
        },
        canRemoveItem(): boolean {
            return this.model.canRemoveItem();
        },
    },
    methods: {
        handleFunctionalityButtonClick(): void {
            this.model.createItem();
        },
        handleModuleFunctionalityButtonClick(
            value: FunctionalityButtonValueType,
            inverter: InverterModuleFormGroupType,
        ): void {
            if (value === 'remove') {
                this.model.removeItem(inverter);
            }
        },
    },
});
