










































import { getFormInputTextMixin } from '@/mixins/formInputTextMixin';
import { TextField } from '@/types/forms/fields/textField';
import { TextInputWithUnitContent } from '@/api/interfaces/content/form/base/textInputWithUnitContent';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';
import Icon from '@/components/atoms/icon/Icon.vue';

export default getFormInputTextMixin<
    TextField,
    TextInputWithUnitContent
>().extend({
    name: 'FormInputMultiLineText',
    components: {
        FieldWithInfoIcon,
        Icon,
    },
});
