

























import Vue, { PropType } from 'vue';
import AppButton from '@/components/atoms/appButton/AppButton.vue';
import SpinnerArea from '@/components/molecules/spinnerArea/SpinnerArea.vue';
import { SpinnerState } from '@/enums/spinnerState';
import { SpinnerSize } from '@/enums/spinnerSize';
import { ButtonTheme } from '@/enums/buttonTheme';
import { BaseFormContent } from '@/api/interfaces/content/form/base/baseFormContent';
import { ApplicationFormCommonContent } from '@/api/interfaces/content/form/common/applicationFormCommonContent';

export default Vue.extend({
    name: 'ButtonSpacer',
    components: {
        AppButton,
        SpinnerArea,
    },
    props: {
        content: {
            type: Object as PropType<BaseFormContent>,
            required: false,
            default: () => {
                return {
                    common: {} as ApplicationFormCommonContent,
                };
            },
        },
        buttonText: {
            type: String,
            default: null,
        },
        align: {
            type: String,
            default: 'left',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        showDebugFillButton: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        requiredFieldsText() {
            return (
                this.content?.common?.formStep?.requiredFieldsText?.text ?? ''
            );
        },
        buttonThemeSecondary() {
            return ButtonTheme.buttonSecondary;
        },
        spinnerSize: () => SpinnerSize.small,
        spinnerState(): SpinnerState {
            return this.isLoading ? SpinnerState.transparent : SpinnerState.off;
        },
    },
    methods: {
        handleClick(): void {
            this.$emit('button-click');
        },
        handleDebugFillClick(): void {
            this.$emit('debug-fill-button-click');
        },
    },
});
