






import Vue from 'vue';
import { PropType } from 'vue';
import { FormElement } from '@/types/forms/formElement';

export default Vue.extend({
    name: 'MapWrapper',
    props: {
        model: {
            type: Object as PropType<FormElement>,
            default: () => {
                return {};
            },
        },
    },
});
