








































































import { ControllableConsumptionDevicesFormStepContent } from '@/api/interfaces/content/form/steps/controllableConsumptionDevicesFormStepContent';
import ButtonSpacer from '@/components/molecules/buttonSpacer/ButtonSpacer.vue';
import { getFormStepMixin } from '@/mixins/formStepMixin';
import { ControllableConsumptionDevicesFormStep } from '@/types/forms/formSteps/controllableConsumptionDevicesFormStep';
import { FormStepConfiguration } from '@/api/interfaces/configuration/form/base/formStepConfiguration';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormRadioGroup from '@/components/molecules/formRadioGroup/FormRadioGroup.vue';

export default getFormStepMixin<
    ControllableConsumptionDevicesFormStep,
    ControllableConsumptionDevicesFormStepContent,
    FormStepConfiguration,
    ApplicationForm
>().extend({
    name: 'ControllableConsumptionDevices',
    components: {
        ButtonSpacer,
        FormTextBlock,
        FormField,
        FormBooleanCheckbox,
        FormRadioGroup,
    },
    computed: {},
    watch: {},
    mounted() {
        //
    },
    methods: {},
});
