









import Vue from 'vue';
import { isIE } from '@/utilities/browserDetection';

export default Vue.extend({
    name: 'CloseButton',
    computed: {
        isIE,
    },
});
