var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.model.disabled)?_c('FieldWithInfoIcon',{attrs:{"show-info-icon":_vm.showInfoIcon,"content":_vm.content}},[(_vm.selectCustomized)?_c('FormInputSelectCustomized',{attrs:{"classes":[
            {
                'text-on-surface-variant placeholder-on-surface-variant':
                    _vm.content.placeholder && !_vm.model.value,
            },
            _vm.errorClass ],"content":_vm.content,"options":_vm.enabledOptions,"show-search":_vm.selectCustomizedShowSearch},on:{"change":function($event){return _vm.$emit('change', $event)}},model:{value:(_vm.model.value),callback:function ($$v) {_vm.$set(_vm.model, "value", $$v)},expression:"model.value"}}):_vm._e(),(!_vm.selectCustomized)?_c('FormInputSelect',{attrs:{"classes":[
            {
                'text-on-surface-variant':
                    _vm.content.placeholder && !_vm.model.value,
            },
            _vm.errorClass ]},on:{"change":function($event){return _vm.$emit('change', $event)}},model:{value:(_vm.model.value),callback:function ($$v) {_vm.$set(_vm.model, "value", $$v)},expression:"model.value"}},[(_vm.content.placeholder)?_c('FormInputOption',{attrs:{"value":null,"text":_vm.content.placeholder,"disabled":"","selected":"","hidden":""}}):_vm._e(),_vm._l((_vm.enabledOptions),function(option){return _c('FormInputOption',{key:option.key,staticClass:"text-primary",attrs:{"value":option.key,"text":_vm.content.options[option.key].label}})})],2):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }