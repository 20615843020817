
































import { PropType } from 'vue';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';
import Icon from '@/components/atoms/icon/Icon.vue';
import SpinnerArea from '@/components/molecules/spinnerArea/SpinnerArea.vue';
import { SpinnerState } from '@/enums/spinnerState';
import { documentRepository } from '@/api';
import { SpinnerSize } from '@/enums/spinnerSize';
import { appState } from '@/services/form/appState';
import { downloadFile } from '@/services/fileDownloadService';
import { ServiceError } from '@/api/interfaces/serviceError';
import { messageService } from '@/services/messageService';
import { LabelContent } from '@/api/interfaces/content/form/base/labelContent';
import { FormElement } from '@/types/forms/formElement';
import { NoValueField } from '@/types/forms/fields/noValueField';
import { getFormElementMixin } from '@/mixins/formElementMixin';
import { DocumentId } from '@/api/interfaces/document/documentId';

export default getFormElementMixin<
    NoValueField,
    Record<string, string>
>().extend({
    name: 'DocumentDownload',
    components: {
        FieldWithInfoIcon,
        Icon,
        SpinnerArea,
    },
    props: {
        content: {
            type: Object as PropType<LabelContent>,
            default: () => {
                return {};
            },
        },
        model: {
            type: Object as PropType<FormElement>,
            default: () => {
                return {};
            },
        },
        documentId: {
            type: String as PropType<DocumentId>,
            required: true,
        },
        applicationId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            serverErrorMessage: null as string | null,
            isLoading: false,
        };
    },
    computed: {
        loadingState(): SpinnerState {
            return this.isLoading ? SpinnerState.transparent : SpinnerState.off;
        },
        spinnerSize: () => SpinnerSize.small,
    },
    methods: {
        async downloadFile() {
            this.serverErrorMessage = null;
            await this.$nextTick();

            this.setLoading();

            documentRepository
                .downloadDocument(this.documentId, this.applicationId)
                .then((response) => {
                    downloadFile(response.bytes, response.fileName);
                })
                .catch((error: ServiceError) => {
                    this.serverErrorMessage = messageService.getErrorMessage(
                        error.messageCode,
                    );
                })
                .finally(() => {
                    this.unsetLoading();
                });
        },
        setLoading(): void {
            this.isLoading = true;
            appState.pendingFileUploads++;
        },
        unsetLoading(): void {
            this.isLoading = false;
            appState.pendingFileUploads--;
        },
    },
});
