



























































































































































import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormInputTextWithSuggestions from '@/components/atoms/formInputTextWithSuggestions/FormInputTextWithSuggestions.vue';
import DatePicker from '@/components/molecules/datePicker/DatePicker.vue';
import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormRadioGroup from '@/components/molecules/formRadioGroup/FormRadioGroup.vue';
import FormSelect from '@/components/molecules/formSelect/FormSelect.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { OperatorFormGroup } from '@/types/forms/formGroups/operatorFormGroup';
import type { OperatorFormGroupContent } from '@/api/interfaces/content/form/steps/plantOperatorFormStepContent';
import type { ValidationsCommonType } from '@/services/form/validations';

export default getFormInputMixin<
    OperatorFormGroup,
    OperatorFormGroupContent
>().extend({
    name: 'OperatorFormGroup',
    components: {
        FormInputText,
        FormInputTextWithSuggestions,
        DatePicker,
        FormBooleanCheckbox,
        FormField,
        FormRadioGroup,
        FormSelect,
    },
    computed: {
        isFirstOperator(): boolean {
            return this.$vnode.key === 0;
        },
        operatorNameValidations(): ValidationsCommonType | unknown {
            return this.validations?.operatorName || {};
        },
        contactPersonNameValidations(): ValidationsCommonType | unknown {
            return this.validations?.contactPersonName || {};
        },
    },
});
