




















































































































































































































import { MeasuringConceptsFormStepContent } from '@/api/interfaces/content/form/steps/measuringConceptsFormStepContent';
import ButtonSpacer from '@/components/molecules/buttonSpacer/ButtonSpacer.vue';
import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormFileUpload from '@/components/molecules/formFileUpload/FormFileUpload.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormRadioGroup from '@/components/molecules/formRadioGroup/FormRadioGroup.vue';
import FormRow from '@/components/molecules/formRow/FormRow.vue';
import FormSelect from '@/components/molecules/formSelect/FormSelect.vue';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import { getFormStepMixin } from '@/mixins/formStepMixin';
import { MeasuringConceptsFormStep } from '@/types/forms/formSteps/measuringConceptsFormStep';
import { FormStepConfiguration } from '@/api/interfaces/configuration/form/base/formStepConfiguration';
import {
    formatGermanNumber,
    parseGermanFloat,
} from '@/utilities/germanFormats';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { Mkf } from '@/api/interfaces/mkf/mkf';
import { PropType } from 'vue';
import { FormOption } from '@/types/forms/formOption';
import { calculateSelfGenerationQuantity } from '@/services/form/utilities/application/plantPerformance';
import { isPv } from '@/services/form/rules/application/common';

const PV_SELFGENERATIONQUANTITY_TO_SELFSUPPLYQUANTITY_FACTOR = 0.7;

export default getFormStepMixin<
    MeasuringConceptsFormStep,
    MeasuringConceptsFormStepContent,
    FormStepConfiguration,
    ApplicationForm
>().extend({
    name: 'MeasuringConcepts',
    props: {
        mkf: {
            type: Object as PropType<Mkf>,
            required: true,
        },
    },
    components: {
        ButtonSpacer,
        FormBooleanCheckbox,
        FormField,
        FormFileUpload,
        FormInputText,
        FormRadioGroup,
        FormRow,
        FormSelect,
        FormTextBlock,
    },
    watch: {
        'model.operatingMode.value': function () {
            this.updateSelfSupplyQuantity();
        },
        'model.selfGenerationQuantity.value': function () {
            if (this.model.operatingMode.value !== 'fullInfeed') {
                this.updateSelfSupplyQuantity();
            }
        },
    },
    mounted() {
        this.initializeSelfGenerationQuantity();
        this.initializeMeasuringPointOperator();
    },
    methods: {
        updateSelfSupplyQuantity(): void {
            let selfSupplyQuantity = 0;
            if (this.model.operatingMode.value !== 'fullInfeed') {
                const selfGenerationQuantity = parseGermanFloat(
                    this.model.selfGenerationQuantity?.value ?? '0',
                );
                selfSupplyQuantity =
                    selfGenerationQuantity *
                    PV_SELFGENERATIONQUANTITY_TO_SELFSUPPLYQUANTITY_FACTOR;
            }
            this.model.selfSupplyQuantity.value = formatGermanNumber(
                selfSupplyQuantity,
                { decimals: 2, thousandsSeparator: true },
            );
            this.$nextTick(() => {
                this.validations?.selfSupplyQuantity.$reset();
                this.rules(this);
            });
        },
        initializeSelfGenerationQuantity(): void {
            if (isPv(this.formModel)) {
                this.model.selfGenerationQuantity.value = calculateSelfGenerationQuantity(
                    this.formModel,
                );
                this.$nextTick(() => {
                    this.validations?.selfGenerationQuantity.$reset();
                });
            }
        },
        initializeMeasuringPointOperator(): void {
            const msb = this.mkf?.MSB;
            if ((msb?.size ?? 0) > 0) {
                const values = msb?.values ?? {};
                this.model.measuringPointOperator.options = [];
                this.content.measuringPointOperator.options = {};
                for (var key in values) {
                    const value = values[key];
                    if (
                        this.model.measuringPointOperator.options.some(
                            (o) => o.key == value,
                        )
                    ) {
                        // key already in list, skip
                        continue;
                    }
                    this.model.measuringPointOperator.options.push(
                        new FormOption({
                            key: value,
                        }),
                    );
                    this.content.measuringPointOperator.options[value] = {
                        label: value,
                    };
                }
                // sort alphabetically
                this.model.measuringPointOperator.options.sort((a, b) => {
                    const akl = a.key.toLowerCase();
                    const bkl = b.key.toLowerCase();
                    if (akl < bkl) {
                        return -1;
                    }
                    if (akl > bkl) {
                        return 1;
                    }
                    return 0;
                });
            }
        },
    },
});
