




























































































import Vue, { PropType } from 'vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import Icon from '@/components/atoms/icon/Icon.vue';
import { SelectContent } from '@/api/interfaces/content/form/base/selectContent';
import { SelectSearchContent } from '@/api/interfaces/content/form/base/selectSearchContent';
import { FormOption } from '@/types/forms/formOption';
import { noop as _noop } from 'lodash';

export default getFormInputMixin().extend({
    name: 'FormInputSelectCustomized',
    components: {
        Icon,
    },
    props: {
        content: {
            type: Object as PropType<SelectContent & SelectSearchContent>,
            default: () => {
                return {} as SelectContent & SelectSearchContent;
            },
        },
        options: {
            type: Array as PropType<FormOption<string>[]>,
            default: () => {
                return [] as FormOption<string>[];
            },
        },
        value: {
            type: String,
            default: null,
        },
        classes: {
            type: [String, Object, Array],
            default: null,
        },
        showSearch: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            isDropdownVisible: false,
            isSearchEnabled: false,
            selectedOption: (null as unknown) as FormOption<string> | null,
            hoveredOption: (null as unknown) as FormOption<string> | null,
            cursorOption: (null as unknown) as FormOption<string> | null,
            searchTerm: '',
            vClickOutsideConfig: {
                handler: _noop,
                middleware: _noop,
                events: ['dblclick', 'click'],
            },
        };
    },
    computed: {
        ghostInputVal(): string {
            if (this.cursorOption) {
                return this.content.options
                    ? this.content.options[this.cursorOption.key]?.label
                    : '';
            }
            return '';
        },
        inputVal: {
            get(): string {
                if (this.ghostInputVal) {
                    return this.ghostInputVal;
                }
                if (this.selectedOption === null) {
                    return '';
                }
                return this.content.options
                    ? this.content.options[this.selectedOption?.key]?.label
                    : '';
            },
            set() {
                this.$emit('input', this.selectedOption?.key);
            },
        },
        filteredOptions(): FormOption<string>[] {
            if (!this.isSearchEnabled || !this.searchTerm) {
                return this.options;
            }

            return this.options.filter(
                (option) =>
                    this.content.options &&
                    this.content.options[option.key].label
                        .toLowerCase()
                        .indexOf(this.searchTerm.toLowerCase()) > -1,
            );
        },
    },
    created(): void {
        this.vClickOutsideConfig.handler = this.vClickOutsideHandler;
        this.vClickOutsideConfig.middleware = this.vClickOutsideMiddleware;
    },
    mounted(): void {
        this.isSearchEnabled = !!this.content.search && this.showSearch;
        const selectedOption = this.options.find(
            (option) => option.key == this.value,
        );
        this.selectedOption =
            selectedOption !== undefined ? selectedOption : null;
    },
    methods: {
        openDropdown(): void {
            if (!this.isDropdownVisible) {
                this.isDropdownVisible = true;
                if (this.isSearchEnabled) {
                    const searchInput = this.$refs.searchInput as HTMLElement;
                    Vue.nextTick(function () {
                        searchInput?.focus();
                    });
                }
            }
        },
        closeDropdown(keepFocused = false): void {
            if (this.cursorOption) {
                const option = this.cursorOption;
                this.cursorOption = null;
                this.selectOption(option);
                return;
            }
            if (this.isDropdownVisible) {
                this.isDropdownVisible = false;
                this.searchTerm = '';
                this.hoveredOption = null;
                if (keepFocused) {
                    const input = this.$refs.input as HTMLElement;
                    Vue.nextTick(function () {
                        input?.focus();
                    });
                }
            }
        },
        toggleDropdown(keepFocused = false): void {
            if (this.isDropdownVisible) {
                this.closeDropdown(keepFocused);
            } else {
                this.openDropdown();
            }
        },
        selectOption(option: FormOption<string>): void {
            this.selectedOption = option;
            this.inputVal = option.key;
            this.closeDropdown();
        },
        hoverOption(option: FormOption<string>): void {
            this.hoveredOption = option;
            const optionIndex = this.filteredOptions.indexOf(option);
            if (optionIndex != -1) {
                (this.$refs.list as HTMLElement[])[optionIndex].scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start',
                });
            }
        },
        cursorUp(): void {
            let index =
                this.filteredOptions.findIndex(
                    (option) => option.key === this.value,
                ) || 0;
            if (this.hoveredOption) {
                index = this.filteredOptions.indexOf(this.hoveredOption);
            }
            index--;
            if (index < 0) {
                index = 0;
            }
            const option = this.filteredOptions[index];
            if (this.isDropdownVisible) {
                this.hoverOption(option);
                this.cursorOption = option;
            } else {
                this.selectOption(option);
            }
        },
        cursorDown(): void {
            const filteredOptionsCountMinusOne =
                this.filteredOptions.length - 1;
            let index =
                this.filteredOptions.findIndex(
                    (option) => option.key === this.value,
                ) || 0;
            if (this.hoveredOption) {
                index = this.filteredOptions.indexOf(this.hoveredOption);
            }
            index++;
            if (index > filteredOptionsCountMinusOne) {
                index = filteredOptionsCountMinusOne;
            }
            const option = this.filteredOptions[index];
            if (this.isDropdownVisible) {
                this.hoverOption(option);
                this.cursorOption = option;
            } else {
                this.selectOption(option);
            }
        },
        vClickOutsideHandler(/*event*/): void {
            this.closeDropdown();
        },
        vClickOutsideMiddleware(event: Event): boolean {
            return !(event.target as HTMLElement)?.closest('.dropdown');
        },
    },
});
