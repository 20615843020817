














































































import FormInputCount from '@/components/molecules/formInputCount/FormInputCount.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormFileUpload from '@/components/molecules/formFileUpload/FormFileUpload.vue';
import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { PhotovoltaicModuleFormGroup } from '@/types/forms/formGroups/plantPerformance/photovoltaic/photovoltaicModuleFormGroup';
import type { PhotovoltaicModuleFormGroupContent } from '@/api/interfaces/content/form/steps/plantPerformance/photovoltaicContent';
import type { ValidationsCommonType } from '@/services/form/validations';
import { number } from '@/validators/number';
import { interpolate } from '@/services/interpolationService';
import {
    formatGermanNumber,
    parseGermanFloat,
} from '@/utilities/germanFormats';
import { roundToDecimals } from '@/utilities/math';

export default getFormInputMixin<
    PhotovoltaicModuleFormGroup,
    PhotovoltaicModuleFormGroupContent
>().extend({
    name: 'PhotovoltaicModuleFormGroup',
    components: {
        FormInputCount,
        FormInputText,
        FormField,
        FormFileUpload,
        FunctionalityButton,
    },
    props: {
        applicationId: {
            type: String,
            required: true,
        },
        showRemoveButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            functionalityButtonValue: 'remove' as FunctionalityButtonValueType,
        };
    },
    computed: {
        pvQuantityAndNominalPowerValidations():
            | ValidationsCommonType
            | unknown {
            return this.validations?.pvQuantityAndNominalPower || {};
        },
        totalNominalPowerDisplayValue(): string {
            const value = (this.model.pvTotalNominalPower.value ?? 0) / 1000;

            return interpolate(this.content.pvTotalNominalPower.unit, {
                value: formatGermanNumber(value, {
                    decimals: 2,
                    thousandsSeparator: true,
                }),
            });
        },
    },
    watch: {
        'model.pvQuantityAndNominalPower.quantity.value': {
            handler(newValue: number | null) {
                this.setTotalNominalPower(
                    newValue,
                    this.model.pvQuantityAndNominalPower.nominalPower.value,
                );
            },
            immediate: true,
        },
        'model.pvQuantityAndNominalPower.nominalPower.value': {
            handler(newValue: string | null) {
                this.setTotalNominalPower(
                    this.model.pvQuantityAndNominalPower.quantity.value,
                    newValue,
                );
            },
            immediate: true,
        },
    },
    methods: {
        setTotalNominalPower(
            rawQuantity: number | null,
            rawNominalPower: string | null,
        ): void {
            // nominalPower as number; default: 0
            let nominalPower = 0;
            if (rawNominalPower != null) {
                const parsedNominalPower = parseGermanFloat(rawNominalPower);

                nominalPower = number(parsedNominalPower)
                    ? parsedNominalPower
                    : 0;
            }

            // quantity as number; default: 0
            let quantity = 0;
            if (rawQuantity != null) {
                quantity = number(rawQuantity) ? rawQuantity : 0;
            }

            this.model.pvTotalNominalPower.value = roundToDecimals(
                quantity * nominalPower,
            );
        },
    },
});
