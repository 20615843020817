import Vue from 'vue';
import { configurationRepository } from '@/api';
import type { FormConfiguration } from '@/api/interfaces/configuration/form/formConfiguration';

class ConfigurationService<T> {
    private state = Vue.observable({
        currentPromise: null as Promise<T> | null,
        loadedConfiguration: null as T | null,
    });

    public constructor(
        private readonly getConfigurationPromise: () => Promise<T>,
    ) {}

    public get hasConfigurationLoaded(): boolean {
        return !!this.state.loadedConfiguration;
    }

    public get isConfigurationLoading(): boolean {
        return !this.state.loadedConfiguration && !this.state.currentPromise;
    }

    public get content(): T | null {
        this.requestConfigurationOnce();
        return this.state.loadedConfiguration;
    }

    public async requestConfigurationOnce(): Promise<T> {
        if (this.state.loadedConfiguration) {
            return this.state.loadedConfiguration;
        }

        if (this.state.currentPromise) {
            return await this.state.currentPromise;
        }

        return await this.requestConfiguration();
    }

    public async requestConfiguration(): Promise<T> {
        this.state.currentPromise = this.getConfigurationPromise();
        this.state.loadedConfiguration = await this.state.currentPromise;
        this.state.currentPromise = null;
        return this.state.loadedConfiguration;
    }
}

export const configurationServices = {
    form: new ConfigurationService<FormConfiguration>(() =>
        configurationRepository.fetchFormConfiguration(),
    ),
};
