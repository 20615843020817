




















































import { getFormInputMixin } from '@/mixins/formInputMixin';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import PhotovoltaicModuleFormList from '@/components/organisms/plantPerformance/photovoltaicModuleFormList/PhotovoltaicModuleFormList.vue';
import { AddressCheckFeedbackFormGroup } from '@/types/forms/formGroups/electricityUnavailableAtAddressFormGroup';
import { AddressCheckFeedbackFormGroupContent } from '@/api/interfaces/content/form/steps/location/addressCheckFeedbackFormGroupContent';
import { addressRequiredIfNoCadastral } from '@/validators/addressRequiredIfNoCadastral';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import SpinnerArea from '@/components/molecules/spinnerArea/SpinnerArea.vue';
import { SpinnerState } from '@/enums/spinnerState';
import { SpinnerSize } from '@/enums/spinnerSize';
import { AddressesApiAvailabilityResult } from '@/types/forms/shared/addressesApi/addressesApiAvailabilityResult';
import { PropType } from 'vue';

export default getFormInputMixin<
    AddressCheckFeedbackFormGroup,
    AddressCheckFeedbackFormGroupContent,
    unknown,
    ApplicationForm
>().extend({
    name: 'AddressCheckFeedbackFormGroup',
    components: {
        FormField,
        FormTextBlock,
        PhotovoltaicModuleFormList,
        SpinnerArea,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        addressCheckInInitState: {
            type: Boolean,
            default: false,
        },
        availabilityResult: {
            type: String as PropType<AddressesApiAvailabilityResult>,
        },
    },
    computed: {
        isVisible(): boolean {
            return (
                !this.showSummary &&
                (this.isLoading ||
                    (!this.isLoading &&
                        (this.availabilityResult == 'available' ||
                            (!this.addressCheckInInitState &&
                                this.availabilityResult == null) ||
                            this.availabilityResult == 'unavailable')))
            );
        },
        addHasErrorsClass(): boolean {
            return (
                !this.formModel.location.proceedWithCadastral.disabled &&
                !this.formModel.location.proceedWithCadastral.value &&
                !addressRequiredIfNoCadastral.call(this.$parent)
            );
        },
        loadingState(): SpinnerState {
            return this.isLoading ? SpinnerState.transparent : SpinnerState.off;
        },
        spinnerSize: () => SpinnerSize.small,
    },
});
