import Vue, { PropType } from 'vue';
import {
    ValidationGroups,
    ValidationProperties,
    VueConstructor,
} from 'vue/types/vue';
import { Validation } from 'vuelidate';

export interface FormFieldVue {
    registerFormElementComponent(formElementComponent: Vue): void;
}

export interface FormElementComponent<TModel, TContent> extends Vue {
    readonly content: TContent;
    readonly model: TModel;
    readonly validations:
        | (Validation & ValidationGroups & ValidationProperties<unknown>)
        | null;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export function getFormElementMixin<
    TModel,
    TContent,
    TConfiguration = void
>(): VueConstructor<FormElementComponent<TModel, TContent>> {
    return Vue.extend({
        inject: {
            injectedFormField: {
                from: 'formField',
                default: null as (Vue & FormFieldVue) | null,
            },
        },
        props: {
            content: {
                type: Object as PropType<TContent>,
                default: () => {
                    return {} as TContent;
                },
            },
            configuration: {
                type: Object as PropType<TConfiguration>,
                default: () => ({} as TConfiguration),
            },
            model: {
                type: Object as PropType<TModel>,
                default: () => {
                    return {} as TModel;
                },
            },
            validations: {
                type: Object as PropType<
                    | (Validation &
                          ValidationGroups &
                          ValidationProperties<unknown>)
                    | null
                >,
                default: null,
            },
            showInfoIcon: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            formField(this: {
                injectedFormField: FormFieldVue;
            }): FormFieldVue | null {
                return this.injectedFormField || null;
            },
        },
    });
}
