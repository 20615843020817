var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",class:{ 'mb-4 md:mb-8 lg:mb-10 xl:mb-12': !_vm.showSummary }},[_c('FormField',{staticClass:"meter-picture",attrs:{"model":_vm.model.meterPicture,"content":_vm.content.meterPicture,"validations":_vm.validations.meterPicture,"show-summary":_vm.showSummary,"summary-value":_vm.model.meterPicture.value && _vm.model.meterPicture.value.name}},[_c('FormFileUpload',{attrs:{"model":_vm.model.meterPicture,"content":_vm.content.meterPicture,"configuration":_vm.configuration.meterPicture,"validations":_vm.validations.meterPicture,"application-id":_vm.formModel.applicationId}})],1),_c('FormField',{staticClass:"bafa-approval",attrs:{"model":_vm.model.bafaApproval,"content":_vm.content.bafaApproval,"validations":_vm.validations.bafaApproval,"show-summary":_vm.showSummary,"summary-value":_vm.model.bafaApproval.value && _vm.model.bafaApproval.value.name}},[_c('FormFileUpload',{attrs:{"model":_vm.model.bafaApproval,"content":_vm.content.bafaApproval,"configuration":_vm.configuration.bafaApproval,"validations":_vm.validations.bafaApproval,"application-id":_vm.formModel.applicationId,"show-info-icon":""}})],1),_c('FormField',{staticClass:"grid-and-plant-protection-proof-of-conformity",attrs:{"model":_vm.model.gridAndPlantProtectionProofOfConformity,"content":_vm.content.gridAndPlantProtectionProofOfConformity,"validations":_vm.validations.gridAndPlantProtectionProofOfConformity,"show-summary":_vm.showSummary,"summary-value":_vm.model.gridAndPlantProtectionProofOfConformity.value
                ? _vm.model.gridAndPlantProtectionProofOfConformity.value.name
                : '',"show-asterisk":_vm.showAsteriskForGridAndPlantProtectionProofOfConformity}},[_c('FormFileUpload',{attrs:{"model":_vm.model.gridAndPlantProtectionProofOfConformity,"content":_vm.content.gridAndPlantProtectionProofOfConformity,"configuration":_vm.configuration.gridAndPlantProtectionProofOfConformity,"validations":_vm.validations.gridAndPlantProtectionProofOfConformity,"application-id":_vm.formModel.applicationId}})],1),(!_vm.formModel.plantPerformance.storage.disabled)?_c('div',{attrs:{"className":"storageModules"}},[_vm._l((_vm.model.storageModules.items),function(storageModule,index){return [_c('div',{key:index},[(
                        !_vm.model.storageModules.disabled &&
                        (!storageModule
                            .storageConformityDeclarationStorageSystem
                            .disabled ||
                            !storageModule.storageManufacturerDataSheet
                                .disabled)
                    )?_c('div',{staticClass:"lg:text-lg xl:text-xl font-normal"},[_vm._v(" Speicher ("+_vm._s(_vm.formModel.plantPerformance.storage.storageModules .items[index] && _vm.formModel.plantPerformance.storage.storageModules .items[index].storageManufacturerAndStorageType .manufacturer.value)+", "+_vm._s(_vm.formModel.plantPerformance.storage.storageModules .items[index] && _vm.formModel.plantPerformance.storage.storageModules .items[index].storageManufacturerAndStorageType .storageType.value)+") ")]):_vm._e(),_c('FormField',{staticClass:"storage-conformity-declaration-storage-system",attrs:{"model":storageModule.storageConformityDeclarationStorageSystem,"content":_vm.content.storageConformityDeclarationStorageSystem,"validations":_vm.validations.storageModules[index] &&
                        _vm.validations.storageModules[index]
                            .storageConformityDeclarationStorageSystem,"show-summary":_vm.showSummary,"summary-value":storageModule
                            .storageConformityDeclarationStorageSystem.value
                            ? storageModule
                                  .storageConformityDeclarationStorageSystem
                                  .value.name
                            : ''}},[_c('FormFileUpload',{attrs:{"model":storageModule.storageConformityDeclarationStorageSystem,"content":_vm.content.storageConformityDeclarationStorageSystem,"configuration":_vm.configuration.storageConformityDeclarationStorageSystem,"validations":_vm.validations.storageModules[index] &&
                            _vm.validations.storageModules[index]
                                .storageConformityDeclarationStorageSystem,"application-id":_vm.formModel.applicationId}})],1),_c('FormField',{staticClass:"storage-manufacturer-data-sheet",attrs:{"model":storageModule.storageManufacturerDataSheet,"content":_vm.content.storageManufacturerDataSheet,"validations":_vm.validations.storageModules[index] &&
                        _vm.validations.storageModules[index]
                            .storageManufacturerDataSheet,"show-summary":_vm.showSummary,"summary-value":storageModule.storageManufacturerDataSheet.value
                            ? storageModule.storageManufacturerDataSheet
                                  .value.name
                            : ''}},[_c('FormFileUpload',{attrs:{"model":storageModule.storageManufacturerDataSheet,"content":_vm.content.storageManufacturerDataSheet,"configuration":_vm.configuration.storageManufacturerDataSheet,"validations":_vm.validations.storageModules[index] &&
                            _vm.validations.storageModules[index]
                                .storageManufacturerDataSheet,"application-id":_vm.formModel.applicationId}})],1)],1)]})],2):_vm._e(),(!_vm.model.inverterModules.disabled)?_c('div',{attrs:{"className":"inverterModules"}},[_vm._l((_vm.model.inverterModules.items),function(inverterModule,index){return [_c('div',{key:index},[(
                        !_vm.model.inverterModules.disabled &&
                        (!inverterModule
                            .inverterConformityConfirmationOrUnitCertificate
                            .disabled ||
                            !inverterModule.inverterTechnicalDataSheet
                                .disabled ||
                            !inverterModule.inverterProofOfConformity
                                .disabled ||
                            !inverterModule.inverterUnitCertificate
                                .disabled)
                    )?_c('div',{staticClass:"lg:text-lg xl:text-xl font-normal"},[_vm._v(" Wechselrichter ("+_vm._s(_vm.formModel.plantPerformance.inverter.inverterModules .items[index] && _vm.formModel.plantPerformance.inverter.inverterModules .items[index].inverterManufacturerAndModuleType .manufacturer.value)+", "+_vm._s(_vm.formModel.plantPerformance.inverter.inverterModules .items[index] && _vm.formModel.plantPerformance.inverter.inverterModules .items[index].inverterManufacturerAndModuleType .moduleType.value)+") ")]):_vm._e(),_c('FormField',{staticClass:"inverter-conformity-confirmation-or-unit-certificate",attrs:{"model":inverterModule.inverterConformityConfirmationOrUnitCertificate,"content":_vm.content.inverterConformityConfirmationOrUnitCertificate,"validations":_vm.validations.inverterModules[index] &&
                        _vm.validations.inverterModules[index]
                            .inverterConformityConfirmationOrUnitCertificate,"show-summary":_vm.showSummary,"summary-value":inverterModule
                            .inverterConformityConfirmationOrUnitCertificate
                            .value
                            ? inverterModule
                                  .inverterConformityConfirmationOrUnitCertificate
                                  .value.name
                            : ''}},[_c('FormFileUpload',{attrs:{"model":inverterModule.inverterConformityConfirmationOrUnitCertificate,"content":_vm.content.inverterConformityConfirmationOrUnitCertificate,"configuration":_vm.configuration.inverterConformityConfirmationOrUnitCertificate,"validations":_vm.validations.inverterModules[index] &&
                            _vm.validations.inverterModules[index]
                                .inverterConformityConfirmationOrUnitCertificate,"application-id":_vm.formModel.applicationId}})],1),_c('FormField',{staticClass:"inverter-technical-data-sheet",attrs:{"model":inverterModule.inverterTechnicalDataSheet,"content":_vm.content.inverterTechnicalDataSheet,"validations":_vm.validations.inverterModules[index] &&
                        _vm.validations.inverterModules[index]
                            .inverterTechnicalDataSheet,"show-summary":_vm.showSummary,"summary-value":inverterModule.inverterTechnicalDataSheet.value
                            ? inverterModule.inverterTechnicalDataSheet
                                  .value.name
                            : ''}},[_c('FormFileUpload',{attrs:{"model":inverterModule.inverterTechnicalDataSheet,"content":_vm.content.inverterTechnicalDataSheet,"configuration":_vm.configuration.inverterTechnicalDataSheet,"validations":_vm.validations.inverterModules[index] &&
                            _vm.validations.inverterModules[index]
                                .inverterTechnicalDataSheet,"application-id":_vm.formModel.applicationId}})],1),_c('FormField',{staticClass:"inverter-proof-of-conformity",attrs:{"model":inverterModule.inverterProofOfConformity,"content":_vm.content.inverterProofOfConformity,"validations":_vm.validations.inverterModules[index] &&
                        _vm.validations.inverterModules[index]
                            .inverterProofOfConformity,"show-summary":_vm.showSummary,"summary-value":inverterModule.inverterProofOfConformity.value
                            ? inverterModule.inverterProofOfConformity.value
                                  .name
                            : ''}},[_c('FormFileUpload',{attrs:{"model":inverterModule.inverterProofOfConformity,"content":_vm.content.inverterProofOfConformity,"configuration":_vm.configuration.inverterProofOfConformity,"validations":_vm.validations.inverterModules[index] &&
                            _vm.validations.inverterModules[index]
                                .inverterProofOfConformity,"application-id":_vm.formModel.applicationId}})],1),_c('FormField',{staticClass:"inverter-unit-certificate",attrs:{"model":inverterModule.inverterUnitCertificate,"content":_vm.content.inverterUnitCertificate,"validations":_vm.validations.inverterModules[index] &&
                        _vm.validations.inverterModules[index]
                            .inverterUnitCertificate,"show-summary":_vm.showSummary,"summary-value":inverterModule.inverterUnitCertificate.value
                            ? inverterModule.inverterUnitCertificate.value
                                  .name
                            : ''}},[_c('FormFileUpload',{attrs:{"model":inverterModule.inverterUnitCertificate,"content":_vm.content.inverterUnitCertificate,"configuration":_vm.configuration.inverterUnitCertificate,"validations":_vm.validations.inverterModules[index] &&
                            _vm.validations.inverterModules[index]
                                .inverterUnitCertificate,"application-id":_vm.formModel.applicationId}})],1)],1)]})],2):_vm._e(),(!_vm.formModel.plantPerformance.generator.disabled)?_c('div',{staticClass:"generatorModules"},[_vm._l((_vm.model.generatorModules.items),function(generatorModule,index){return [_c('div',{key:index},[(
                        !_vm.model.generatorModules.disabled &&
                        (!generatorModule.generatorTechnicalDataSheet
                            .disabled ||
                            !generatorModule
                                .generatorConformityConfirmationOrUnitCertificate
                                .disabled ||
                            !generatorModule.generatorProofOfConformity
                                .disabled ||
                            !generatorModule
                                .generatorUnitOrPrototypeConfirmation
                                .disabled ||
                            !generatorModule.generatorComponentCertificate
                                .disabled)
                    )?_c('div',{staticClass:"lg:text-lg xl:text-xl font-normal"},[_vm._v(" Generator ("+_vm._s(_vm.formModel.plantPerformance.generator .generatorModules.items[index] && _vm.formModel.plantPerformance.generator .generatorModules.items[index] .generatorManufacturerAndModuleType.manufacturer .value)+", "+_vm._s(_vm.formModel.plantPerformance.generator .generatorModules.items[index] && _vm.formModel.plantPerformance.generator .generatorModules.items[index] .generatorManufacturerAndModuleType.moduleType .value)+") ")]):_vm._e(),_c('FormField',{staticClass:"generator-technical-data-sheet",attrs:{"model":generatorModule.generatorTechnicalDataSheet,"content":_vm.content.generatorTechnicalDataSheet,"validations":_vm.validations.generatorModules[index] &&
                        _vm.validations.generatorModules[index]
                            .generatorTechnicalDataSheet,"show-summary":_vm.showSummary,"summary-value":generatorModule.generatorTechnicalDataSheet.value
                            ? generatorModule.generatorTechnicalDataSheet
                                  .value.name
                            : ''}},[_c('FormFileUpload',{attrs:{"model":generatorModule.generatorTechnicalDataSheet,"content":_vm.content.generatorTechnicalDataSheet,"configuration":_vm.configuration.generatorTechnicalDataSheet,"validations":_vm.validations.generatorModules[index] &&
                            _vm.validations.generatorModules[index]
                                .generatorTechnicalDataSheet,"application-id":_vm.formModel.applicationId}})],1),_c('FormField',{staticClass:"generator-conformity-confirmation-or-unit-certificate",attrs:{"model":generatorModule.generatorConformityConfirmationOrUnitCertificate,"content":_vm.content.generatorConformityConfirmationOrUnitCertificate,"validations":_vm.validations.generatorModules[index] &&
                        _vm.validations.generatorModules[index]
                            .generatorConformityConfirmationOrUnitCertificate,"show-summary":_vm.showSummary,"summary-value":generatorModule
                            .generatorConformityConfirmationOrUnitCertificate
                            .value
                            ? generatorModule
                                  .generatorConformityConfirmationOrUnitCertificate
                                  .value.name
                            : ''}},[_c('FormFileUpload',{attrs:{"model":generatorModule.generatorConformityConfirmationOrUnitCertificate,"content":_vm.content.generatorConformityConfirmationOrUnitCertificate,"configuration":_vm.configuration.generatorConformityConfirmationOrUnitCertificate,"validations":_vm.validations.generatorModules[index] &&
                            _vm.validations.generatorModules[index]
                                .generatorConformityConfirmationOrUnitCertificate,"application-id":_vm.formModel.applicationId}})],1),_c('FormField',{staticClass:"generator-proof-of-conformity",attrs:{"model":generatorModule.generatorProofOfConformity,"content":_vm.content.generatorProofOfConformity,"validations":_vm.validations.generatorModules[index] &&
                        _vm.validations.generatorModules[index]
                            .generatorProofOfConformity,"show-summary":_vm.showSummary,"summary-value":generatorModule.generatorProofOfConformity.value
                            ? generatorModule.generatorProofOfConformity
                                  .value.name
                            : ''}},[_c('FormFileUpload',{attrs:{"model":generatorModule.generatorProofOfConformity,"content":_vm.content.generatorProofOfConformity,"configuration":_vm.configuration.generatorProofOfConformity,"validations":_vm.validations.generatorModules[index] &&
                            _vm.validations.generatorModules[index]
                                .generatorProofOfConformity,"application-id":_vm.formModel.applicationId}})],1),_c('FormField',{staticClass:"generator-unit-or-prototype-confirmation",attrs:{"model":generatorModule.generatorUnitOrPrototypeConfirmation,"content":_vm.content.generatorUnitOrPrototypeConfirmation,"validations":_vm.validations.generatorModules[index] &&
                        _vm.validations.generatorModules[index]
                            .generatorUnitOrPrototypeConfirmation,"show-summary":_vm.showSummary,"summary-value":generatorModule.generatorUnitOrPrototypeConfirmation
                            .value
                            ? generatorModule
                                  .generatorUnitOrPrototypeConfirmation
                                  .value.name
                            : ''}},[_c('FormFileUpload',{attrs:{"model":generatorModule.generatorUnitOrPrototypeConfirmation,"content":_vm.content.generatorUnitOrPrototypeConfirmation,"configuration":_vm.configuration.generatorUnitOrPrototypeConfirmation,"validations":_vm.validations.generatorModules[index] &&
                            _vm.validations.generatorModules[index]
                                .generatorUnitOrPrototypeConfirmation,"application-id":_vm.formModel.applicationId}})],1),_c('FormField',{staticClass:"generator-component-certificate",attrs:{"model":generatorModule.generatorComponentCertificate,"content":_vm.content.generatorComponentCertificate,"validations":_vm.validations.generatorModules[index] &&
                        _vm.validations.generatorModules[index]
                            .generatorComponentCertificate,"show-summary":_vm.showSummary,"summary-value":generatorModule.generatorComponentCertificate.value
                            ? generatorModule.generatorComponentCertificate
                                  .value.name
                            : ''}},[_c('FormFileUpload',{attrs:{"model":generatorModule.generatorComponentCertificate,"content":_vm.content.generatorComponentCertificate,"configuration":_vm.configuration.generatorComponentCertificate,"validations":_vm.validations.generatorModules[index] &&
                            _vm.validations.generatorModules[index]
                                .generatorComponentCertificate,"application-id":_vm.formModel.applicationId}})],1)],1)]})],2):_vm._e(),_c('FormField',{staticClass:"performance-monitoring-certificate",attrs:{"model":_vm.model.performanceMonitoringCertificate,"content":_vm.content.performanceMonitoringCertificate,"validations":_vm.validations.performanceMonitoringCertificate,"show-summary":_vm.showSummary,"summary-value":_vm.model.performanceMonitoringCertificate.value
                ? _vm.model.performanceMonitoringCertificate.value.name
                : ''}},[_c('FormFileUpload',{attrs:{"model":_vm.model.performanceMonitoringCertificate,"content":_vm.content.performanceMonitoringCertificate,"configuration":_vm.configuration.performanceMonitoringCertificate,"validations":_vm.validations.performanceMonitoringCertificate,"application-id":_vm.formModel.applicationId}})],1),_c('FormField',{staticClass:"site-plan-with-lot-number",attrs:{"model":_vm.model.sitePlanWithLotNumber,"content":_vm.content.sitePlanWithLotNumber,"validations":_vm.validations.sitePlanWithLotNumber,"show-summary":_vm.showSummary,"summary-value":_vm.model.sitePlanWithLotNumber.value
                ? _vm.model.sitePlanWithLotNumber.value.name
                : ''}},[_c('FormFileUpload',{attrs:{"model":_vm.model.sitePlanWithLotNumber,"content":_vm.content.sitePlanWithLotNumber,"configuration":_vm.configuration.sitePlanWithLotNumber,"validations":_vm.validations.sitePlanWithLotNumber,"application-id":_vm.formModel.applicationId,"show-info-icon":""}})],1),_c('FormField',{staticClass:"electrical-system-circuit-diagram",attrs:{"model":_vm.model.electricalSystemCircuitDiagram,"content":_vm.content.electricalSystemCircuitDiagram,"validations":_vm.validations.electricalSystemCircuitDiagram,"show-summary":_vm.showSummary,"summary-value":_vm.model.electricalSystemCircuitDiagram.value
                ? _vm.model.electricalSystemCircuitDiagram.value.name
                : ''}},[_c('FormFileUpload',{attrs:{"model":_vm.model.electricalSystemCircuitDiagram,"content":_vm.content.electricalSystemCircuitDiagram,"configuration":_vm.configuration.electricalSystemCircuitDiagram,"validations":_vm.validations.electricalSystemCircuitDiagram,"application-id":_vm.formModel.applicationId,"show-info-icon":""}})],1),_c('FormField',{staticClass:"proof-of-ready-for-planning",attrs:{"model":_vm.model.proofOfReadyForPlanning,"content":_vm.content.proofOfReadyForPlanning,"validations":_vm.validations.proofOfReadyForPlanning,"show-summary":_vm.showSummary,"summary-value":_vm.model.proofOfReadyForPlanning.value
                ? _vm.model.proofOfReadyForPlanning.value.name
                : ''}},[_c('FormFileUpload',{attrs:{"model":_vm.model.proofOfReadyForPlanning,"content":_vm.content.proofOfReadyForPlanning,"configuration":_vm.configuration.proofOfReadyForPlanning,"validations":_vm.validations.proofOfReadyForPlanning,"application-id":_vm.formModel.applicationId}})],1),(!_vm.showSummary)?_c('ButtonSpacer',{attrs:{"align":"right","content":_vm.content,"button-text":_vm.content.buttonNext.text,"show-debug-fill-button":_vm.showDebugFillButton},on:{"button-click":_vm.validateAndSubmit,"debug-fill-button-click":_vm.debugFillSubmit}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }