




















































































































































































































































































































































































































import { PowerConnectionFormStepContent } from '@/api/interfaces/content/form/steps/powerConnectionFormStepContent';
import { getFormStepMixin } from '@/mixins/formStepMixin';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormRadioGroup from '@/components/molecules/formRadioGroup/FormRadioGroup.vue';
import ButtonSpacer from '@/components/molecules/buttonSpacer/ButtonSpacer.vue';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import FormRadioGroupTiles from '@/components/molecules/formRadioGroupTiles/FormRadioGroupTiles.vue';
import FormFileUpload from '@/components/molecules/formFileUpload/FormFileUpload.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import DatePicker from '@/components/molecules/datePicker/DatePicker.vue';
import FormInputPowerOldNewSum from '@/components/molecules/formInputPowerOldNewSum/FormInputPowerOldNewSum.vue';
import { PowerConnectionFormStep } from '@/types/forms/formSteps/powerConnectionFormStep';
import { FormStepConfiguration } from '@/api/interfaces/configuration/form/base/formStepConfiguration';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { calculateFeedInPower } from '@/services/form/utilities/application/powerConnection';

export default getFormStepMixin<
    PowerConnectionFormStep,
    PowerConnectionFormStepContent,
    FormStepConfiguration,
    ApplicationForm
>().extend({
    name: 'PowerConnectionForm',
    components: {
        FormField,
        FormRadioGroup,
        ButtonSpacer,
        FormTextBlock,
        FormBooleanCheckbox,
        FormRadioGroupTiles,
        FormFileUpload,
        FormInputText,
        DatePicker,
        FormInputPowerOldNewSum,
    },
    computed: {
        calculateFeedInPowerWatchValues() {
            return [
                this.model.hasExistingPlants.value,
                this.model.existingPlantsTotalPower.value,
                this.model.repoweringPlanned.value,
                this.model.repoweringTotalPower.value,
                this.model.whichVoltageLevel.value,
            ];
        },
    },
    watch: {
        calculateFeedInPowerWatchValues: {
            handler() {
                calculateFeedInPower(
                    this.formModel.applicationType,
                    this.formModel.plantPerformance,
                    this.model,
                );
            },
            immediate: true,
        },
        'model.feedInPowerShallBeLimitedByAController.value': {
            handler(newValue: boolean | null) {
                if (newValue === false) {
                    calculateFeedInPower(
                        this.formModel.applicationType,
                        this.formModel.plantPerformance,
                        this.model,
                    );
                }
            },
            immediate: true,
        },
    },
});
