var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.model.disabled)?_c('FieldWithInfoIcon',{attrs:{"show-info-icon":_vm.showInfoIcon,"content":_vm.content}},[_c('div',{staticClass:"autosuggest"},[_c('VueSimpleSuggest',{ref:"suggest",attrs:{"list":_vm.getSuggestionsMethod,"filter-by-query":_vm.filterByInput,"destyled":true,"placeholder":_vm.content.placeholder,"max-suggestions":_vm.maxSuggestions,"min-length":_vm.suggestAfterCharacters,"maxlength":_vm.maxLength,"debounce":_vm.debounce,"styles":{
                vueSimpleSuggest: 'relative',
                inputWrapper: '',
                defaultInput: ("input text-primary bg-surface-variant border border-primary rounded px-4 py-3 lg:text-lg xl:text-xl w-full " + _vm.inputClassesString),
                suggestions:
                    'results absolute bg-surface-variant border border-surface-variant-100 z-10 inset-x-0',
                suggestItem: 'px-4 py-1 cursor-pointer',
            }},on:{"blur":_vm.onBlur},nativeOn:{"keydown":function($event){return _vm.onKeydown($event)}},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"inputValue"}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }