




































import { v4 as uuidv4 } from 'uuid';
import FormLabel from '@/components/atoms/formLabel/FormLabel.vue';
import FormInputRadio from '@/components/atoms/formInputRadio/FormInputRadio.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { FormOption } from '@/types/forms/formOption';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';
import { RadioButtonContent } from '@/api/interfaces/content/form/base/radioButtonContent';
import { RadioButtonsField } from '@/types/forms/fields/radioButtonsField';
import InfoIconWrapper from '@/components/molecules/infoIconWrapper/InfoIconWrapper.vue';

export default getFormInputMixin<
    RadioButtonsField<string>,
    RadioButtonContent
>().extend({
    name: 'FormRadioGroup',
    components: {
        FieldWithInfoIcon,
        FormLabel,
        FormInputRadio,
        InfoIconWrapper,
    },
    data: () => {
        return {
            radioButtonGroupName: uuidv4(),
        };
    },
    computed: {
        enabledOptions(): FormOption<string>[] {
            return this.model.options.filter((options) => !options.disabled);
        },
    },
    watch: {
        enabledOptions: {
            async handler() {
                // do not change value, if it is still enabled
                if (
                    this.enabledOptions.some(
                        (option) => option.key === this.model.value,
                    )
                ) {
                    return;
                }

                // wait additional step to trigger rules again
                await this.$nextTick();

                // reset value to first option, if there is any
                this.model.value =
                    this.enabledOptions.length > 0
                        ? this.enabledOptions[0].key
                        : null;
            },
            immediate: true,
        },
    },
});
