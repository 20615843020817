





























import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import OwnerFormGroup from '@/components/organisms/ownerFormGroup/OwnerFormGroup.vue';
import type { OwnerFormGroup as OwnerFormGroupType } from '@/types/forms/formGroups/ownerFormGroup';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import type { OwnerFormListType } from '@/types/forms/formGroups/ownerFormList';
import type { OwnerFormListContent } from '@/api/interfaces/content/form/steps/propertyOwnerFormStepContent';

export default getFormInputMixin<
    OwnerFormListType,
    OwnerFormListContent
>().extend({
    name: 'OwnerFormList',
    components: {
        FunctionalityButton,
        OwnerFormGroup,
    },
    data() {
        return {
            functionalityButtonValue: 'add' as FunctionalityButtonValueType,
        };
    },
    methods: {
        handleFunctionalityButtonClick(
            value: FunctionalityButtonValueType,
        ): void {
            if (value === 'add') {
                this.model.createItem();
            }
        },
        handleOwnerFunctionalityButtonClick(
            value: FunctionalityButtonValueType,
            owner: OwnerFormGroupType,
        ): void {
            if (value === 'remove') {
                this.model.removeItem(owner);
            }
        },
    },
});
