
































import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import StorageModuleFormGroup from '@/components/organisms/plantPerformance/storageModuleFormGroup/StorageModuleFormGroup.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import type { StorageModuleFormList } from '@/types/forms/formGroups/plantPerformance/storage/storageModuleFormList';
import { StorageModuleFormListContent } from '@/api/interfaces/content/form/steps/plantPerformance/storageContent';
import type { StorageModuleFormGroup as StorageModuleFormGroupType } from '@/types/forms/formGroups/plantPerformance/storage/storageModuleFormGroup';

export default getFormInputMixin<
    StorageModuleFormList,
    StorageModuleFormListContent
>().extend({
    name: 'StorageModuleFormList',
    components: {
        FunctionalityButton,
        StorageModuleFormGroup,
    },
    props: {
        applicationId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            functionalityButtonValue: 'add' as FunctionalityButtonValueType,
        };
    },
    computed: {
        canAddItem(): boolean {
            return this.model.canAddItem();
        },
        canRemoveItem(): boolean {
            return this.model.canRemoveItem();
        },
    },
    methods: {
        handleFunctionalityButtonClick(): void {
            this.model.createItem();
        },
        handleModuleFunctionalityButtonClick(
            value: FunctionalityButtonValueType,
            storageModule: StorageModuleFormGroupType,
        ): void {
            if (value === 'remove') {
                this.model.removeItem(storageModule);
            }
        },
    },
});
