






































import { getFormInputTextMixin } from '@/mixins/formInputTextMixin';
import { CountField } from '@/types/forms/fields/countField';
import { digitKeys } from '@/services/keys';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import { TextInputWithUnitContent } from '@/api/interfaces/content/form/base/textInputWithUnitContent';

export default getFormInputTextMixin<
    CountField,
    TextInputWithUnitContent
>().extend({
    name: 'FormInputCount',
    components: {
        FieldWithInfoIcon,
        FormInputText,
    },
    data() {
        return {
            formInputIsFocused: false,
            inputModel: {
                value: null as string | null,
                userChangeable: true,
            },
        };
    },
    computed: {
        digitKeys(): string[] {
            return digitKeys;
        },
        isMin(): boolean {
            return (
                this.model.value !== null && this.model.value <= this.model.min
            );
        },
        isMax(): boolean {
            return (
                this.model.value !== null && this.model.value >= this.model.max
            );
        },
        containerClassList(): string[] {
            const classes = [
                this.hasErrors ? 'border-error' : 'border-primary',
            ];
            if (this.formInputIsFocused) {
                classes.push(
                    this.hasErrors ? 'outline-error' : 'outline-primary',
                );
            }
            return classes;
        },
        decrementButtonClassList(): string {
            return this.getButtonClassList(this.isMin);
        },
        incrementButtonClassList(): string {
            return this.getButtonClassList(this.isMax);
        },
    },
    watch: {
        model: {
            handler(): void {
                this.inputModel.value =
                    this.model.value === null ? null : `${this.model.value}`;
            },
            deep: true,
            immediate: true,
        },
        inputModel: {
            handler(): void {
                if (
                    this.inputModel.value === null ||
                    this.inputModel.value === ''
                ) {
                    this.model.value = null;
                    return;
                }
                const parsedValue = parseInt(this.inputModel.value);
                if (!isNaN(parsedValue)) {
                    this.model.value = parsedValue;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        getButtonClassList(isLimit: boolean): string {
            if (isLimit) {
                const inactiveClass = this.hasErrors
                    ? 'bg-error-inactive'
                    : 'bg-primary-inactive';

                return `cursor-default ${inactiveClass}`;
            }

            return this.hasErrors
                ? 'bg-error hover:bg-error-variant'
                : 'bg-primary hover:bg-primary-variant';
        },
        onFormInputFocus(): void {
            this.formInputIsFocused = true;
        },
        onFormInputBlur(): void {
            this.formInputIsFocused = false;
        },
        onKeydown({ key }: KeyboardEvent): void {
            switch (key) {
                case 'ArrowDown':
                case '-':
                    this.decrement();
                    return;

                case 'ArrowUp':
                case '+':
                    this.increment();
                    return;
            }
        },
        decrement(): void {
            if (this.isMin) {
                return;
            }

            if (this.model.value === null) {
                this.model.value = this.model.min;
                return;
            }

            this.model.value -= this.model.step;
        },
        increment(): void {
            if (this.isMax) {
                return;
            }

            if (this.model.value === null) {
                this.model.value = this.model.min;
                return;
            }

            this.model.value += this.model.step;
        },
    },
});
