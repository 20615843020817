

































import Vue from 'vue';
import VueSimpleSuggest from 'vue-simple-suggest/dist/cjs';
import { getFormInputTextMixin } from '@/mixins/formInputTextMixin';
import { TextField } from '@/types/forms/fields/textField';
import { TextInputWithUnitContent } from '@/api/interfaces/content/form/base/textInputWithUnitContent';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';

//types have not been specified with the VueSimpleSuggest plugin. To avoid declaring the whole module,
//which is quite a bit of overhead, we use an interface here to scope the props and methods we will use
interface VueSimpleSuggestScope extends Vue {
    clearSuggestions(): void;
}

export default getFormInputTextMixin<
    TextField,
    TextInputWithUnitContent
>().extend({
    name: 'FormInputTextWithSuggestions',
    components: {
        FieldWithInfoIcon,
        VueSimpleSuggest,
    },
    props: {
        clearableSuggestions: {
            type: Object,
            default: null,
        },
        getSuggestionsMethod: {
            type: Function,
            required: true,
        },
        maxSuggestions: {
            type: Number,
            default: 5,
        },
        suggestAfterCharacters: {
            type: Number,
            default: 2,
        },
        filterByInput: {
            type: Boolean,
            default: false,
        },
        debounce: {
            type: Number,
            default: 0,
        },
        /* minLength for the html input.minlength attribute is not possible because of a bug
           in vue-simple-suggest (it uses the same property name for a different thing) */
    },
    computed: {
        inputClassesString(): string {
            return this.inputClasses.join(' ');
        },
        inputValue: {
            get(): string {
                return this.model.value === null ? '' : this.model.value;
            },
            set(value: string): void {
                if (value === '' && this.model.value === null) {
                    return;
                }
                this.model.value = value;
            },
        },
    },
    watch: {
        clearableSuggestions: function () {
            (this.$refs.suggest as VueSimpleSuggestScope).clearSuggestions();
        },
    },
});
