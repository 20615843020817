export function setAllProps(obj: Record<string, unknown>, val: unknown): void {
    Object.keys(obj).forEach((k) => (obj[k] = val));
}

export function htmlDecode(input: string): string | null {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
}

/**
 * Apply template literals for static string, for example fetched from database.
 *
 * @param string static string.
 * @param context object with the content, that can be accessed from the string.
 * @returns interpolated string.
 * @example
 * ```
 * staticStringTemplateLiterals('Hello ${this.lorem}', { lorem: 'ipsum' });
 * ```
 * returns: `Hello ipsum`
 */
export function staticStringTemplateLiterals(
    string: string,
    context: unknown,
): string {
    return new Function('return `' + string + '`').call(context);
}
