var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormField',{staticClass:"inverter-manufacturer-module-type",attrs:{"model":_vm.model.inverterManufacturerAndModuleType,"content":_vm.content.inverterManufacturerAndModuleType,"validations":_vm.validationProps.inverterManufacturerAndModuleType,"show-summary":_vm.showSummary,"summary-value":_vm._f("concat")([
                _vm.model.inverterManufacturerAndModuleType.manufacturer.value,
                ', ',
                _vm.model.inverterManufacturerAndModuleType.moduleType.value ])}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/2 mr-4 md:mr-10"},[_c('FormInputText',{attrs:{"model":_vm.model.inverterManufacturerAndModuleType.manufacturer,"content":_vm.content.inverterManufacturerAndModuleType
                            .manufacturer,"validations":_vm.inverterManufacturerAndModuleTypeValidations.manufacturer}})],1),_c('div',{staticClass:"w-1/2"},[_c('FormInputText',{attrs:{"model":_vm.model.inverterManufacturerAndModuleType.moduleType,"content":_vm.content.inverterManufacturerAndModuleType.moduleType,"validations":_vm.inverterManufacturerAndModuleTypeValidations.moduleType}})],1)])]),_c('FormField',{staticClass:"inverter-quantity",attrs:{"model":_vm.model.inverterQuantity,"content":_vm.content.inverterQuantity,"validations":_vm.validationProps.inverterQuantity,"show-summary":_vm.showSummary,"summary-value":_vm.model.inverterQuantity.value}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/2 mr-4 md:mr-10"},[_c('FormInputCount',{attrs:{"model":_vm.model.inverterQuantity,"content":_vm.content.inverterQuantity,"validations":_vm.validationProps.inverterQuantity}})],1),_c('div',{staticClass:"w-1/2"})])]),_c('FormField',{staticClass:"inverter-contribution-symmetrical-short-circuit-current",attrs:{"model":_vm.model.inverterContributionSymmetricalShortCircuitCurrent,"content":_vm.content.inverterContributionSymmetricalShortCircuitCurrent,"validations":_vm.validationProps.inverterContributionSymmetricalShortCircuitCurrent,"show-summary":_vm.showSummary,"summary-value":_vm._f("concat")([
                _vm.model.inverterContributionSymmetricalShortCircuitCurrent
                    .value,
                ' ',
                _vm.content.inverterContributionSymmetricalShortCircuitCurrent
                    .unit ])}},[_c('FormInputText',{attrs:{"model":_vm.model.inverterContributionSymmetricalShortCircuitCurrent,"content":_vm.content.inverterContributionSymmetricalShortCircuitCurrent,"validations":_vm.validationProps.inverterContributionSymmetricalShortCircuitCurrent,"align-text":"right","show-info-icon":""}})],1),_c('FormField',{staticClass:"inverter-voltage-symmetrical-short-circuit-current",attrs:{"model":_vm.model.inverterVoltageSymmetricalShortCircuitCurrent,"content":_vm.content.inverterVoltageSymmetricalShortCircuitCurrent,"validations":_vm.validationProps.inverterVoltageSymmetricalShortCircuitCurrent,"show-summary":_vm.showSummary,"summary-value":_vm._f("concat")([
                _vm.model.inverterVoltageSymmetricalShortCircuitCurrent.value,
                ' ',
                _vm.content.inverterVoltageSymmetricalShortCircuitCurrent.unit ])}},[_c('FormInputText',{attrs:{"model":_vm.model.inverterVoltageSymmetricalShortCircuitCurrent,"content":_vm.content.inverterVoltageSymmetricalShortCircuitCurrent,"validations":_vm.validationProps.inverterVoltageSymmetricalShortCircuitCurrent,"align-text":"right"}})],1),_c('FormField',{staticClass:"inverter-contribution-sustained-short-circuit-current",attrs:{"model":_vm.model.inverterContributionSustainedShortCircuitCurrent,"content":_vm.content.inverterContributionSustainedShortCircuitCurrent,"validations":_vm.validationProps.inverterContributionSustainedShortCircuitCurrent,"show-summary":_vm.showSummary,"summary-value":_vm._f("concat")([
                _vm.model.inverterContributionSustainedShortCircuitCurrent
                    .value,
                ' ',
                _vm.content.inverterContributionSustainedShortCircuitCurrent
                    .unit ])}},[_c('FormInputText',{attrs:{"model":_vm.model.inverterContributionSustainedShortCircuitCurrent,"content":_vm.content.inverterContributionSustainedShortCircuitCurrent,"validations":_vm.validationProps.inverterContributionSustainedShortCircuitCurrent,"align-text":"right"}})],1),_c('FormField',{staticClass:"inverter-voltage-sustained-short-circuit-current",attrs:{"model":_vm.model.inverterVoltageSustainedShortCircuitCurrent,"content":_vm.content.inverterVoltageSustainedShortCircuitCurrent,"validations":_vm.validationProps.inverterVoltageSustainedShortCircuitCurrent,"show-summary":_vm.showSummary,"summary-value":_vm._f("concat")([
                _vm.model.inverterVoltageSustainedShortCircuitCurrent.value,
                ' ',
                _vm.content.inverterVoltageSustainedShortCircuitCurrent.unit ])}},[_c('FormInputText',{attrs:{"model":_vm.model.inverterVoltageSustainedShortCircuitCurrent,"content":_vm.content.inverterVoltageSustainedShortCircuitCurrent,"validations":_vm.validationProps.inverterVoltageSustainedShortCircuitCurrent,"align-text":"right","show-info-icon":""}})],1),_c('FormField',{staticClass:"inverter-active-power-apparent-power",attrs:{"model":_vm.model.inverterActivePowerAndApparentPower,"content":_vm.content.inverterActivePowerAndApparentPower,"validations":_vm.validationProps.inverterActivePowerAndApparentPower,"show-summary":_vm.showSummary,"summary-value":_vm._f("concat")([
                _vm.model.inverterActivePowerAndApparentPower.activePower.value,
                ' ',
                _vm.content.inverterActivePowerAndApparentPower.activePower
                    .unit,
                ', ',
                _vm.model.inverterActivePowerAndApparentPower.apparentPower
                    .value,
                ' ',
                _vm.content.inverterActivePowerAndApparentPower.apparentPower
                    .unit ])}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/2 mr-4 md:mr-10"},[_c('FormInputText',{attrs:{"model":_vm.model.inverterActivePowerAndApparentPower
                            .activePower,"content":_vm.content.inverterActivePowerAndApparentPower
                            .activePower,"validations":_vm.validationProps.inverterActivePowerAndApparentPower &&
                        _vm.validationProps.inverterActivePowerAndApparentPower
                            .activePower,"align-text":"right"}})],1),_c('div',{staticClass:"w-1/2"},[_c('FormInputText',{attrs:{"model":_vm.model.inverterActivePowerAndApparentPower
                            .apparentPower,"content":_vm.content.inverterActivePowerAndApparentPower
                            .apparentPower,"validations":_vm.validationProps.inverterActivePowerAndApparentPower &&
                        _vm.validationProps.inverterActivePowerAndApparentPower
                            .apparentPower,"align-text":"right"}})],1)])]),_c('FormField',{staticClass:"inverter-total-active-power",attrs:{"model":_vm.model.inverterTotalActivePower,"content":_vm.content.inverterTotalActivePower,"validations":_vm.validationProps.inverterTotalActivePower,"show-summary":_vm.showSummary,"summary-value":_vm.totalActivePowerDisplayValue}},[_c('span',{staticClass:"inverter-total-active-power-display"},[_vm._v(_vm._s(_vm.totalActivePowerDisplayValue))])]),_c('FormField',{staticClass:"inverter-total-apparent-power",attrs:{"model":_vm.model.inverterTotalApparentPower,"content":_vm.content.inverterTotalApparentPower,"validations":_vm.validationProps.inverterTotalApparentPower,"show-summary":_vm.showSummary,"summary-value":_vm.totalApparentPowerDisplayValue}},[_c('span',{staticClass:"inverter-total-apparent-power-display"},[_vm._v(_vm._s(_vm.totalApparentPowerDisplayValue))])]),(_vm.showSummary)?_c('div',{staticClass:"h-4"}):_vm._e(),(!_vm.showSummary && _vm.showRemoveButton)?_c('FunctionalityButton',{staticClass:"inverter-remove-button",attrs:{"content":_vm.content.inverterAddRemoveModule,"value":_vm.functionalityButtonValue},on:{"click":function($event){return _vm.$emit('functionality-button-click', $event)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }