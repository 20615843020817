















import { getFormInputMixin } from '@/mixins/formInputMixin';
import { PropType } from 'vue';

export default getFormInputMixin().extend({
    name: 'FormInputRadio',
    props: {
        value: {
            type: String as PropType<string | undefined>,
            default: undefined,
        },
        name: {
            type: String,
            required: true,
        },
        inputValue: {
            type: String,
            required: true,
        },
    },
});
