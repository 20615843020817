








































import { getFormInputMixin } from '@/mixins/formInputMixin';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import PhotovoltaicModuleFormList from '@/components/organisms/plantPerformance/photovoltaicModuleFormList/PhotovoltaicModuleFormList.vue';
import { PhotovoltaicFormGroup } from '@/types/forms/formGroups/plantPerformance/photovoltaic/photovoltaicFormGroup';
import type { PhotovoltaicFormGroupContent } from '@/api/interfaces/content/form/steps/plantPerformance/photovoltaicContent';
import type { PhotovoltaicModuleFormList as PhotovoltaicModuleFormListType } from '@/types/forms/formGroups/plantPerformance/photovoltaic/photovoltaicModuleFormList';
import { interpolate } from '@/services/interpolationService';
import { formatGermanNumber } from '@/utilities/germanFormats';
import { roundToDecimals } from '@/utilities/math';

export default getFormInputMixin<
    PhotovoltaicFormGroup,
    PhotovoltaicFormGroupContent
>().extend({
    name: 'PhotovoltaicFormGroup',
    props: {
        applicationId: {
            type: String,
            required: true,
        },
    },
    components: {
        FormField,
        FormTextBlock,
        PhotovoltaicModuleFormList,
    },
    computed: {
        overallNominalPowerDisplayValue(): string {
            const value = this.model.pvOverallNominalPower.value ?? 0;
            return interpolate(this.content.pvOverallNominalPower.unit, {
                value: formatGermanNumber(value, {
                    decimals: 2,
                    thousandsSeparator: true,
                }),
            });
        },
    },
    watch: {
        'model.pvModules': {
            handler(newValue: PhotovoltaicModuleFormListType) {
                let overallNominalPower = 0;
                if (newValue != null) {
                    overallNominalPower = roundToDecimals(
                        newValue.items.reduce(
                            (sum, item) =>
                                (sum += item.pvTotalNominalPower.value ?? 0),
                            0,
                        ) / 1000,
                    );
                }
                this.setOverallNominalPower(overallNominalPower);
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        setOverallNominalPower(overallNominalPower: number): void {
            this.model.pvOverallNominalPower.value = overallNominalPower;
        },
    },
});
