import { addressRepository } from '@/api';
import { AddressSuggestions } from '@/api/interfaces/address/addressSuggestions';
import { TextField } from '@/types/forms/fields/textField';
import { StreetAndHousenumberFormGroup } from '@/types/forms/formGroups/streetAndHousenumberFormGroup';
import { ZipAndCityFormGroup } from '@/types/forms/formGroups/zipAndCityFormGroup';
import { setAllProps } from '@/utilities/utils';

export class AddressService {
    static readonly noDistrictFoundServiceValue = 'kein Ortsteil';
    addressSuggestions: AddressSuggestions;

    constructor() {
        this.addressSuggestions = {
            zips: [],
            cities: [],
            districts: [],
            streets: [],
        } as AddressSuggestions;
    }

    private getAddressSuggestions(
        order: string[],
        zip: string | null,
        city: string | null,
        street: string | null,
        district: string | null,
    ): Promise<AddressSuggestions> {
        return addressRepository
            .fetchSuggestions(order, zip, city, street, district)
            .then((results) => {
                results.districts = results.districts.filter(
                    (d) => d !== AddressService.noDistrictFoundServiceValue,
                );
                this.addressSuggestions = results;
                return results;
            });
    }

    public async getZipSuggestions(
        query: string,
        zipAndCity: ZipAndCityFormGroup,
        streetAndHousenumber: StreetAndHousenumberFormGroup,
        district?: TextField,
    ): Promise<string[]> {
        return this.getAddressSuggestions(
            ['zip'],
            query,
            zipAndCity.city.value,
            streetAndHousenumber.street.value,
            district?.value ?? null,
        ).then((results) => {
            return results.zips;
        });
    }

    public async getCitySuggestions(
        query: string,
        zipAndCity: ZipAndCityFormGroup,
        streetAndHousenumber: StreetAndHousenumberFormGroup,
        district?: TextField,
    ): Promise<string[]> {
        return this.getAddressSuggestions(
            ['zip', 'city'],
            zipAndCity.zip.value,
            query,
            streetAndHousenumber.street.value,
            district?.value ?? null,
        ).then((results) => results.cities);
    }

    public async getDistrictSuggestions(
        query: string,
        zipAndCity: ZipAndCityFormGroup,
        streetAndHousenumber: StreetAndHousenumberFormGroup,
    ): Promise<string[]> {
        return this.getAddressSuggestions(
            ['zip', 'city', 'street', 'district'],
            zipAndCity.zip.value,
            zipAndCity.city.value,
            streetAndHousenumber.street.value,
            query,
        ).then((results) => results.districts);
    }

    public async getStreetSuggestions(
        query: string,
        zipAndCity: ZipAndCityFormGroup,
        district?: TextField,
    ): Promise<string[]> {
        return this.getAddressSuggestions(
            ['zip', 'city', 'street'],
            zipAndCity.zip.value,
            zipAndCity.city.value,
            query,
            district?.value ?? null,
        ).then((results) => results.streets);
    }

    setCity(): string | null {
        // if a single city is found for this zip, insert immediately
        if (
            this.addressSuggestions != null &&
            this.addressSuggestions.cities.length === 1
        ) {
            return this.addressSuggestions.cities[0];
        } else {
            return null;
        }
    }

    clearSuggestionFields(fields: Record<string, unknown>): void {
        setAllProps(fields, {});
    }
}

export const addressService = new AddressService();
