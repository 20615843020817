import Vue, { PropType } from 'vue';
import { noop as _noop, isEmpty as _isEmpty } from 'lodash';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';
import { VueConstructor } from 'vue/types/umd';
import { appState } from '@/services/form/appState';
import { Form } from '@/types/forms/form';
import { FormDto } from '@/services/form/dto';
import { modelService } from '@/services/form/modelService';
import { authService } from '@/services/authService';
import { FormContent } from '@/api/interfaces/content/form/formContent';

// eslint-disable-next-line @typescript-eslint/comma-dangle
interface FormStepComponent<
    TModel,
    TContent,
    TConfiguration,
    TFormType extends Form = Form
> extends Vue {
    readonly content: TContent;
    readonly formContent: FormContent;
    readonly configuration: TConfiguration;
    readonly model: TModel;
    readonly formModel: TFormType;
    readonly validations:
        | (Validation & ValidationGroups & ValidationProperties<unknown>)
        | null;
    readonly rules: (component: this) => void;
    readonly showSummary: boolean;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export function getFormStepMixin<
    TModel,
    TContent,
    TConfiguration,
    TFormType extends Form = Form
>(): VueConstructor<
    FormStepComponent<TModel, TContent, TConfiguration, TFormType>
> {
    return Vue.extend({
        props: {
            content: {
                type: Object as PropType<TContent>,
                default: () => ({} as TContent),
            },
            formContent: {
                type: Object as PropType<FormContent>,
                default: () => ({} as FormContent),
            },
            configuration: {
                type: Object as PropType<TConfiguration>,
                default: () => ({} as TConfiguration),
            },
            identifier: {
                type: String,
                default: null,
            },
            debugDto: {
                type: Object as PropType<FormDto>,
                default: () => ({}),
            },
            model: {
                type: Object as PropType<TModel>,
                default: () => ({} as TModel),
            },
            formModel: {
                type: Object as PropType<TFormType>,
                default: () => ({} as TFormType),
            },
            validations: {
                type: Object as PropType<
                    | (Validation &
                          ValidationGroups &
                          ValidationProperties<TModel>)
                    | null
                >,
                default: null,
            },
            rules: {
                type: Function as PropType<
                    (
                        component: FormStepComponent<
                            TModel,
                            TContent,
                            TConfiguration
                        >,
                        cb: () => void,
                    ) => void
                >,
                default: () => {
                    return _noop;
                },
            },
            showSummary: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                disableWatch: false,
                appState,
            };
        },
        computed: {
            showDebugFillButton(): boolean {
                return (
                    authService.isAuthenticated &&
                    authService.hasRoles('DEA Debug') &&
                    !_isEmpty(this.debugDto)
                );
            },
            allValidationsAreValid(): boolean {
                return !this.validations?.$invalid;
            },
        },
        watch: {
            model: {
                handler() {
                    if (!this.rules || this.disableWatch) {
                        return;
                    }
                    this.disableWatch = true;
                    this.rules(this, () => {
                        this.disableWatch = false;
                    });
                },
                deep: true,
                immediate: true,
            },
        },
        methods: {
            isFormValid(): boolean {
                this.validations?.$touch();
                return this.allValidationsAreValid;
            },
            async debugFillSubmit(): Promise<void> {
                await this.$nextTick();
                modelService.loadFormIntoModel(
                    this.debugDto,
                    `application.${this.identifier}`,
                );
                await this.$nextTick();
                return this.validateAndSubmit();
            },
            async validateAndSubmit(): Promise<void> {
                if (this.isFormValid()) {
                    this.$emit('submit');
                } else {
                    await this.$nextTick();
                    this.$emit('validation-failed');
                }
            },
        },
    });
}
