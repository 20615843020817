










































































// IE crashes if we include this statically, so it's loaded conditionally in main.ts.
// import VDatePicker from './VDatePicker.vue';

import { PropType } from 'vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import { isIE } from '@/utilities/browserDetection';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';
import { DateRangeField } from '@/types/forms/fields/dateRangeField';
import { TextInputContent } from '@/api/interfaces/content/form/base/textInputContent';
import moment from 'moment';
import { germanDateFormat } from '@/utilities/germanFormats';
import { DateField } from '@/types/forms/fields/dateField';

const msToWaitForDatePicker = 50;

export default getFormInputMixin<
    DateField | DateRangeField,
    TextInputContent
>().extend({
    name: 'DatePicker',
    components: {
        FieldWithInfoIcon,
        FormInputText,
        // VDatePicker,
    },
    props: {
        showTodayButton: {
            // not working correctly. :( Leaving as concept.
            type: Boolean,
            default: false,
        },
        contentDateStart: {
            type: Object as PropType<TextInputContent>,
            default: () => {
                return {} as TextInputContent;
            },
        },
        contentDateEnd: {
            type: Object as PropType<TextInputContent>,
            default: () => {
                return {} as TextInputContent;
            },
        },
    },
    computed: {
        contentDateStartWithFallback(): TextInputContent {
            if (Object.keys(this.contentDateStart).length) {
                return this.contentDateStart;
            } else {
                return this.content as TextInputContent;
            }
        },
        contentDateEndWithFallback(): TextInputContent {
            if (Object.keys(this.contentDateEnd).length) {
                return this.contentDateEnd;
            } else {
                return this.content as TextInputContent;
            }
        },
        datePickerSupported(): boolean {
            return !isIE();
        },
        isRange(): boolean {
            return this.model instanceof DateRangeField;
        },
    },
    methods: {
        focusDatePickerInput(e: Event): void {
            const target = e?.target as HTMLElement;
            if (target?.classList.contains('input-field')) {
                const input = target.querySelector('input') as HTMLElement;
                if (input) {
                    setTimeout(() => {
                        input.focus();
                        input.click();
                    }, msToWaitForDatePicker);
                }
            }
        },
        setDateToday(): void {
            const currentDateString = moment().format(germanDateFormat);
            if (this.model instanceof DateRangeField && !!this.model.value) {
                const currentStart = this.model.value.start;
                this.model.value = {
                    start: currentStart,
                    end: currentDateString,
                };
            } else {
                this.model.value = currentDateString;
            }
        },
    },
});
