






























import { PowerOldNewSumContent } from '@/api/interfaces/content/form/base/powerOldNewSumContent';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { interpolate } from '@/services/interpolationService';
import { PowerOldNewSumFormGroup } from '@/types/forms/formGroups/powerOldNewSumFormGroup';
import { formatGermanNumber } from '@/utilities/germanFormats';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';

export default getFormInputMixin<
    PowerOldNewSumFormGroup,
    PowerOldNewSumContent
>().extend({
    name: 'FormInputPowerOldNewSum',
    components: {
        FormInputText,
        FieldWithInfoIcon,
    },
    created() {
        this.$watch('validations.old.$dirty', () => this.touchAllInputs());
        this.$watch('validations.new.$dirty', () => this.touchAllInputs());
    },
    methods: {
        touchAllInputs() {
            if (!this.validations) {
                return;
            }
            if (this.validations.old) {
                this.validations.old.$touch();
            }
            if (this.validations.new) {
                this.validations.new.$touch();
            }
        },
    },
    computed: {
        summary(): string {
            if (!this.content.summary.text) {
                return '';
            }

            return interpolate(this.content.summary.text, {
                total: formatGermanNumber(this.model.sum, {
                    decimals: 2,
                    thousandsSeparator: true,
                }),
            });
        },
    },
});
