

































import { v4 as uuidv4 } from 'uuid';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import FormLabel from '@/components/atoms/formLabel/FormLabel.vue';
import FormInputRadio from '@/components/atoms/formInputRadio/FormInputRadio.vue';
import AppImage from '@/components/atoms/appImage/AppImage.vue';
import { RadioButtonsField } from '@/types/forms/fields/radioButtonsField';
import { FormOption } from '@/types/forms/formOption';
import { RadioButtonTileContent } from '@/api/interfaces/content/form/base/radioButtonTileContent';
import { RadioButtonTilesContent } from '@/api/interfaces/content/form/base/radioButtonTilesContent';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';

export default getFormInputMixin<
    RadioButtonsField<string>,
    RadioButtonTilesContent
>().extend({
    name: 'FormRadioGroupTiles',
    components: {
        FieldWithInfoIcon,
        FormLabel,
        FormInputRadio,
        AppImage,
    },
    data: () => {
        return {
            radioButtonGroupName: uuidv4(),
        };
    },
    computed: {
        enabledOptions(): FormOption<string>[] {
            return this.model.options.filter((options) => !options.disabled);
        },
        enabledOptionsWithContent(): (FormOption<string> & {
            content: RadioButtonTileContent;
        })[] {
            return this.enabledOptions
                .filter((option) => !option.disabled)
                .map((option) => ({
                    ...option,
                    content: this.content.options[option.key],
                }));
        },
    },
    watch: {
        enabledOptions: {
            async handler() {
                // do not change value, if it is still enabled
                if (
                    this.enabledOptions.some(
                        (option) => option.key === this.model.value,
                    )
                ) {
                    return;
                }

                // wait additional step to trigger rules again
                await this.$nextTick();

                // reset value to first option, if there is any
                this.model.value =
                    this.enabledOptions.length > 0
                        ? this.enabledOptions[0].key
                        : null;
            },
            immediate: true,
        },
    },
    methods: {
        selectRadioButton(option: FormOption<string>): void {
            this.model.value = option.key;
        },
    },
});
