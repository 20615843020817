export const debugDto = {
    location: {
        zipAndCity: {
            zip: '12345',
            city: 'TestCity1',
        },
        streetAndHousenumber: {
            street: 'StreetCity1',
            housenumber: '1',
        },
        availabilityResult: 'available',
        geographicLatCoordinates: {
            degrees: '53',
            minutes: '8',
            seconds: '16',
        },
        geographicLngCoordinates: {
            degrees: '8',
            minutes: '12',
            seconds: '17,2',
        },
    },
    controllableConsumptionDevices: {
        gridControlConditionsAccepted: true,
    },
    powerConnection: {
        doesConnectionExist: 'yes',
        whichVoltageLevel: 'lowVoltage',
        sitePlanWithLotNumber: {
            id: 'd92fd22e-0dc5-4bfe-be3e-a7f078c2df75',
            name: 'sample-pdf-file.pdf',
            size: 146367,
        },
    },
    plantOperator: {
        separatePropertyOwner: 'yes',
        plantBuilder: 'operator',
        operators: [
            {
                salutation: 'mr',
                title: 'untitled',
                operatorName: { firstName: 'Tester', lastName: 'Test' },
                birthday: '01.01.2021',
            },
        ],
        country: 'deu',
        zipAndCity: { zip: '26121', city: 'Oldenburg' },
        streetAndHousenumber: { street: 'Adolf-Grimme-Str.', housenumber: '1' },
        email: 'email@example.com',
        phoneNumber1: '12345',
    },
    plantBuilder: {
        companyName: 'Test Company',
        builderName: { firstName: 'Tester', lastName: 'Test' },
        country: 'deu',
        zipAndCity: { zip: '26121', city: 'Oldenburg' },
        streetAndHousenumber: { street: 'Adolf-Grimme-Str.', housenumber: '1' },
        email: 'email@example.com',
        phoneNumber1: '12345',
    },
    propertyOwner: {
        owners: [
            {
                salutation: 'mr',
                title: 'untitled',
                ownerName: { firstName: 'Tester', lastName: 'Test' },
                birthday: '01.01.2021',
                country: 'deu',
                zipAndCity: { zip: '26121', city: 'Oldenburg' },
                streetAndHousenumber: {
                    street: 'Adolf-Grimme-Str.',
                    housenumber: '1',
                },
                email: 'email@example.com',
                phoneNumber1: '12345',
            },
        ],
        propertyOwnerAgreement: true,
    },
    plantData: {
        newOrExistingApplication: 'new',
        newOrExistingPlant: 'first',
        storageConnectionConcept:
            'storageWithoutPowerGenerationPlantAndWithoutConsumptionPlant',
        storageCharge: 'onlyFromEeg',
        storageDischarge: 'onlyIntoCustomersGridSelfSupply',
    },
    plantPerformance: {
        photovoltaic: {
            pvModules: [
                {
                    pvQuantityAndNominalPower: {
                        quantity: 1,
                        nominalPower: '200',
                    },
                    pvTotalNominalPower: 200,
                },
            ],
            pvOverallNominalPower: 0.2,
        },
        inverter: {
            inverterModules: [
                {
                    inverterManufacturerAndModuleType: {
                        manufacturer: 'Muster',
                        moduleType: 'Muster Boy 2.5',
                    },
                    inverterQuantity: 6,
                    inverterActivePowerAndApparentPower: {
                        activePower: '2,5',
                        apparentPower: '2,6',
                    },
                    inverterTotalActivePower: 15,
                    inverterTotalApparentPower: 15.6,
                    inverterConformityConfirmationOrUnitCertificate: {
                        id: 'b17cc08a-933e-465b-a452-890511414315',
                        name: 'imago74595426h.jpg',
                        size: 39006,
                    },
                    inverterTechnicalDataSheet: {
                        id: '59352449-fc6f-4662-b46c-7641115662f2',
                        name: 'imago74595426h.jpg',
                        size: 39006,
                    },
                    inverterProofOfConformity: {
                        id: 'f5030890-50e0-4ec8-8e1f-a0f33e3392f0',
                        name: 'imago74595426h.jpg',
                        size: 39006,
                    },
                },
            ],
            inverterOverallActivePower: 15,
            inverterOverallApparentPower: 15.600000000000001,
        },
        storage: {
            storageModules: [
                {
                    storageManufacturerAndStorageType: {
                        manufacturer: 'EW Speicher 2',
                        storageType: 'S4 E Pro',
                    },
                    storageQuantity: 2,
                    storageDischargePowerAndStorageCapacity: {
                        dischargePower: '12',
                        storageCapacity: '12',
                    },
                    storageTotalDischargePower: 24,
                    storageTotalStorageCapacity: 24,
                    storageConformityDeclarationStorageSystem: {
                        id: '22cb1e23-3169-4f19-a20a-6f8da7b7ae82',
                        name: 'imago74595426h.jpg',
                        size: 39006,
                    },
                    storageManufacturerDataSheet: {
                        id: 'fa92dea8-60c0-4828-a7dc-40bcfc0d99fd',
                        name: 'imago74595426h.jpg',
                        size: 39006,
                    },
                },
            ],
            storageOverallDischargePower: 24,
            storageOverallStorageCapacity: 24,
        },
        selfGenerationQuantity: '1000',
        selfSupplyQuantity: '1000',
        mainsConnectionType: 'rotatingCurrent',
        certificateHint: true,
    },
    billing: {
        iban: 'DE89370400440532013000',
        date: '17.01.2024',
        accountOwnerName: 'Tester Test',
    },
};
