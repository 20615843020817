


































































import Vue, { PropType, VueConstructor } from 'vue';
import LocationForm from '@/components/organisms/locationForm/LocationForm.vue';
import GenerationTypeForm from '@/components/organisms/generationTypeForm/GenerationTypeForm.vue';
import MeasuringConceptsForm from '@/components/organisms/measuringConceptsForm/MeasuringConceptsFormGroup.vue';
import ControllableConsumptionDevicesForm from '@/components/organisms/controllableConsumptionDevicesForm/ControllableConsumptionDevicesForm.vue';
import PowerConnectionForm from '@/components/organisms/powerConnectionForm/PowerConnectionForm.vue';
import PlantOperatorForm from '@/components/organisms/plantOperatorForm/PlantOperatorForm.vue';
import PlantBuilderForm from '@/components/organisms/plantBuilderForm/PlantBuilderForm.vue';
import PlantDataForm from '@/components/organisms/plantDataForm/PlantDataForm.vue';
import PropertyOwnerForm from '@/components/organisms/propertyOwnerForm/PropertyOwnerForm.vue';
import BillingForm from '@/components/organisms/billingForm/BillingForm.vue';
import PlantPerformanceForm from '@/components/organisms/plantPerformance/PlantPerformanceForm.vue';
import CheckAndSendForm from '@/components/organisms/checkAndSendForm/CheckAndSendForm.vue';
import Stage from '@/components/organisms/stage/Stage.vue';
import FormStepComponent from '@/components/molecules/formStep/FormStep.vue';
import { ApplicationFormContent } from '@/api/interfaces/content/form/applicationFormContent';
import { rules } from '@/services/form/rules';
import { StepState } from '@/enums/stepState';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import SpinnerArea from '@/components/molecules/spinnerArea/SpinnerArea.vue';
import { SpinnerState } from '@/enums/spinnerState';
import { MessageType } from '@/enums/messageType';
import { alertService } from '@/services/alertService';
import AppLayer from '@/components/molecules/appLayer/AppLayer.vue';
import AppContent from '@/components/atoms/appContent/AppContent.vue';
import AppButton from '@/components/atoms/appButton/AppButton.vue';
import { FormStep } from '@/types/forms/formStep';
import { FormStepContent } from '@/api/interfaces/content/form/base/formStepContent';
import { ApplicationFormConfiguration } from '@/api/interfaces/configuration/form/applicationFormConfiguration';
import { FormStepConfiguration } from '@/api/interfaces/configuration/form/base/formStepConfiguration';
import { ValidationsCommonType } from '@/services/form/validations';
import { FormDto } from '@/services/form/dto';
import { ServiceError } from '@/api/interfaces/serviceError';
import { isServiceError } from '@/api/interfaces/typeGuards';
import DocumentsForm from '@/components/organisms/documents/DocumentsForm.vue';
import { Mkf } from '@/api/interfaces/mkf/mkf';

interface FormStepViewModel {
    identifier: string;
    debugDto: FormDto;
    model: FormStep;
    content: FormStepContent;
    configuration: FormStepConfiguration;
    mkf: Mkf;
    title: string;
    state: StepState;
    spinnerState: SpinnerState;
    component: VueConstructor;
    rules: (formStep: Vue) => void;
    validations?: ValidationsCommonType;
    showSummary: boolean;
}

export default Vue.extend({
    name: 'ApplicationFormTemplate',
    components: {
        Stage,
        FormStepComponent,
        SpinnerArea,
        AppLayer,
        AppContent,
        AppButton,
    },
    props: {
        content: {
            type: Object as PropType<ApplicationFormContent>,
            required: true,
        },
        configuration: {
            type: Object as PropType<ApplicationFormConfiguration>,
            required: true,
        },
        mkf: {
            type: Object as PropType<Mkf>,
            required: true,
        },
        debugDto: {
            type: Object as PropType<FormDto>,
            default: {},
        },
        model: {
            type: Object as PropType<ApplicationForm>,
            required: true,
        },
        editModel: {
            type: Object as PropType<ApplicationForm>,
            required: true,
        },
        rules: {
            type: Object as PropType<typeof rules.application>,
            required: true,
        },
        validations: {
            type: Object,
            required: true,
        },
        editValidations: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            showLayer: false,
        };
    },
    computed: {
        currentStepIndex(): number {
            return this.model.enabledSteps.indexOf(this.model.currentStep);
        },
        maxReachedStepIndex(): number {
            return this.model.enabledSteps.indexOf(this.model.maxReachedStep);
        },
        steps(): FormStepViewModel[] {
            return [
                {
                    identifier: 'generationType',
                    component: GenerationTypeForm,
                    content: {
                        ...this.content.generationTypeContent,
                        common: this.content.common,
                    },
                    debugDto: this.debugDto.generationType,
                    model: this.model.generationType,
                    editModel: this.editModel.generationType,
                    rules: this.rules.generationType,
                    validations: this.validations.generationType,
                    editValidations: this.editValidations.generationType,
                },
                {
                    identifier: 'location',
                    component: LocationForm,
                    content: {
                        ...this.content.locationContent,
                        common: this.content.common,
                    },
                    debugDto: this.debugDto.location,
                    model: this.model.location,
                    editModel: this.editModel.location,
                    rules: this.rules.location,
                    validations: this.validations.location,
                    editValidations: this.editValidations.location,
                },
                {
                    identifier: 'plantPerformance',
                    component: PlantPerformanceForm,
                    content: {
                        ...this.content.plantPerformanceContent,
                        common: this.content.common,
                    },
                    debugDto: this.debugDto.plantPerformance,
                    model: this.model.plantPerformance,
                    editModel: this.editModel.plantPerformance,
                    rules: this.rules.plantPerformance,
                    validations: this.validations.plantPerformance,
                    editValidations: this.editValidations.plantPerformance,
                },
                {
                    identifier: 'powerConnection',
                    component: PowerConnectionForm,
                    content: {
                        ...this.content.powerConnectionContent,
                        common: this.content.common,
                    },
                    debugDto: this.debugDto.powerConnection,
                    model: this.model.powerConnection,
                    editModel: this.editModel.powerConnection,
                    rules: this.rules.powerConnection,
                    validations: this.validations.powerConnection,
                    editValidations: this.editValidations.powerConnection,
                },
                {
                    identifier: 'plantData',
                    component: PlantDataForm,
                    content: {
                        ...this.content.plantDataContent,
                        common: this.content.common,
                    },
                    debugDto: this.debugDto.plantData,
                    model: this.model.plantData,
                    editModel: this.editModel.plantData,
                    rules: this.rules.plantData,
                    validations: this.validations.plantData,
                    editValidations: this.editValidations.plantData,
                },
                {
                    identifier: 'measuringConcepts',
                    component: MeasuringConceptsForm,
                    content: {
                        ...this.content.measuringConceptsContent,
                        common: this.content.common,
                    },
                    mkf: this.mkf,
                    model: this.model.measuringConcepts,
                    editModel: this.editModel.measuringConcepts,
                    rules: this.rules.measuringConcepts,
                    validations: this.validations.measuringConcepts,
                    editValidations: this.editValidations.measuringConcepts,
                },
                {
                    identifier: 'controllableConsumptionDevices',
                    component: ControllableConsumptionDevicesForm,
                    content: {
                        ...this.content.controllableConsumptionDevicesContent,
                        common: this.content.common,
                    },
                    debugDto: this.debugDto.controllableConsumptionDevices,
                    model: this.model.controllableConsumptionDevices,
                    editModel: this.editModel.controllableConsumptionDevices,
                    rules: this.rules.controllableConsumptionDevices,
                    validations: this.validations
                        .controllableConsumptionDevices,
                    editValidations: this.editValidations
                        .controllableConsumptionDevices,
                },
                {
                    identifier: 'plantOperator',
                    component: PlantOperatorForm,
                    content: {
                        ...this.content.plantOperatorContent,
                        common: this.content.common,
                    },
                    debugDto: this.debugDto.plantOperator,
                    model: this.model.plantOperator,
                    editModel: this.editModel.plantOperator,
                    rules: this.rules.plantOperator,
                    validations: this.validations.plantOperator,
                    editValidations: this.editValidations.plantOperator,
                },
                {
                    identifier: 'plantBuilder',
                    component: PlantBuilderForm,
                    content: {
                        ...this.content.plantBuilderContent,
                        common: this.content.common,
                    },
                    debugDto: this.debugDto.plantBuilder,
                    model: this.model.plantBuilder,
                    editModel: this.editModel.plantBuilder,
                    rules: this.rules.plantBuilder,
                    validations: this.validations.plantBuilder,
                    editValidations: this.editValidations.plantBuilder,
                },
                {
                    identifier: 'propertyOwner',
                    component: PropertyOwnerForm,
                    content: {
                        ...this.content.propertyOwnerContent,
                        common: this.content.common,
                    },
                    debugDto: this.debugDto.propertyOwner,
                    model: this.model.propertyOwner,
                    editModel: this.editModel.propertyOwner,
                    rules: this.rules.propertyOwner,
                    validations: this.validations.propertyOwner,
                    editValidations: this.editValidations.propertyOwner,
                },
                {
                    identifier: 'billing',
                    component: BillingForm,
                    content: {
                        ...this.content.billingContent,
                        common: this.content.common,
                    },
                    debugDto: this.debugDto.billing,
                    model: this.model.billing,
                    editModel: this.editModel.billing,
                    rules: this.rules.billing,
                    validations: this.validations.billing,
                    editValidations: this.editValidations.billing,
                },
                {
                    identifier: 'documents',
                    component: DocumentsForm,
                    content: {
                        ...this.content.documentsContent,
                        common: this.content.common,
                    },
                    model: this.model.documents,
                    editModel: this.editModel.documents,
                    rules: this.rules.documents,
                    validations: this.validations.documents,
                    editValidations: this.editValidations.documents,
                    configuration: this.configuration.documentsConfiguration,
                },
                {
                    identifier: 'checkAndSend',
                    component: CheckAndSendForm,
                    content: {
                        ...this.content.checkAndSendContent,
                        common: this.content.common,
                    },
                    model: this.model.checkAndSend,
                    editModel: this.editModel.checkAndSend,
                    rules: this.rules.checkAndSend,
                    validations: this.validations.checkAndSend,
                    editValidations: this.editValidations.checkAndSend,
                },
            ]
                .filter((formStep) => !formStep.model.disabled)
                .map((formStep) => {
                    const stepIsEdit = this.isStepBeingEdited(formStep);
                    const stepState = this.getStepState(formStep);
                    return {
                        identifier: formStep.identifier,
                        debugDto: formStep.debugDto,
                        model: stepIsEdit ? formStep.editModel : formStep.model,
                        formModel: this.model,
                        content: formStep.content,
                        configuration: formStep.configuration ?? {},
                        mkf: formStep.mkf ?? {},
                        title: formStep.content?.stepHeading,
                        state: stepState,
                        spinnerState:
                            this.loading && stepState === StepState.current
                                ? SpinnerState.transparent
                                : SpinnerState.off,
                        component: formStep.component,
                        rules: formStep.rules as (formStep: Vue) => void,
                        validations: stepIsEdit
                            ? formStep.editValidations
                            : formStep.validations,
                        showSummary: stepState === StepState.done,
                    } as FormStepViewModel;
                })
                .filter((formStep) => formStep.content);
        },
    },
    methods: {
        handleSubmitClick(): void {
            if (this.model.editStep) {
                this.showLayer = true;
            } else {
                this.submitForm();
            }
        },
        handleLayerCancelClick(): void {
            this.showLayer = false;
        },
        handleLayerAcceptClick(): void {
            this.showLayer = false;
            this.submitForm();
        },
        getStepRules(formStep: { rules: (vm: Vue) => Promise<void> }) {
            return async (vm: Vue, cb: () => void) => {
                this.rules.general(this.model);
                await formStep.rules(vm);
                if (cb) {
                    cb();
                }
            };
        },
        isStepBeingEdited(formStep: { identifier: string }): boolean {
            return this.model.editStep === formStep.identifier;
        },
        getStepState(formStep: { identifier: string }): StepState {
            if (this.isStepBeingEdited(formStep)) {
                return StepState.edit;
            }

            const index = this.model.enabledSteps.indexOf(formStep.identifier);

            if (index < this.currentStepIndex) {
                return StepState.done;
            } else if (index === this.currentStepIndex) {
                return StepState.current;
            } else if (index < this.maxReachedStepIndex) {
                return StepState.remainingWithData;
            }

            return StepState.remaining;
        },
        submitForm(): void {
            this.loading = true;
            alertService.clear();

            this.$emit('submit-form', (promise: Promise<boolean>) => {
                promise
                    .then((result) => {
                        if (!result) {
                            alertService.pushAlert({
                                alert: {
                                    type: MessageType.error,
                                    identifier: 'applications.saveError',
                                },
                                displayDuration: 0,
                            });
                        }
                    })
                    .catch((error: ServiceError) => {
                        if (isServiceError(error)) {
                            alertService.pushAlert({
                                alert: {
                                    type: MessageType.error,
                                    identifier: error.messageCode,
                                },
                                displayDuration: 0,
                            });
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
        focusFirstErrorField() {
            const errorRow = document.querySelector(
                'div.has-errors',
            ) as HTMLElement;

            if (errorRow) {
                const errorField = errorRow.querySelector(
                    'input.has-errors, .input-field.has-errors input, select.has-errors',
                ) as HTMLElement;

                if (errorField) {
                    errorField.focus();
                } else {
                    errorRow.scrollIntoView({
                        block: 'center',
                        inline: 'nearest',
                    });
                }
            }
        },
    },
});
