<template>
    <option :value="value" class="option">{{ text }}</option>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    name: 'FormInputOption',
    props: {
        value: {
            validator: (prop) => typeof prop === 'string' || prop === null,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
    },
});
</script>

<style lang="postcss" scoped>
.option:hover {
    @apply bg-primary text-on-primary;
}
</style>
