






/* global google:readonly */
import Vue from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import { LocationMapContent } from '@/api/interfaces/content/form/base/locationMapContent';

export default Vue.extend({
    name: 'LocationMap',
    props: {
        plantLocationAddress: {
            type: String,
            default: null,
        },
    },
    watch: {
        plantLocationAddress(): void {
            this.handleMap();
        },
    },
    async mounted() {
        this.handleMap();
    },
    methods: {
        handleMap() {
            const apiKey = process.env?.VUE_APP_GOOGLE_MAPS_APIKEY;
            const mapElement = document.getElementById('map') as HTMLElement;

            if (mapElement) {
                const loader = new Loader({
                    apiKey: apiKey,
                    version: 'weekly',
                    libraries: ['places'],
                });

                loader.load().then(() => {
                    let map: google.maps.Map;
                    const address = this.plantLocationAddress
                        ? this.plantLocationAddress
                        : '26122 Oldenburg';
                    const geocoder = new google.maps.Geocoder();

                    geocoder.geocode(
                        { address: address },
                        (results, status) => {
                            if (status == 'OK' && results) {
                                const location = results[0].geometry.location;
                                const position = {
                                    lat: location.lat(),
                                    lng: location.lng(),
                                };

                                map = new google.maps.Map(mapElement, {
                                    center: position,
                                    zoom: 18,
                                    streetViewControl: false,
                                    mapTypeId: 'satellite',
                                    tilt: 45,
                                });

                                const marker = new google.maps.Marker({
                                    position: position,
                                    map,
                                    draggable: true,
                                });

                                if (this.plantLocationAddress) {
                                    this.$emit(
                                        'coordinates-changed',
                                        this.getGPSData(
                                            location.lat(),
                                            location.lng(),
                                        ),
                                    );
                                }

                                google.maps.event.addListener(
                                    marker,
                                    'dragend',
                                    () => {
                                        const markerPosition = marker.getPosition();

                                        if (markerPosition) {
                                            const currentLatitude = markerPosition.lat();
                                            const currentLongitude = markerPosition.lng();

                                            this.$emit(
                                                'coordinates-changed',
                                                this.getGPSData(
                                                    currentLatitude,
                                                    currentLongitude,
                                                ),
                                            );
                                        }
                                    },
                                );
                            }
                        },
                    );
                });
            }
        },
        convertToDegreesMinutesAndSeconds(coordinate: number) {
            const absolute = Math.abs(coordinate);
            const degrees = Math.floor(absolute);
            const minutesNotTruncated = (absolute - degrees) * 60;
            const minutes = Math.floor(minutesNotTruncated);
            const secondsNotTruncated = (minutesNotTruncated - minutes) * 60;
            const secondsNonFormatted =
                Math.trunc(secondsNotTruncated * 10) / 10;
            const seconds = secondsNonFormatted.toString().replace('.', ',');

            const convertedGpsData = {
                degrees: degrees.toString(),
                minutes: minutes.toString(),
                seconds: seconds.toString(),
            };

            return convertedGpsData;
        },
        getGPSData(lat: number, lng: number) {
            const latitude = this.convertToDegreesMinutesAndSeconds(lat);
            const longitude = this.convertToDegreesMinutesAndSeconds(lng);

            const gpsData: LocationMapContent = {
                latitude: {
                    degrees: latitude.degrees,
                    minutes: latitude.minutes,
                    seconds: latitude.seconds,
                },
                longitude: {
                    degrees: longitude.degrees,
                    minutes: longitude.minutes,
                    seconds: longitude.seconds,
                },
            };

            return gpsData;
        },
    },
});
