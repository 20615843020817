




























































































































































































































































































































































































































































































































































































































































































































































import { DocumentsFormStepContent } from '@/api/interfaces/content/form/steps/documentsFormStepContent';
import { getFormStepMixin } from '@/mixins/formStepMixin';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import DatePicker from '@/components/molecules/datePicker/DatePicker.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormInputMultiLineText from '@/components/atoms/formInputMultiLineText/FormInputMultiLineText.vue';
import FormRadioGroup from '@/components/molecules/formRadioGroup/FormRadioGroup.vue';
import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import ButtonSpacer from '@/components/molecules/buttonSpacer/ButtonSpacer.vue';
import { DocumentsFormStep } from '@/types/forms/formSteps/documentsFormStep';
import { DocumentsFormStepConfiguration } from '@/api/interfaces/configuration/form/steps/documentsFormStepConfiguration';
import FormFileUpload from '@/components/molecules/formFileUpload/FormFileUpload.vue';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { proofOfConformityValidator } from '@/services/form/config/application/plantPerformance';
import { isPluginGeneratingPlant } from '@/services/form/rules/application/common';

export default getFormStepMixin<
    DocumentsFormStep,
    DocumentsFormStepContent,
    DocumentsFormStepConfiguration,
    ApplicationForm
>().extend({
    name: 'DocumentsForm',
    components: {
        FormTextBlock,
        FormField,
        DatePicker,
        FormInputText,
        FormInputMultiLineText,
        FormRadioGroup,
        FormBooleanCheckbox,
        ButtonSpacer,
        FormFileUpload,
    },
    computed: {
        showAsteriskForGridAndPlantProtectionProofOfConformity(): boolean {
            const formModel = this.formModel as ApplicationForm;
            return (
                !!this.validations &&
                proofOfConformityValidator(formModel.plantPerformance)
            );
        },
        isPluginGeneratingPlant(): boolean {
            const formModel = this.formModel as ApplicationForm;
            return isPluginGeneratingPlant(formModel);
        },
        showPlantPerformanceHeadline(): boolean {
            return (
                !this.model.disabled &&
                ((!this.model.generatorModules.disabled &&
                    this.model.generatorModules.items.some(
                        (e) =>
                            !e.generatorTechnicalDataSheet.disabled ||
                            !e.generatorProofOfConformity.disabled ||
                            !e.generatorConformityConfirmationOrUnitCertificate
                                .disabled ||
                            !e.generatorUnitOrPrototypeConfirmation.disabled ||
                            !e.generatorComponentCertificate.disabled,
                    )) ||
                    (!this.model.inverterModules.disabled &&
                        this.model.inverterModules.items.some(
                            (e) =>
                                !e
                                    .inverterConformityConfirmationOrUnitCertificate
                                    .disabled ||
                                !e.inverterTechnicalDataSheet.disabled ||
                                !e.inverterProofOfConformity.disabled ||
                                !e.inverterUnitCertificate.disabled,
                        )) ||
                    (!this.model.storageModules.disabled &&
                        this.model.storageModules.items.some(
                            (e) =>
                                !e.storageConformityDeclarationStorageSystem
                                    .disabled ||
                                !e.storageManufacturerDataSheet.disabled,
                        )))
            );
        },
        showPowerConnectionHeadline(): boolean {
            return (
                !this.model.performanceMonitoringCertificate.disabled ||
                !this.model.sitePlanWithLotNumber.disabled ||
                !this.model.electricalSystemCircuitDiagram.disabled
            );
        },
    },
});
