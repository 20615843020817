




























































































































































































































































































































import { AddressService } from '@/services/address/addressService';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormInputTextWithSuggestions from '@/components/atoms/formInputTextWithSuggestions/FormInputTextWithSuggestions.vue';
import DatePicker from '@/components/molecules/datePicker/DatePicker.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormRadioGroup from '@/components/molecules/formRadioGroup/FormRadioGroup.vue';
import FormSelect from '@/components/molecules/formSelect/FormSelect.vue';
import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { OwnerFormGroup } from '@/types/forms/formGroups/ownerFormGroup';
import type { OwnerFormGroupContent } from '@/api/interfaces/content/form/steps/propertyOwnerFormStepContent';
import { ValidationsCommonType } from '@/services/form/validations';

export default getFormInputMixin<
    OwnerFormGroup,
    OwnerFormGroupContent
>().extend({
    name: 'OwnerFormGroup',
    components: {
        FormInputText,
        FormInputTextWithSuggestions,
        DatePicker,
        FormField,
        FormRadioGroup,
        FormSelect,
        FunctionalityButton,
    },
    props: {
        canRemoveItem: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            functionalityButtonValue: 'remove' as FunctionalityButtonValueType,
            addressService: new AddressService(),
            clearableSuggestionFields: {
                zip: {},
                city: {},
                street: {},
            },
        };
    },
    computed: {
        ownerNameValidations(): ValidationsCommonType | unknown {
            return this.validations?.ownerName || {};
        },
        contactPersonNameValidations(): ValidationsCommonType | unknown {
            return this.validations?.contactPersonName || {};
        },
        zipAndCityValidations(): ValidationsCommonType | unknown {
            return this.validations?.zipAndCity || {};
        },
        streetAndHousenumberValidations(): ValidationsCommonType | unknown {
            return this.validations?.streetAndHousenumber || {};
        },
    },
    methods: {
        async getZipSuggestions(query: string): Promise<string[]> {
            return this.model.country.value === 'deu'
                ? this.addressService.getZipSuggestions(
                      query,
                      this.model.zipAndCity,
                      this.model.streetAndHousenumber,
                  )
                : [];
        },
        async getCitySuggestions(query: string): Promise<string[]> {
            return this.model.country.value === 'deu'
                ? this.addressService.getCitySuggestions(
                      query,
                      this.model.zipAndCity,
                      this.model.streetAndHousenumber,
                  )
                : [];
        },
        async getStreetSuggestions(query: string): Promise<string[]> {
            return this.model.country.value === 'deu'
                ? this.addressService.getStreetSuggestions(
                      query,
                      this.model.zipAndCity,
                  )
                : [];
        },
        handleZipBlur() {
            this.resetSuggestions();

            this.getZipSuggestions(this.model.zipAndCity.zip.value ?? '').then(
                () => {
                    this.model.zipAndCity.city.value = this.addressService.setCity();
                },
            );
        },
        resetSuggestions() {
            this.addressService.clearSuggestionFields(
                this.clearableSuggestionFields,
            );
        },
    },
});
