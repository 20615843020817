var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isEdit)?_c('BackgroundOverlay',{staticClass:"flex items-center",attrs:{"active":true}}):_vm._e(),_c('div',{staticClass:"relative lg:mx-16 px-4 py-4 sm:py-6 sm:px-32",class:{
            remaining: _vm.isRemaining || _vm.isRemainingWithData,
            'sm:border-2 mb-10': !(_vm.isRemaining || _vm.isRemainingWithData),
            'z-50': _vm.isEdit,
            'sm:border-surface-variant-200 bg-surface': _vm.isCurrent || _vm.isEdit,
            'sm:border-surface-variant': _vm.isDone,
            'scroll-to-step': _vm.isCurrent && _vm.step !== 1,
        }},[_c('div',{staticClass:"sm:absolute sm:top-0 sm:left-0 sm:mt-6 sm:ml-8",class:{
                'font-normal': !(_vm.isRemaining || _vm.isRemainingWithData),
                'sm:ml-10': _vm.isRemaining || _vm.isRemainingWithData,
            }},[_c('StepNumber',{attrs:{"step":_vm.step,"is-remaining":_vm.isRemaining || _vm.isRemainingWithData}})],1),_c('div',{staticClass:"iconButtons absolute top-0 right-0 mr-8 mt-6 flex items-center"},[_c('SpinnerArea',{staticClass:"inline-block mt-2",attrs:{"state":_vm.loadingState,"size":_vm.spinnerSize}},[(_vm.isDone)?_c('Icon',{staticClass:"text-primary text-3xl cursor-pointer",attrs:{"id":"bleistift","title":_vm.formStepButtonEditText},nativeOn:{"click":function($event){return _vm.$emit('edit-step')}}}):_vm._e(),(_vm.isRemainingWithData)?_c('Icon',{staticClass:"text-primary text-3xl",attrs:{"id":"stoerung","title":_vm.formStepIconRemainingWithDataText}}):_vm._e()],1),(_vm.isEdit)?_c('CloseButton',{staticClass:"closeButton mt-2",attrs:{"title":_vm.formStepButtonCancelText},nativeOn:{"click":function($event){return _vm.$emit('cancel-edit')}}}):_vm._e()],1),_c('div',{class:{ 'mt-1': !(_vm.isRemaining || _vm.isRemainingWithData) }},[_c('span',{staticClass:"block font-normal",class:{
                    'text-primary text-2xl mb-3 lg:text-3xl lg:mb-4 xl:text-4xl xl:mb-5': !(
                        _vm.isRemaining || _vm.isRemainingWithData
                    ),
                    'text-on-surface-variant text-xl mb-3 lg:text-2xl lg:mb-4 xl:text-3xl xl:mb-5':
                        _vm.isRemaining || _vm.isRemainingWithData,
                }},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.heading)}})]),(_vm.isCurrent)?_c('AppAlerts'):_vm._e(),(_vm.isCurrent || _vm.isEdit || _vm.isDone)?_vm._t("default"):_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }