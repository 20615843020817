



















import { PropType } from 'vue';
import { CheckboxContent } from '@/api/interfaces/content/form/base/checkboxContent';
import FormInputCheckbox from '@/components/atoms/formInputCheckbox/FormInputCheckbox.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { CheckboxField } from '@/types/forms/fields/checkboxField';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';
import { CheckboxStyle } from '@/enums/checkboxStyle';

export default getFormInputMixin<CheckboxField, CheckboxContent>().extend({
    name: 'FormBooleanCheckbox',
    components: {
        FieldWithInfoIcon,
        FormInputCheckbox,
    },
    props: {
        checkboxStyle: {
            type: String as PropType<CheckboxStyle>,
            default: CheckboxStyle.standard,
        },
    },
    computed: {
        value: {
            get(): boolean {
                return this.model.value || false;
            },
            set(value: boolean): void {
                this.model.value = value;
            },
        },
    },
});
