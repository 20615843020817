
































import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import PhotovoltaicModuleFormGroup from '@/components/organisms/plantPerformance/photovoltaicModuleFormGroup/PhotovoltaicModuleFormGroup.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import type { PhotovoltaicModuleFormList } from '@/types/forms/formGroups/plantPerformance/photovoltaic/photovoltaicModuleFormList';
import type { PhotovoltaicModuleFormListContent } from '@/api/interfaces/content/form/steps/plantPerformance/photovoltaicContent';
import type { PhotovoltaicModuleFormGroup as PhotovoltaicModuleFormGroupType } from '@/types/forms/formGroups/plantPerformance/photovoltaic/photovoltaicModuleFormGroup';

export default getFormInputMixin<
    PhotovoltaicModuleFormList,
    PhotovoltaicModuleFormListContent
>().extend({
    name: 'PhotovoltaicModuleFormList',
    components: {
        FunctionalityButton,
        PhotovoltaicModuleFormGroup,
    },
    props: {
        applicationId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            functionalityButtonValue: 'add' as FunctionalityButtonValueType,
        };
    },
    computed: {
        canAddItem(): boolean {
            return this.model.canAddItem();
        },
        canRemoveItem(): boolean {
            return this.model.canRemoveItem();
        },
    },
    methods: {
        handleFunctionalityButtonClick(): void {
            this.model.createItem();
        },
        handleModuleFunctionalityButtonClick(
            value: FunctionalityButtonValueType,
            pvModule: PhotovoltaicModuleFormGroupType,
        ): void {
            if (value === 'remove') {
                this.model.removeItem(pvModule);
            }
        },
    },
});
