


































































import { PropType } from 'vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import FormFieldComponent from '@/components/molecules/formField/FormField.vue';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import InverterModuleFormList from '@/components/organisms/plantPerformance/inverterModuleFormList/InverterModuleFormList.vue';
import { InverterFormGroup } from '@/types/forms/formGroups/plantPerformance/inverter/inverterFormGroup';
import { InverterFormGroupContent } from '@/api/interfaces/content/form/steps/plantPerformance/inverterContent';
import type { InverterModuleFormList as InverterModuleFormListType } from '@/types/forms/formGroups/plantPerformance/inverter/inverterModuleFormList';
import { interpolate } from '@/services/interpolationService';
import { formatGermanNumber } from '@/utilities/germanFormats';
import { FormField } from '@/types/forms/fields/formField';
import { InverterModuleFormGroup } from '@/types/forms/formGroups/plantPerformance/inverter/inverterModuleFormGroup';
import { ApplicationType } from '@/types/forms/specific/applicationForm';
import { roundToDecimals } from '@/utilities/math';

export default getFormInputMixin<
    InverterFormGroup,
    InverterFormGroupContent
>().extend({
    name: 'InverterFormGroup',
    props: {
        applicationId: {
            type: String,
            required: true,
        },
        applicationType: {
            type: String as PropType<ApplicationType>,
            required: true,
        },
        isPluginGeneratingPlant: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        FormFieldComponent,
        FormTextBlock,
        InverterModuleFormList,
    },
    computed: {
        overallActivePowerDisplayValue(): string {
            return this.getOverallPowerDisplayValue(
                this.model.inverterOverallActivePower.value,
                this.content.inverterOverallActivePower.unit,
            );
        },
        overallApparentPowerDisplayValue(): string {
            return this.getOverallPowerDisplayValue(
                this.model.inverterOverallApparentPower.value,
                this.content.inverterOverallApparentPower.unit,
            );
        },
    },
    watch: {
        'model.inverterModules': {
            handler(newValue: InverterModuleFormListType) {
                this.model.inverterOverallActivePower.value = this.getTotalPower(
                    newValue,
                    (item) => item.inverterTotalActivePower,
                );

                this.model.inverterOverallApparentPower.value = this.getTotalPower(
                    newValue,
                    (item) => item.inverterTotalApparentPower,
                );
            },
            deep: true,
            immediate: true,
        },
        'model.inverterOverallApparentPower.value'(): void {
            if (this.validations) {
                this.validations.inverterOverallApparentPower.$touch();
            }
        },
    },
    methods: {
        getOverallPowerDisplayValue(
            rawValue: number | null,
            stringToInterpolate: string,
        ): string {
            return interpolate(stringToInterpolate, {
                value: formatGermanNumber(rawValue ?? 0, {
                    decimals: 2,
                    thousandsSeparator: true,
                }),
            });
        },
        getTotalPower(
            formList: InverterModuleFormListType,
            getFormField: (
                formGroup: InverterModuleFormGroup,
            ) => FormField<number>,
        ): number | null {
            return formList === null
                ? null
                : roundToDecimals(
                      formList.items.reduce(
                          (sum, item) => (sum += getFormField(item).value ?? 0),
                          0,
                      ),
                  );
        },
    },
});
