

















import { getFormInputMixin } from '@/mixins/formInputMixin';
import Icon from '@/components/atoms/icon/Icon.vue';

export default getFormInputMixin().extend({
    name: 'FormInputSelect',
    components: {
        Icon,
    },
    props: {
        value: {
            type: String,
            default: null,
        },
        classes: {
            type: [String, Object, Array],
            default: null,
        },
    },
    computed: {
        inputVal: {
            get(): string {
                return this.value;
            },
            set(val: string) {
                this.$emit('input', val);
            },
        },
    },
});
