























































import { getFormInputMixin } from '@/mixins/formInputMixin';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import StorageModuleFormList from '@/components/organisms/plantPerformance/storageModuleFormList/StorageModuleFormList.vue';
import { StorageFormGroup } from '@/types/forms/formGroups/plantPerformance/storage/storageFormGroup';
import { StorageFormGroupContent } from '@/api/interfaces/content/form/steps/plantPerformance/storageContent';
import type { StorageModuleFormList as StorageModuleFormListType } from '@/types/forms/formGroups/plantPerformance/storage/storageModuleFormList';
import { interpolate } from '@/services/interpolationService';
import { formatGermanNumber } from '@/utilities/germanFormats';
import { roundToDecimals } from '@/utilities/math';

export default getFormInputMixin<
    StorageFormGroup,
    StorageFormGroupContent
>().extend({
    name: 'StorageFormGroup',
    props: {
        applicationId: {
            type: String,
            required: true,
        },
    },
    components: {
        FormField,
        FormTextBlock,
        StorageModuleFormList,
    },
    computed: {
        overallDischargePowerDisplayValue(): string {
            const value = this.model.storageOverallDischargePower.value ?? 0;
            return interpolate(this.content.storageOverallDischargePower.unit, {
                value: formatGermanNumber(value, {
                    decimals: 2,
                    thousandsSeparator: true,
                }),
            });
        },

        overallStorageCapacityDisplayValue(): string {
            const value = this.model.storageOverallStorageCapacity.value ?? 0;
            return interpolate(
                this.content.storageOverallStorageCapacity.unit,
                {
                    value: formatGermanNumber(value, {
                        decimals: 2,
                        thousandsSeparator: true,
                    }),
                },
            );
        },
    },
    watch: {
        'model.storageModules': {
            handler(newValue: StorageModuleFormListType) {
                let overallDischargePower = 0;
                if (newValue != null) {
                    overallDischargePower = roundToDecimals(
                        newValue.items.reduce(
                            (sum, item) =>
                                (sum +=
                                    item.storageTotalDischargePower.value ?? 0),
                            0,
                        ),
                    );
                }
                this.setOverallDischargePower(overallDischargePower);

                let overallStorageCapacity = 0;
                if (newValue != null) {
                    overallStorageCapacity = roundToDecimals(
                        newValue.items.reduce(
                            (sum, item) =>
                                (sum +=
                                    item.storageTotalStorageCapacity.value ??
                                    0),
                            0,
                        ),
                    );
                }
                this.setOverallStorageCapacity(overallStorageCapacity);
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        setOverallDischargePower(overallDischargePower: number): void {
            this.model.storageOverallDischargePower.value = overallDischargePower;
        },
        setOverallStorageCapacity(overallStorageCapacity: number): void {
            this.model.storageOverallStorageCapacity.value = overallStorageCapacity;
        },
    },
});
