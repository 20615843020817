







































































import { PropType } from 'vue';
import Icon from '@/components/atoms/icon/Icon.vue';
import SpinnerArea from '../spinnerArea/SpinnerArea.vue';
import { SpinnerState } from '@/enums/spinnerState';
import { UploadedFileInfo } from '@/api/interfaces/file/uploadedFileInfo';
import { fileRepository } from '@/api';
import { SpinnerSize } from '@/enums/spinnerSize';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { UploadField } from '@/types/forms/fields/uploadField';
import { appState } from '@/services/form/appState';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';
import { FileUploadConfiguration } from '@/api/interfaces/configuration/form/base/fileUploadConfiguration';
import { FormFieldConfiguration } from '@/api/interfaces/configuration/form/base/formFieldConfiguration';
import { downloadFile } from '@/services/fileDownloadService';
import { ServiceError } from '@/api/interfaces/serviceError';
import { messageService } from '@/services/messageService';
import { LabelContent } from '@/api/interfaces/content/form/base/labelContent';
import { htmlDecode } from '@/utilities/utils';

export default getFormInputMixin<UploadField, Record<string, string>>().extend({
    name: 'FormFileUpload',
    components: {
        FieldWithInfoIcon,
        Icon,
        SpinnerArea,
    },
    props: {
        applicationId: {
            type: String,
            required: true,
        },
        configuration: {
            type: Object as PropType<FormFieldConfiguration>,
            required: true,
        },
        content: {
            type: Object as PropType<LabelContent>,
            default: () => {
                return {} as LabelContent;
            },
        },
    },
    data() {
        return {
            serverErrorMessage: null as string | null,
            isLoading: false,
        };
    },
    computed: {
        fileUploadConfiguration(): FileUploadConfiguration | null {
            const fileUploadConfiguration =
                ((this.configuration.configurations.find(
                    (c) => c.type === 'fileUploadConfiguration',
                ) as unknown) as FileUploadConfiguration) ?? null;
            return fileUploadConfiguration;
        },
        loadingState(): SpinnerState {
            return this.isLoading ? SpinnerState.transparent : SpinnerState.off;
        },
        spinnerSize: () => SpinnerSize.small,
        acceptedExtensions(): Array<string> {
            const scId = this.fileUploadConfiguration?.scId;
            return scId
                ? this.fileUploadConfiguration?.validations.fileTypes ?? []
                : [];
        },
        accept(): string | null {
            if (this.acceptedExtensions.length === 0) {
                return null; // prevents accept="" from being rendered
            }

            return this.acceptedExtensions
                .map((extension) => `.${extension}`)
                .join(',');
        },
        showUploadButton(): boolean {
            // no file has been selected
            if (this.model.value === null) {
                return true;
            }

            // file has been selected but is still uploading
            if (!this.model.value?.id && this.isLoading) {
                return true;
            }

            return false;
        },
    },
    methods: {
        async uploadFile(file: File) {
            this.serverErrorMessage = null;
            this.model.value = {
                scId: this.fileUploadConfiguration?.scId,
                name: file.name,
                size: file.size,
            } as UploadedFileInfo;

            await this.$nextTick();

            // validate before actually uploading the file
            if (this.hasErrors) {
                return;
            }

            this.setLoading();

            var fileName = file.name;
            if (this.content?.summaryLabel) {
                fileName = `${htmlDecode(
                    this.content.summaryLabel,
                )} ${fileName}`;
            } else if (this.content?.label) {
                fileName = `${htmlDecode(this.content.label)} ${fileName}`;
            }

            fileRepository
                .uploadFile(
                    file,
                    this.fileUploadConfiguration?.scId ?? '',
                    fileName,
                    this.applicationId,
                )
                .then((response) => {
                    this.model.value = response.fileInfo;
                })
                .catch((error: ServiceError) => {
                    this.model.value = null;
                    this.serverErrorMessage = messageService.getErrorMessage(
                        error.messageCode,
                    );
                })
                .finally(() => {
                    this.unsetLoading();
                });
        },
        async downloadFile() {
            this.serverErrorMessage = null;
            await this.$nextTick();

            // validate before actually downloading the file
            if (this.hasErrors) {
                return;
            }

            // do not download, if there is no file
            if (!this.model.value) {
                return;
            }

            this.setLoading();

            fileRepository
                .downloadFile(this.model.value.id, this.applicationId)
                .then((response) => {
                    downloadFile(response.bytes, response.fileName);
                })
                .catch((error: ServiceError) => {
                    this.serverErrorMessage = messageService.getErrorMessage(
                        error.messageCode,
                    );
                })
                .finally(() => {
                    this.unsetLoading();
                });
        },
        deleteFile() {
            // no file has been selected
            if (this.model.value === null) {
                return;
            }

            // file has been selected but wasn't uploaded due too validation errors
            if (this.model.value !== null && this.hasErrors) {
                this.model.value = null;
                return;
            }

            this.serverErrorMessage = null;
            this.setLoading();

            fileRepository
                .deleteFile(this.model.value)
                .then(() => {
                    this.model.value = null;
                })
                .catch((error: ServiceError) => {
                    this.serverErrorMessage = messageService.getErrorMessage(
                        error.messageCode,
                    );
                })
                .finally(() => {
                    this.unsetLoading();
                });
        },
        setLoading(): void {
            this.isLoading = true;
            appState.pendingFileUploads++;
        },
        unsetLoading(): void {
            this.isLoading = false;
            appState.pendingFileUploads--;
        },
    },
});
