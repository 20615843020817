




















import { PropType } from 'vue';
import { TextBlockContent } from '@/api/interfaces/content/form/base/textBlockContent';
import InfoIconWrapper from '@/components/molecules/infoIconWrapper/InfoIconWrapper.vue';
import FormRow from '@/components/molecules/formRow/FormRow.vue';
import { NoValueField } from '@/types/forms/fields/noValueField';
import { getFormElementMixin } from '@/mixins/formElementMixin';

export default getFormElementMixin<
    NoValueField,
    Record<string, string>
>().extend({
    name: 'FormTextBlock',
    components: {
        FormRow,
        InfoIconWrapper,
    },
    props: {
        content: {
            type: Object as PropType<TextBlockContent>,
            default: () => {
                return {} as TextBlockContent;
            },
        },
        model: {
            type: Object,
            default: () => {
                return {};
            },
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        showSummary: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasErrors(): boolean {
            return !!this.validations && this.validations.$anyError;
        },
    },
});
