


















import Vue from 'vue';
import { PropType } from 'vue';
import { FormElement } from '@/types/forms/formElement';
import Icon from '@/components/atoms/icon/Icon.vue';

export default Vue.extend({
    name: 'Accordion',
    components: {
        Icon,
    },
    props: {
        model: {
            type: Object as PropType<FormElement>,
            default: () => {
                return {};
            },
        },
    },
    data: () => {
        return {
            accordionExpanded: false,
        };
    },
    methods: {
        toggleAccordion: function (): void {
            this.accordionExpanded = !this.accordionExpanded;
        },
    },
});
