var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormField',{staticClass:"generator-manufacturer-module-type",attrs:{"model":_vm.model.generatorManufacturerAndModuleType,"content":_vm.content.generatorManufacturerAndModuleType,"validations":_vm.validationProps.generatorManufacturerAndModuleType,"show-summary":_vm.showSummary,"summary-value":_vm._f("concat")([
                _vm.model.generatorManufacturerAndModuleType.manufacturer.value,
                ', ',
                _vm.model.generatorManufacturerAndModuleType.moduleType.value ])}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/2 mr-4 md:mr-10"},[_c('FormInputText',{attrs:{"model":_vm.model.generatorManufacturerAndModuleType
                            .manufacturer,"content":_vm.content.generatorManufacturerAndModuleType
                            .manufacturer,"validations":_vm.generatorManufacturerAndModuleTypeValidations.manufacturer}})],1),_c('div',{staticClass:"w-1/2"},[_c('FormInputText',{attrs:{"model":_vm.model.generatorManufacturerAndModuleType.moduleType,"content":_vm.content.generatorManufacturerAndModuleType
                            .moduleType,"validations":_vm.generatorManufacturerAndModuleTypeValidations.moduleType}})],1)])]),_c('FormField',{staticClass:"generator-quantity-and-generator-type",attrs:{"model":_vm.model.generatorQuantityAndGeneratorType,"content":_vm.content.generatorQuantityAndGeneratorType,"validations":_vm.validationProps.generatorQuantityAndGeneratorType,"show-summary":_vm.showSummary,"summary-value":_vm._f("concat")([
                _vm.model.generatorQuantityAndGeneratorType.quantity.value,
                ' x ',
                _vm.content.generatorQuantityAndGeneratorType.generatorType
                    .options[
                    _vm.model.generatorQuantityAndGeneratorType.generatorType
                        .value
                ] &&
                    _vm.content.generatorQuantityAndGeneratorType.generatorType
                        .options[
                        _vm.model.generatorQuantityAndGeneratorType
                            .generatorType.value
                    ].label ])}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/2 mr-4 md:mr-10"},[_c('FormInputCount',{attrs:{"model":_vm.model.generatorQuantityAndGeneratorType.quantity,"content":_vm.content.generatorQuantityAndGeneratorType.quantity,"validations":_vm.generatorQuantityAndGeneratorTypeValidations.quantity}})],1),_c('div',{staticClass:"w-1/2"},[_c('FormSelect',{attrs:{"model":_vm.model.generatorQuantityAndGeneratorType
                            .generatorType,"content":_vm.content.generatorQuantityAndGeneratorType
                            .generatorType,"validations":_vm.generatorQuantityAndGeneratorTypeValidations.generatorType,"show-info-icon":""}})],1)])]),_c('FormField',{staticClass:"generator-contribution-symmetrical-short-circuit-current",attrs:{"model":_vm.model.generatorContributionSymmetricalShortCircuitCurrent,"content":_vm.content.generatorContributionSymmetricalShortCircuitCurrent,"validations":_vm.validationProps.generatorContributionSymmetricalShortCircuitCurrent,"show-summary":_vm.showSummary,"summary-value":_vm._f("concat")([
                _vm.model.generatorContributionSymmetricalShortCircuitCurrent
                    .value,
                ' ',
                _vm.content.generatorContributionSymmetricalShortCircuitCurrent
                    .unit ])}},[_c('FormInputText',{attrs:{"model":_vm.model.generatorContributionSymmetricalShortCircuitCurrent,"content":_vm.content.generatorContributionSymmetricalShortCircuitCurrent,"validations":_vm.validationProps.generatorContributionSymmetricalShortCircuitCurrent,"align-text":"right","show-info-icon":""}})],1),_c('FormField',{staticClass:"generator-voltage-symmetrical-short-circuit-current",attrs:{"model":_vm.model.generatorVoltageSymmetricalShortCircuitCurrent,"content":_vm.content.generatorVoltageSymmetricalShortCircuitCurrent,"validations":_vm.validationProps.generatorVoltageSymmetricalShortCircuitCurrent,"show-summary":_vm.showSummary,"summary-value":_vm._f("concat")([
                _vm.model.generatorVoltageSymmetricalShortCircuitCurrent.value,
                ' ',
                _vm.content.generatorVoltageSymmetricalShortCircuitCurrent.unit ])}},[_c('FormInputText',{attrs:{"model":_vm.model.generatorVoltageSymmetricalShortCircuitCurrent,"content":_vm.content.generatorVoltageSymmetricalShortCircuitCurrent,"validations":_vm.validationProps.generatorVoltageSymmetricalShortCircuitCurrent,"align-text":"right"}})],1),_c('FormField',{staticClass:"generator-contribution-sustained-short-circuit-current",attrs:{"model":_vm.model.generatorContributionSustainedShortCircuitCurrent,"content":_vm.content.generatorContributionSustainedShortCircuitCurrent,"validations":_vm.validationProps.generatorContributionSustainedShortCircuitCurrent,"show-summary":_vm.showSummary,"summary-value":_vm._f("concat")([
                _vm.model.generatorContributionSustainedShortCircuitCurrent
                    .value,
                ' ',
                _vm.content.generatorContributionSustainedShortCircuitCurrent
                    .unit ])}},[_c('FormInputText',{attrs:{"model":_vm.model.generatorContributionSustainedShortCircuitCurrent,"content":_vm.content.generatorContributionSustainedShortCircuitCurrent,"validations":_vm.validationProps.generatorContributionSustainedShortCircuitCurrent,"align-text":"right"}})],1),_c('FormField',{staticClass:"generator-voltage-sustained-short-circuit-current",attrs:{"model":_vm.model.generatorVoltageSustainedShortCircuitCurrent,"content":_vm.content.generatorVoltageSustainedShortCircuitCurrent,"validations":_vm.validationProps.generatorVoltageSustainedShortCircuitCurrent,"show-summary":_vm.showSummary,"summary-value":_vm._f("concat")([
                _vm.model.generatorVoltageSustainedShortCircuitCurrent.value,
                ' ',
                _vm.content.generatorVoltageSustainedShortCircuitCurrent.unit ])}},[_c('FormInputText',{attrs:{"model":_vm.model.generatorVoltageSustainedShortCircuitCurrent,"content":_vm.content.generatorVoltageSustainedShortCircuitCurrent,"validations":_vm.validationProps.generatorVoltageSustainedShortCircuitCurrent,"align-text":"right","show-info-icon":""}})],1),_c('FormField',{staticClass:"generator-active-power-apparent-power",attrs:{"model":_vm.model.generatorActivePowerAndApparentPower,"content":_vm.content.generatorActivePowerAndApparentPower,"validations":_vm.validationProps.generatorActivePowerAndApparentPower,"show-summary":_vm.showSummary,"summary-value":_vm._f("concat")([
                _vm.model.generatorActivePowerAndApparentPower.activePower
                    .value,
                ' ',
                _vm.content.generatorActivePowerAndApparentPower.activePower
                    .unit,
                ', ',
                _vm.model.generatorActivePowerAndApparentPower.apparentPower
                    .value,
                ' ',
                _vm.content.generatorActivePowerAndApparentPower.apparentPower
                    .unit ])}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/2 mr-4 md:mr-10"},[_c('FormInputText',{attrs:{"model":_vm.model.generatorActivePowerAndApparentPower
                            .activePower,"content":_vm.content.generatorActivePowerAndApparentPower
                            .activePower,"validations":_vm.generatorActivePowerAndApparentPowerValidations.activePower,"align-text":"right"}})],1),_c('div',{staticClass:"w-1/2"},[_c('FormInputText',{attrs:{"model":_vm.model.generatorActivePowerAndApparentPower
                            .apparentPower,"content":_vm.content.generatorActivePowerAndApparentPower
                            .apparentPower,"validations":_vm.generatorActivePowerAndApparentPowerValidations.apparentPower,"align-text":"right"}})],1)])]),_c('FormTextBlock',{staticClass:"generator-overall-active-power-warning text-error",attrs:{"model":_vm.model.generatorTotalActivePowerIsGreaterThanTotalApparentPowerWarning,"content":_vm.content.generatorTotalActivePowerIsGreaterThanTotalApparentPowerWarning}}),_c('FormField',{staticClass:"generator-total-active-power",attrs:{"model":_vm.model.generatorTotalActivePower,"content":_vm.content.generatorTotalActivePower,"validations":_vm.validationProps.generatorTotalActivePower,"show-summary":_vm.showSummary,"summary-value":_vm.totalActivePowerDisplayValue}},[_c('span',{staticClass:"generator-total-active-power-display"},[_vm._v(" "+_vm._s(_vm.totalActivePowerDisplayValue)+" ")])]),_c('FormField',{staticClass:"generator-total-apparent-power",attrs:{"model":_vm.model.generatorTotalApparentPower,"content":_vm.content.generatorTotalApparentPower,"validations":_vm.validationProps.generatorTotalApparentPower,"show-summary":_vm.showSummary,"summary-value":_vm.totalApparentPowerDisplayValue}},[_c('span',{staticClass:"generator-total-apparent-power-display"},[_vm._v(" "+_vm._s(_vm.totalApparentPowerDisplayValue)+" ")])]),(_vm.showSummary)?_c('div',{staticClass:"h-4"}):_vm._e(),(!_vm.showSummary && _vm.showRemoveButton)?_c('FunctionalityButton',{staticClass:"generator-remove-button",attrs:{"content":_vm.content.generatorAddRemoveModule,"value":_vm.functionalityButtonValue},on:{"click":function($event){return _vm.$emit('functionality-button-click', $event)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }