import { PlantPerformanceFormStep } from '@/types/forms/formSteps/plantPerformanceFormStep';
import { PowerConnectionFormStep } from '@/types/forms/formSteps/powerConnectionFormStep';
import {
    formatGermanNumber,
    parseGermanFloat,
} from '@/utilities/germanFormats';
import { getOverallActivePower } from './plantPerformance';
import { ApplicationType } from '@/types/forms/specific/applicationForm';

export function calculateFeedInPower(
    applicationType: ApplicationType | undefined,
    plantPerformanceModel: PlantPerformanceFormStep,
    powerConnectionFormStep: PowerConnectionFormStep,
): number | null {
    if (
        !powerConnectionFormStep.feedInPowerShallBeLimitedByAController
            .disabled &&
        !powerConnectionFormStep.feedInPowerShallBeLimitedByAController.value
    ) {
        const overallActivePower =
            getOverallActivePower(applicationType, plantPerformanceModel) ?? 0;

        const existingPlantsTotalPower = parseGermanFloat(
            !powerConnectionFormStep.existingPlantsTotalPower.disabled
                ? powerConnectionFormStep.existingPlantsTotalPower.value ?? '0'
                : '0',
        );
        const repoweringTotalPower = parseGermanFloat(
            !powerConnectionFormStep.repoweringTotalPower.disabled
                ? powerConnectionFormStep.repoweringTotalPower.value ?? '0'
                : '0',
        );
        powerConnectionFormStep.feedInPower.value = formatGermanNumber(
            overallActivePower +
                existingPlantsTotalPower -
                repoweringTotalPower,
            {
                decimals: 2,
                thousandsSeparator: true,
            },
        );
    }

    return null;
}
