





















import { PropType } from 'vue';
import Icon from '@/components/atoms/icon/Icon.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { CheckboxField } from '@/types/forms/fields/checkboxField';
import { CheckboxContent } from '@/api/interfaces/content/form/base/checkboxContent';
import { CheckboxStyle } from '@/enums/checkboxStyle';

export default getFormInputMixin<CheckboxField, CheckboxContent>().extend({
    name: 'FormInputCheckbox',
    components: {
        Icon,
    },
    methods: {
        cbClicked(e: Event) {
            if (!this.model.userChangeable) {
                e.preventDefault();
            }
        },
    },
    props: {
        value: {
            type: String as PropType<string | undefined>,
            default: undefined,
        },
        checkboxStyle: {
            type: String as PropType<CheckboxStyle>,
            default: CheckboxStyle.standard,
        },
    },
    computed: {
        isSlide(): boolean {
            return this.checkboxStyle === CheckboxStyle.slide;
        },
    },
});
