




























































































































































































































































































































import { PropType } from 'vue';
import FormInputCount from '@/components/molecules/formInputCount/FormInputCount.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormFileUpload from '@/components/molecules/formFileUpload/FormFileUpload.vue';
import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { InverterModuleFormGroup } from '@/types/forms/formGroups/plantPerformance/inverter/inverterModuleFormGroup';
import type { InverterModuleFormGroupContent } from '@/api/interfaces/content/form/steps/plantPerformance/inverterContent';
import type { ValidationsCommonType } from '@/services/form/validations';
import { number } from '@/validators/number';
import { interpolate } from '@/services/interpolationService';
import {
    formatGermanNumber,
    parseGermanFloat,
} from '@/utilities/germanFormats';
import { ApplicationType } from '@/types/forms/specific/applicationForm';
import { roundToDecimals } from '@/utilities/math';

export default getFormInputMixin<
    InverterModuleFormGroup,
    InverterModuleFormGroupContent
>().extend({
    name: 'InverterModuleFormGroup',
    components: {
        FormInputCount,
        FormInputText,
        FormField,
        FormFileUpload,
        FunctionalityButton,
    },
    props: {
        applicationId: {
            type: String,
            required: true,
        },
        applicationType: {
            type: String as PropType<ApplicationType>,
            required: true,
        },
        showRemoveButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            functionalityButtonValue: 'remove' as FunctionalityButtonValueType,
        };
    },
    computed: {
        inverterManufacturerAndModuleTypeValidations():
            | ValidationsCommonType
            | unknown {
            return this.validations?.inverterManufacturerAndModuleType || {};
        },
        inverterActivePowerAndApparentPowerValidations():
            | ValidationsCommonType
            | unknown {
            return this.validations?.inverterActivePowerAndApparentPower || {};
        },
        totalActivePowerDisplayValue(): string {
            return interpolate(this.content.inverterTotalActivePower.unit, {
                value: formatGermanNumber(
                    this.model.inverterTotalActivePower.value ?? 0,
                    {
                        decimals: 2,
                        thousandsSeparator: true,
                    },
                ),
            });
        },
        totalApparentPowerDisplayValue(): string {
            return interpolate(this.content.inverterTotalApparentPower.unit, {
                value: formatGermanNumber(
                    this.model.inverterTotalApparentPower.value ?? 0,
                    {
                        decimals: 2,
                        thousandsSeparator: true,
                    },
                ),
            });
        },
    },
    watch: {
        'model.inverterQuantity.value': {
            handler(newValue: number | null) {
                this.model.inverterTotalActivePower.value = this.getTotalPower(
                    newValue,
                    this.model.inverterActivePowerAndApparentPower.activePower
                        .value,
                );

                this.model.inverterTotalApparentPower.value = this.getTotalPower(
                    newValue,
                    this.model.inverterActivePowerAndApparentPower.apparentPower
                        .value,
                );
            },
            immediate: true,
        },
        'model.inverterActivePowerAndApparentPower.activePower.value': {
            handler(newValue: string | null) {
                this.model.inverterTotalActivePower.value = this.getTotalPower(
                    this.model.inverterQuantity.value,
                    newValue,
                );
            },
            immediate: true,
        },
        'model.inverterActivePowerAndApparentPower.apparentPower.value': {
            handler(newValue: string | null) {
                this.model.inverterTotalApparentPower.value = this.getTotalPower(
                    this.model.inverterQuantity.value,
                    newValue,
                );
            },
            immediate: true,
        },
    },
    methods: {
        getTotalPower(
            rawQuantity: number | null,
            rawPower: string | null,
        ): number {
            // power as number; default: 0
            let power = 0;
            if (rawPower != null) {
                const parsedPower = parseGermanFloat(rawPower);
                power = number(parsedPower) ? parsedPower : 0;
            }

            // quantity as number; default: 0
            let quantity = 0;
            if (rawQuantity != null) {
                quantity = number(rawQuantity) ? rawQuantity : 0;
            }

            return roundToDecimals(quantity * power);
        },
    },
});
