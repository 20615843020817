




















































































import { getFormStepMixin } from '@/mixins/formStepMixin';
import ButtonSpacer from '@/components/molecules/buttonSpacer/ButtonSpacer.vue';
import PhotovoltaicFormGroup from '@/components/organisms/plantPerformance/photovoltaicFormGroup/PhotovoltaicFormGroup.vue';
import StorageFormGroup from '@/components/organisms/plantPerformance/storageFormGroup/StorageFormGroup.vue';
import InverterFormGroup from '@/components/organisms/plantPerformance/inverterFormGroup/InverterFormGroup.vue';
import GeneratorFormGroup from '@/components/organisms/plantPerformance/generatorFormGroup/GeneratorFormGroup.vue';
import { PlantPerformanceFormStep } from '@/types/forms/formSteps/plantPerformanceFormStep';
import { PlantPerformanceFormStepContent } from '@/api/interfaces/content/form/steps/plantPerformanceStepContent';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormFileUpload from '@/components/molecules/formFileUpload/FormFileUpload.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormSelect from '@/components/molecules/formSelect/FormSelect.vue';
import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import FormRadioGroup from '@/components/molecules/formRadioGroup/FormRadioGroup.vue';
import FormCheckboxList from '@/components/molecules/formCheckboxList/FormCheckboxList.vue';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import { isPluginGeneratingPlant } from '@/services/form/rules/application/common';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import { FormStepConfiguration } from '@/api/interfaces/configuration/form/base/formStepConfiguration';

export default getFormStepMixin<
    PlantPerformanceFormStep,
    PlantPerformanceFormStepContent,
    FormStepConfiguration
>().extend({
    name: 'PlantPerformanceForm',
    components: {
        ButtonSpacer,
        PhotovoltaicFormGroup,
        StorageFormGroup,
        InverterFormGroup,
        GeneratorFormGroup,
        FormField,
        FormFileUpload,
        FormInputText,
        FormSelect,
        FormBooleanCheckbox,
        FormRadioGroup,
        FormCheckboxList,
        FormTextBlock,
    },
    computed: {
        isPluginGeneratingPlant(): boolean {
            const formModel = this.formModel as ApplicationForm;
            return isPluginGeneratingPlant(formModel);
        },
    },
});
