





































































































































































































































































































































































import { setCookie, getCookie, deleteCookie } from '@/services/cookieService';
import { LocationFormStepContent } from '@/api/interfaces/content/form/steps/locationFormStepContent';
import { LocationMapContent } from '@/api/interfaces/content/form/base/locationMapContent';
import { getFormStepMixin } from '@/mixins/formStepMixin';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormRadioGroup from '@/components/molecules/formRadioGroup/FormRadioGroup.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import FormInputGeoCoordinates from '@/components/molecules/formInputGeoCoordinates/FormInputGeoCoordinates.vue';
import FormInputTextWithSuggestions from '@/components/atoms/formInputTextWithSuggestions/FormInputTextWithSuggestions.vue';
import LocationMap from '@/components/atoms/locationMap/LocationMap.vue';
import MapWrapper from '@/components/molecules/mapWrapper/MapWrapper.vue';
import ButtonSpacer from '@/components/molecules/buttonSpacer/ButtonSpacer.vue';
import { LocationFormStep } from '@/types/forms/formSteps/locationFormStep';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';
import { FormStepConfiguration } from '@/api/interfaces/configuration/form/base/formStepConfiguration';
import { AddressesService } from '@/services/address/addressesService';
import { ServiceError } from '@/api/interfaces/serviceError';
import { MessageType } from '@/enums/messageType';
import { AlertWithIdentifier, alertService } from '@/services/alertService';
import { AddressesSuggestions } from '@/api/interfaces/address/addressesSuggestions';
import AddressCheckFeedbackFormGroup from './addressCheckFeedbackFormGroup/AddressCheckFeedbackFormGroup.vue';

export default getFormStepMixin<
    LocationFormStep,
    LocationFormStepContent,
    FormStepConfiguration
>().extend({
    name: 'LocationForm',
    components: {
        FormTextBlock,
        FormField,
        FormRadioGroup,
        FormInputText,
        FormBooleanCheckbox,
        FormInputTextWithSuggestions,
        LocationMap,
        FormInputGeoCoordinates,
        ButtonSpacer,
        FieldWithInfoIcon,
        MapWrapper,
        AddressCheckFeedbackFormGroup,
    },
    data() {
        return {
            addressesService: new AddressesService(),

            activeAddressesSuggestions: {
                zipQuery: null,
                zipResult: [],
                cityQuery: null,
                cityResult: [],
                streetQuery: null,
                streetResult: [],
            } as AddressesSuggestions,
            //used to trigger the clearSuggestions method of the VueSimpleSuggest plugin
            clearableSuggestionFields: {
                zip: {},
                city: {},
                street: {},
            },
            currentAddress: null as string | null,
            consentGranted: false,
            mapCheckboxModel: {
                value: false,
                userChangeable: true,
            },
            addressCheckIsLoading: false,
            addressCheckInInitState: true,
        };
    },
    computed: {
        isMapVisible(): boolean {
            return this.consentGranted;
        },
    },
    watch: {
        'mapCheckboxModel.value'() {
            this.mapCheckboxModel.value ? this.enableMap() : this.disableMap();
        },

        /**
         * Pre-load cities or handle cached data
         */
        'model.zipAndCity.zip.value': {
            handler(newValue: string | null, oldValue: string | null): void {
                // First of all, if the value has changed in ANY way, then a possibly existing availability result must be reset
                if (newValue !== oldValue) {
                    if (this.model.availabilityResult.value !== null) {
                        this.addressCheckInInitState = false;
                    }
                    this.model.availabilityResult.value = null;
                    this.model.zipAndCity.city.value = null;
                    this.model.streetAndHousenumber.street.value = null;
                    this.model.streetAndHousenumber.housenumber.value = null;
                }

                // If zip input has finished with a valid zip, make sure proper city suggestions are immediately loaded after
                if (this.model.zipAndCity.zip.isValid()) {
                    this.getCitySuggestions();
                    return;
                }

                // Otherwise handle cache
                if (this.activeAddressesSuggestions.zipQuery === null) {
                    return;
                }

                // If zip was completely removed, just reset all suggestions
                if (newValue === null) {
                    this.resetSuggestions();
                    return;
                }

                // Otherwise, how much we reset depends on how much of the zip has changed
                this.resetCitySuggestions();
                this.resetStreetSuggestions();
                if (
                    !newValue.startsWith(
                        this.activeAddressesSuggestions.zipQuery,
                    )
                ) {
                    this.resetZipSuggestions();
                }
            },
            immediate: false,
        },

        /**
         * Reset cached data, if necessary
         */
        'model.zipAndCity.city.value': {
            handler(newValue: string | null, oldValue: string | null): void {
                // First of all, if the value has changed in ANY way, then a possibly existing availability result must be reset
                if (newValue !== oldValue) {
                    if (this.model.availabilityResult.value !== null) {
                        this.addressCheckInInitState = false;
                    }
                    this.model.availabilityResult.value = null;
                    this.model.streetAndHousenumber.street.value = null;
                    this.model.streetAndHousenumber.housenumber.value = null;
                }

                if (this.activeAddressesSuggestions.streetQuery === null) {
                    return;
                }

                this.resetStreetSuggestions();
            },
            immediate: false,
        },

        /**
         * Reset cached data, if necessary
         */
        'model.streetAndHousenumber.street.value': {
            handler(newValue: string | null, oldValue: string | null): void {
                // First of all, if the value has changed in ANY way, then a possibly existing availability result must be reset
                if (newValue !== oldValue) {
                    if (this.model.availabilityResult.value !== null) {
                        this.addressCheckInInitState = false;
                    }
                    this.model.availabilityResult.value = null;
                    this.model.streetAndHousenumber.housenumber.value = null;
                }
            },
            immediate: false,
        },
    },
    mounted(): void {
        this.initMapData();

        this.handleAddressBlur();
    },
    methods: {
        handleAddressBlur(): void {
            this.currentAddress = this.getCurrentAddress();

            // If all address fields are filled, we need to check availability
            if (
                this.model.zipAndCity.zip.isValid() &&
                this.model.zipAndCity.city.isValid() &&
                this.model.streetAndHousenumber.street.isValid()
            ) {
                this.evaluateAvailability();
            }
        },
        enableMap(): void {
            // Set cookie with a validity of six months
            setCookie('googleMapsConsent', true, 182);
            this.consentGranted = true;
        },
        disableMap(): void {
            deleteCookie('googleMapsConsent');
            this.consentGranted = false;
        },
        initMapData(): void {
            this.consentGranted = this.hasMapConsentCookie();
            this.mapCheckboxModel.value = this.hasMapConsentCookie();
        },
        hasMapConsentCookie(): boolean {
            return getCookie('googleMapsConsent') ? true : false;
        },
        isValidAddress(): boolean {
            return (
                this.model.zipAndCity.zip.isValid() &&
                this.model.zipAndCity.city.isValid() &&
                this.model.streetAndHousenumber.street.isValid()
            );
        },
        getCurrentAddress(): string {
            return (
                this.model.streetAndHousenumber.street.value +
                ' ' +
                this.model.streetAndHousenumber.housenumber.value +
                ', ' +
                this.model.zipAndCity.zip.value +
                ' ' +
                this.model.zipAndCity.city.value
            );
        },
        /** Reset all currently active/cached suggestions */
        resetSuggestions(): void {
            this.resetZipSuggestions();
            this.resetCitySuggestions();
            this.resetStreetSuggestions();
        },
        resetZipSuggestions(): void {
            this.activeAddressesSuggestions.zipQuery = null;
            this.activeAddressesSuggestions.zipResult = [];
        },
        resetCitySuggestions(): void {
            this.activeAddressesSuggestions.cityQuery = null;
            this.activeAddressesSuggestions.cityResult = [];
        },
        resetStreetSuggestions(): void {
            this.activeAddressesSuggestions.streetQuery = null;
            this.activeAddressesSuggestions.streetResult = [];
        },
        getZipSuggestions(): Promise<string[]> {
            // If cached results are available, use those
            if (this.activeAddressesSuggestions.zipQuery !== null) {
                return Promise.resolve().then(
                    () => this.activeAddressesSuggestions.zipResult,
                );
            }

            return this.addressesService
                .getZipSuggestions(this.model.zipAndCity.zip.value ?? '')
                .then((response: string[]) => {
                    this.activeAddressesSuggestions.zipQuery = this.model.zipAndCity.zip.value;
                    this.activeAddressesSuggestions.zipResult = response;

                    return response;
                })
                .catch((error: ServiceError) => {
                    this.activeAddressesSuggestions.zipQuery = null;
                    this.activeAddressesSuggestions.zipResult = [];

                    alertService.clear();
                    switch (error.messageCode) {
                        case 'external.addresses.badSuccess':
                            alertService.pushAlert({
                                alert: {
                                    type: MessageType.error,
                                    identifier: error.messageCode,
                                } as AlertWithIdentifier,
                            });
                            break;
                        default:
                            // Ignore by choice. Visitor should not see an error.
                            break;
                    }

                    return [];
                });
        },
        getCitySuggestions(): Promise<string[]> {
            // Can only retrieve proper suggestions with a valid zip
            if (!this.model.zipAndCity.zip.isValid()) {
                return Promise.resolve().then(() => []);
            }

            // If cached results are available, use those
            if (this.activeAddressesSuggestions.cityQuery !== null) {
                return Promise.resolve().then(
                    () => this.activeAddressesSuggestions.cityResult,
                );
            }

            return this.addressesService
                .getCitySuggestionsByZip(this.model.zipAndCity.zip.value ?? '')
                .then((response: string[]) => {
                    this.activeAddressesSuggestions.cityQuery = this.model.zipAndCity.zip.value;
                    this.activeAddressesSuggestions.cityResult = response;

                    if (response.length === 1) {
                        this.model.zipAndCity.city.value = response[0];
                        this.getStreetSuggestions();
                    }

                    return response;
                })
                .catch((error: ServiceError) => {
                    alertService.clear();
                    switch (error.messageCode) {
                        case 'external.addresses.badSuccess':
                            alertService.pushAlert({
                                alert: {
                                    type: MessageType.error,
                                    identifier: error.messageCode,
                                } as AlertWithIdentifier,
                            });
                            break;
                        default:
                            // Ignore by choice. Visitor should not see an error.
                            break;
                    }

                    return [];
                });
        },
        getStreetSuggestions(): Promise<string[]> {
            // Can only retrieve proper suggestions with a valid zip and city
            if (
                !this.model.zipAndCity.zip.isValid() ||
                !this.model.zipAndCity.city.isValid()
            ) {
                return Promise.resolve().then(() => []);
            }

            // If cached results are available, use those
            if (this.activeAddressesSuggestions.streetQuery !== null) {
                return Promise.resolve().then(
                    () => this.activeAddressesSuggestions.streetResult,
                );
            }

            return this.addressesService
                .getStreetSuggestions(
                    this.model.zipAndCity.zip.value ?? '',
                    this.model.zipAndCity.city.value ?? '',
                )
                .then((response: string[]) => {
                    this.activeAddressesSuggestions.streetQuery = {
                        zip: this.model.zipAndCity.zip.value ?? '',
                        city: this.model.zipAndCity.city.value ?? '',
                    };
                    this.activeAddressesSuggestions.streetResult = response;

                    if (response.length === 1) {
                        this.model.streetAndHousenumber.street.value =
                            response[0];
                    }

                    return response;
                })
                .catch((error: ServiceError) => {
                    alertService.clear();
                    switch (error.messageCode) {
                        case 'external.addresses.badSuccess':
                            alertService.pushAlert({
                                alert: {
                                    type: MessageType.error,
                                    identifier: error.messageCode,
                                } as AlertWithIdentifier,
                            });
                            break;
                        default:
                            // Ignore by choice. Visitor should not see an error.
                            break;
                    }

                    return [];
                });
        },
        evaluateAvailability(): void {
            if (!this.isValidAddress()) {
                return;
            }
            this.addressCheckIsLoading = true;
            this.addressesService
                .getAvailability(
                    this.model.zipAndCity.zip.value ?? '',
                    this.model.zipAndCity.city.value ?? '',
                    this.model.streetAndHousenumber.street.value ?? '',
                )
                .then((response: boolean) => {
                    this.model.availabilityResult.value = response
                        ? 'available'
                        : 'unavailable';

                    this.addressCheckInInitState = false;
                })
                .catch((error: ServiceError) => {
                    alertService.clear();
                    switch (error.messageCode) {
                        case 'external.addresses.badSuccess':
                            alertService.pushAlert({
                                alert: {
                                    type: MessageType.error,
                                    identifier: error.messageCode,
                                } as AlertWithIdentifier,
                            });
                            break;
                        default:
                            // Ignore by choice. Visitor should not see an error.
                            break;
                    }

                    this.model.availabilityResult.value = this.addressesService.parseToBadResponseType(
                        error,
                    );
                })
                .finally(() => {
                    this.addressCheckIsLoading = false;
                });
        },
        handleCoordinatesChanged(coordinatesData: LocationMapContent): void {
            // Latitude values
            this.model.geographicLatCoordinates.degrees.value =
                coordinatesData.latitude.degrees;
            this.model.geographicLatCoordinates.minutes.value =
                coordinatesData.latitude.minutes;
            this.model.geographicLatCoordinates.seconds.value =
                coordinatesData.latitude.seconds;

            // Longitude values
            this.model.geographicLngCoordinates.degrees.value =
                coordinatesData.longitude.degrees;
            this.model.geographicLngCoordinates.minutes.value =
                coordinatesData.longitude.minutes;
            this.model.geographicLngCoordinates.seconds.value =
                coordinatesData.longitude.seconds;
        },
    },
});
