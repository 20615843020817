





























import { PropType } from 'vue';
import { ButtonContent } from '@/api/interfaces/content/form/base/buttonContent';
import InfoIconWrapper from '@/components/molecules/infoIconWrapper/InfoIconWrapper.vue';
import FormRow from '@/components/molecules/formRow/FormRow.vue';
import { NoValueField } from '@/types/forms/fields/noValueField';
import { getFormElementMixin } from '@/mixins/formElementMixin';
import AppLink from '@/components/atoms/appLink/AppLink.vue';
import { LinkHoverBehavior } from '@/enums/linkHoverBehavior';

export default getFormElementMixin<
    NoValueField,
    Record<string, string>
>().extend({
    name: 'FormTextBlock',
    components: {
        FormRow,
        InfoIconWrapper,
        AppLink,
    },
    props: {
        content: {
            type: Object as PropType<ButtonContent>,
            default: () => {
                return {} as ButtonContent;
            },
        },
        model: {
            type: Object,
            default: () => {
                return {};
            },
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        showSummary: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        linkHoverBehavior(): LinkHoverBehavior.default {
            return LinkHoverBehavior.default;
        },
    },
});
