



































































































































































































































































































































































import { getFormStepMixin } from '@/mixins/formStepMixin';
import { GenerationTypeFormStepContent } from '@/api/interfaces/content/form/steps/generationTypeFormStepContent';
import { GenerationTypeFormStep } from '@/types/forms/formSteps/generationTypeFormStep';
import ButtonSpacer from '@/components/molecules/buttonSpacer/ButtonSpacer.vue';
import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import FormCheckboxList from '@/components/molecules/formCheckboxList/FormCheckboxList.vue';
import FormDatePicker from '@/components/molecules/datePicker/DatePicker.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import Accordion from '@/components/molecules/accordion/Accordion.vue';
import FormFileUpload from '@/components/molecules/formFileUpload/FormFileUpload.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormSelect from '@/components/molecules/formSelect/FormSelect.vue';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import FormRadioGroup from '@/components/molecules/formRadioGroup/FormRadioGroup.vue';
import { getCheckboxListSummaryValue } from '@/services/form/checkboxListService';
import { FormStepConfiguration } from '@/api/interfaces/configuration/form/base/formStepConfiguration';
import { ApplicationForm } from '@/types/forms/specific/applicationForm';
import FormButton from '@/components/molecules/formButton/FormButton.vue';
import './generationTypeForm.css';

export default getFormStepMixin<
    GenerationTypeFormStep,
    GenerationTypeFormStepContent,
    FormStepConfiguration,
    ApplicationForm
>().extend({
    name: 'GenerationTypeForm',
    components: {
        ButtonSpacer,
        FormBooleanCheckbox,
        FormCheckboxList,
        FormDatePicker,
        FormField,
        Accordion,
        FormFileUpload,
        FormInputText,
        FormSelect,
        FormTextBlock,
        FormRadioGroup,
        FormButton,
    },
    data() {
        return {};
    },
    computed: {
        emergencyGeneratorOperatingModeSummaryValue(): string {
            return getCheckboxListSummaryValue(
                this.model.emergencyGeneratorOperatingMode,
                this.content.emergencyGeneratorOperatingMode,
            );
        },
    },
});
