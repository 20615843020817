
















































































import StepNumber from '@/components/atoms/stepNumber/StepNumber.vue';
import { StepState } from '@/enums/stepState';
import AppAlerts from '@/components/molecules/appAlerts/AppAlerts.vue';
import Icon from '@/components/atoms/icon/Icon.vue';
import Vue, { PropType } from 'vue';
import { BaseFormContent } from '@/api/interfaces/content/form/base/baseFormContent';
import CloseButton from '@/components/atoms/closeButton/CloseButton.vue';
import BackgroundOverlay from '@/components/atoms/backgroundOverlay/BackgroundOverlay.vue';
import SpinnerArea from '../spinnerArea/SpinnerArea.vue';
import { SpinnerState } from '@/enums/spinnerState';
import { SpinnerSize } from '@/enums/spinnerSize';
import { appState } from '@/services/form/appState';

export default Vue.extend({
    name: 'FormStep',
    components: {
        StepNumber,
        AppAlerts,
        Icon,
        CloseButton,
        BackgroundOverlay,
        SpinnerArea,
    },
    props: {
        content: {
            type: Object as PropType<BaseFormContent>,
            required: true,
        },
        heading: {
            type: String,
            default: null,
        },
        step: {
            type: Number,
            default: 1,
        },
        state: {
            type: String as PropType<StepState>,
            default: StepState.remaining,
        },
    },
    computed: {
        formStepButtonEditText(): string {
            return this.content.common.formStep.buttonEdit.text ?? '';
        },
        formStepIconRemainingWithDataText(): string {
            return (
                this.content.common.formStep.formStepIconRemainingWithData
                    .text ?? ''
            );
        },
        formStepButtonCancelText(): string {
            return this.content.common.formStep.buttonCancel.text ?? '';
        },
        isDone(): boolean {
            return this.state === StepState.done;
        },
        isCurrent(): boolean {
            return this.state === StepState.current;
        },
        isEdit(): boolean {
            return this.state === StepState.edit;
        },
        isRemaining(): boolean {
            return this.state === StepState.remaining;
        },
        isRemainingWithData(): boolean {
            return this.state === StepState.remainingWithData;
        },
        loadingState(): SpinnerState {
            return appState.pendingFileUploads > 0
                ? SpinnerState.transparent
                : SpinnerState.off;
        },
        spinnerSize: () => SpinnerSize.small,
    },
});
