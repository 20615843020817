import { isPv } from '../../rules/application/common';
import { calculateSelfGenerationQuantity } from '../../utilities/application/plantPerformance';
import { EditActionContext } from '../application';

export const measuringConceptsEditActions = async (
    context: EditActionContext,
): Promise<void> => {
    if (isPv(context.formModel)) {
        context.formModel.measuringConcepts.selfGenerationQuantity.value = calculateSelfGenerationQuantity(
            context.formModel,
            context.editModel,
        );
    }
};
