

























import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import OperatorFormGroup from '@/components/organisms/operatorFormGroup/OperatorFormGroup.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import type { OperatorFormListType } from '@/types/forms/formGroups/operatorFormList';
import type { OperatorFormListContent } from '@/api/interfaces/content/form/steps/plantOperatorFormStepContent';

export default getFormInputMixin<
    OperatorFormListType,
    OperatorFormListContent
>().extend({
    name: 'OperatorFormList',
    components: {
        FunctionalityButton,
        OperatorFormGroup,
    },
    computed: {
        functionalityButtonValue(): FunctionalityButtonValueType {
            return this.model.items.length > 1 ? 'remove' : 'add';
        },
    },
    methods: {
        handleFunctionalityButtonClick(
            value: FunctionalityButtonValueType,
        ): void {
            if (value === 'add') {
                this.model.createItem();
            } else {
                this.model.pop();
            }
        },
    },
});
