




















































import FormInputSelect from '@/components/atoms/formInputSelect/FormInputSelect.vue';
import FormInputSelectCustomized from '@/components/atoms/formInputSelectCustomized/FormInputSelectCustomized.vue';
import FormInputOption from '@/components/atoms/formInputOption/FormInputOption.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { FormOption } from '@/types/forms/formOption';
import { SelectContent } from '@/api/interfaces/content/form/base/selectContent';
import { SelectField } from '@/types/forms/fields/selectField';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';

export default getFormInputMixin<SelectField<string>, SelectContent>().extend({
    name: 'FormSelect',
    components: {
        FieldWithInfoIcon,
        FormInputSelect,
        FormInputSelectCustomized,
        FormInputOption,
    },
    props: {
        selectCustomized: {
            type: Boolean,
            default: false,
        },
        selectCustomizedShowSearch: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        enabledOptions(): FormOption<string>[] {
            return this.model.options.filter((options) => !options.disabled);
        },
    },
    watch: {
        enabledOptions: {
            async handler() {
                // do not change value, if it is still enabled
                if (
                    this.enabledOptions.some(
                        (option) => option.key === this.model.value,
                    )
                ) {
                    return;
                }

                // wait additional step to trigger rules again
                await this.$nextTick();

                // if there is just a single option, reset to it, otherwise reset to null
                this.model.value =
                    this.enabledOptions.length === 1
                        ? this.enabledOptions[0].key
                        : null;
            },
            immediate: true,
        },
    },
});
