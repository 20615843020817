

























































import { getFormInputMixin } from '@/mixins/formInputMixin';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import GeneratorModuleFormList from '@/components/organisms/plantPerformance/generatorModuleFormList/GeneratorModuleFormList.vue';
import { GeneratorFormGroupContent } from '@/api/interfaces/content/form/steps/plantPerformance/generatorContent';
import { interpolate } from '@/services/interpolationService';
import { formatGermanNumber } from '@/utilities/germanFormats';
import { GeneratorFormGroup } from '@/types/forms/formGroups/plantPerformance/generator/generatorFormGroup';
import type { GeneratorModuleFormList as GeneratorModuleFormListType } from '@/types/forms/formGroups/plantPerformance/generator/generatorModuleFormList';
import { roundToDecimals } from '@/utilities/math';

export default getFormInputMixin<
    GeneratorFormGroup,
    GeneratorFormGroupContent
>().extend({
    name: 'GeneratorFormGroup',
    props: {
        applicationId: {
            type: String,
            required: true,
        },
    },
    components: {
        FormField,
        FormTextBlock,
        GeneratorModuleFormList,
    },
    computed: {
        overallActivePowerDisplayValue(): string {
            return this.getDisplayValue(
                this.model.generatorOverallActivePower.value,
                this.content.generatorOverallActivePower.unit,
            );
        },
        overallApparentPowerDisplayValue(): string {
            return this.getDisplayValue(
                this.model.generatorOverallApparentPower.value,
                this.content.generatorOverallApparentPower.unit,
            );
        },
    },
    watch: {
        'model.generatorModules': {
            handler(newValue: GeneratorModuleFormListType) {
                let overallActivePower = 0;
                if (newValue != null) {
                    overallActivePower = roundToDecimals(
                        newValue.items.reduce(
                            (sum, item) =>
                                (sum +=
                                    item.generatorTotalActivePower.value ?? 0),
                            0,
                        ),
                    );
                }
                this.model.generatorOverallActivePower.value = overallActivePower;

                let overallApparentPower = 0;
                if (newValue != null) {
                    overallApparentPower = roundToDecimals(
                        newValue.items.reduce(
                            (sum, item) =>
                                (sum +=
                                    item.generatorTotalApparentPower.value ??
                                    0),
                            0,
                        ),
                    );
                }
                this.model.generatorOverallApparentPower.value = overallApparentPower;
            },
            deep: true,
            immediate: true,
        },
        'model.generatorOverallApparentPower.value'(): void {
            if (this.validations) {
                this.validations.generatorOverallApparentPower.$touch();
            }
        },
    },
    methods: {
        getDisplayValue(
            rawValue: number | null,
            stringToInterpolate: string,
        ): string {
            return interpolate(stringToInterpolate, {
                value: formatGermanNumber(rawValue ?? 0, {
                    decimals: 2,
                    thousandsSeparator: true,
                }),
            });
        },
    },
});
