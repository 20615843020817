








































































































































































































































































import { BillingFormStepContent } from '@/api/interfaces/content/form/steps/billingFormStepContent';
import { getFormStepMixin } from '@/mixins/formStepMixin';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import DatePicker from '@/components/molecules/datePicker/DatePicker.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormInputMultiLineText from '@/components/atoms/formInputMultiLineText/FormInputMultiLineText.vue';
import FormRadioGroup from '@/components/molecules/formRadioGroup/FormRadioGroup.vue';
import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import ButtonSpacer from '@/components/molecules/buttonSpacer/ButtonSpacer.vue';
import { BillingFormStep } from '@/types/forms/formSteps/billingFormStep';
import { FormStepConfiguration } from '@/api/interfaces/configuration/form/base/formStepConfiguration';

export default getFormStepMixin<
    BillingFormStep,
    BillingFormStepContent,
    FormStepConfiguration
>().extend({
    name: 'BillingForm',
    components: {
        FormTextBlock,
        FormField,
        DatePicker,
        FormInputText,
        FormInputMultiLineText,
        FormRadioGroup,
        FormBooleanCheckbox,
        ButtonSpacer,
    },
});
