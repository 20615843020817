































































































































































































































































































































































import FormInputCount from '@/components/molecules/formInputCount/FormInputCount.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormFileUpload from '@/components/molecules/formFileUpload/FormFileUpload.vue';
import FormSelect from '@/components/molecules/formSelect/FormSelect.vue';
import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { ValidationsCommonType } from '@/services/form/validations';
import { GeneratorModuleFormGroup } from '@/types/forms/formGroups/plantPerformance/generator/generatorModuleFormGroup';
import { GeneratorModuleFormGroupContent } from '@/api/interfaces/content/form/steps/plantPerformance/generatorContent';
import { number } from '@/validators/number';
import {
    formatGermanNumber,
    parseGermanFloat,
} from '@/utilities/germanFormats';
import { interpolate } from '@/services/interpolationService';
import FormTextBlock from '@/components/molecules/formTextBlock/FormTextBlock.vue';
import { roundToDecimals } from '@/utilities/math';

export default getFormInputMixin<
    GeneratorModuleFormGroup,
    GeneratorModuleFormGroupContent
>().extend({
    name: 'GeneratorModuleFormGroup',
    components: {
        FormInputCount,
        FormInputText,
        FormField,
        FormFileUpload,
        FormSelect,
        FunctionalityButton,
        FormTextBlock,
    },
    props: {
        applicationId: {
            type: String,
            required: true,
        },
        showRemoveButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            functionalityButtonValue: 'remove' as FunctionalityButtonValueType,
        };
    },
    computed: {
        generatorManufacturerAndModuleTypeValidations():
            | ValidationsCommonType
            | unknown {
            return this.validations?.generatorManufacturerAndModuleType || {};
        },
        generatorQuantityAndGeneratorTypeValidations():
            | ValidationsCommonType
            | unknown {
            return this.validations?.generatorQuantityAndGeneratorType || {};
        },
        generatorActivePowerAndApparentPowerValidations():
            | ValidationsCommonType
            | unknown {
            return this.validations?.generatorActivePowerAndApparentPower || {};
        },
        totalActivePowerDisplayValue(): string {
            return interpolate(this.content.generatorTotalActivePower.unit, {
                value: formatGermanNumber(
                    this.model.generatorTotalActivePower.value ?? 0,
                    {
                        decimals: 2,
                        thousandsSeparator: true,
                    },
                ),
            });
        },
        totalApparentPowerDisplayValue(): string {
            return interpolate(this.content.generatorTotalApparentPower.unit, {
                value: formatGermanNumber(
                    this.model.generatorTotalApparentPower.value ?? 0,
                    {
                        decimals: 2,
                        thousandsSeparator: true,
                    },
                ),
            });
        },
    },
    watch: {
        'model.generatorQuantityAndGeneratorType.quantity.value': {
            handler(newValue: number | null) {
                this.model.generatorTotalActivePower.value = this.getTotalPower(
                    newValue,
                    this.model.generatorActivePowerAndApparentPower.activePower
                        .value,
                );

                this.model.generatorTotalApparentPower.value = this.getTotalPower(
                    newValue,
                    this.model.generatorActivePowerAndApparentPower
                        .apparentPower.value,
                );
            },
            immediate: true,
        },
        'model.generatorActivePowerAndApparentPower.activePower.value': {
            handler(newValue: string | null) {
                this.model.generatorTotalActivePower.value = this.getTotalPower(
                    this.model.generatorQuantityAndGeneratorType.quantity.value,
                    newValue,
                );
            },
            immediate: true,
        },
        'model.generatorActivePowerAndApparentPower.apparentPower.value': {
            handler(newValue: string | null) {
                this.model.generatorTotalApparentPower.value = this.getTotalPower(
                    this.model.generatorQuantityAndGeneratorType.quantity.value,
                    newValue,
                );
            },
            immediate: true,
        },
    },
    methods: {
        getTotalPower(
            rawQuantity: number | null,
            rawPower: string | null,
        ): number {
            // power as number; default: 0
            let power = 0;
            if (rawPower != null) {
                const parsedPower = parseGermanFloat(rawPower);
                power = number(parsedPower) ? parsedPower : 0;
            }

            // quantity as number; default: 0
            let quantity = 0;
            if (rawQuantity != null) {
                quantity = number(rawQuantity) ? rawQuantity : 0;
            }

            return roundToDecimals(quantity * power);
        },
    },
});
