import { addressesRepository } from '@/api';
import { ServiceError } from '@/api/interfaces/serviceError';
import { AddressesApiBadResponse } from '@/types/forms/shared/addressesApi/addressesApiBadResponse';
import { isAddressesApiBadResponse } from '@/api/interfaces/typeGuards';

/**
 * Implements various methods for getting and validating addresses.
 *
 * Whether this service deprecates {@link AddressService}, or instead both need better labeling while continuing to be used together,
 * is not yet cleared up with the customer
 *
 * @see [Implementation Epic](https://netzkern.atlassian.net/browse/ED-571)
 */
export class AddressesService {
    public async getZipSuggestions(zip: string): Promise<string[]> {
        return addressesRepository.getZipSuggestions(zip);
    }

    public async getCitySuggestionsByZip(zip: string): Promise<string[]> {
        return addressesRepository.getCitySuggestionsByZip(zip);
    }

    public async getCitySuggestionsByCity(city: string): Promise<string[]> {
        return addressesRepository.getCitySuggestionsByCity(city);
    }

    public async getStreetSuggestions(
        zip: string,
        city: string,
        street?: string | undefined | null,
    ): Promise<string[]> {
        return addressesRepository.getStreetSuggestions(zip, city, street);
    }

    public async getAvailability(
        zip: string,
        city: string,
        street: string,
    ): Promise<boolean> {
        return addressesRepository.getAvailability(zip, city, street);
    }

    /**
     * Parses the error response object to the corresponding bad response type
     * @param error error response object
     * @returns The matching response type based on the convention that the last part of the code use the exact same name. If no match is found, the generic fallback value is used
     */
    public parseToBadResponseType(
        error: ServiceError,
    ): AddressesApiBadResponse {
        const parts = error.messageCode.split('.');
        const parsedType = parts[parts.length - 1];

        return isAddressesApiBadResponse(parsedType)
            ? parsedType
            : 'unspecifiedError';
    }
}
