































































































































































































































import { AddressService } from '@/services/address/addressService';
import { PlantBuilderFormStepContent } from '@/api/interfaces/content/form/steps/plantBuilderFormStepContent';
import { getFormStepMixin } from '@/mixins/formStepMixin';
import FormSelect from '@/components/molecules/formSelect/FormSelect.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormRadioGroup from '@/components/molecules/formRadioGroup/FormRadioGroup.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormBooleanCheckbox from '@/components/molecules/formBooleanCheckbox/FormBooleanCheckbox.vue';
import DatePicker from '@/components/molecules/datePicker/DatePicker.vue';
import FormInputTextWithSuggestions from '@/components/atoms/formInputTextWithSuggestions/FormInputTextWithSuggestions.vue';
import ButtonSpacer from '@/components/molecules/buttonSpacer/ButtonSpacer.vue';
import { PlantBuilderFormStep } from '@/types/forms/formSteps/plantBuilderFormStep';
import { FormStepConfiguration } from '@/api/interfaces/configuration/form/base/formStepConfiguration';

export default getFormStepMixin<
    PlantBuilderFormStep,
    PlantBuilderFormStepContent,
    FormStepConfiguration
>().extend({
    name: 'PlantBuilderForm',
    components: {
        FormField,
        FormRadioGroup,
        FormInputText,
        FormSelect,
        FormBooleanCheckbox,
        FormInputTextWithSuggestions,
        DatePicker,
        ButtonSpacer,
    },
    data() {
        return {
            addressService: new AddressService(),
            clearableSuggestionFields: {
                zip: {},
                city: {},
                street: {},
            },
        };
    },
    methods: {
        async getZipSuggestions(query: string): Promise<string[]> {
            return this.model.country.value === 'deu'
                ? this.addressService.getZipSuggestions(
                      query,
                      this.model.zipAndCity,
                      this.model.streetAndHousenumber,
                  )
                : [];
        },
        async getCitySuggestions(query: string): Promise<string[]> {
            return this.model.country.value === 'deu'
                ? this.addressService.getCitySuggestions(
                      query,
                      this.model.zipAndCity,
                      this.model.streetAndHousenumber,
                  )
                : [];
        },
        async getStreetSuggestions(query: string): Promise<string[]> {
            return this.model.country.value === 'deu'
                ? this.addressService.getStreetSuggestions(
                      query,
                      this.model.zipAndCity,
                  )
                : [];
        },
        handleZipBlur() {
            this.resetSuggestions();

            this.getZipSuggestions(this.model.zipAndCity.zip.value ?? '').then(
                () => {
                    this.model.zipAndCity.city.value = this.addressService.setCity();
                },
            );
        },
        resetSuggestions() {
            this.addressService.clearSuggestionFields(
                this.clearableSuggestionFields,
            );
        },
    },
});
