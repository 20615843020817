



































import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';

export default getFormInputMixin().extend({
    name: 'FormInputGeoCoordinates',
    components: {
        FormInputText,
        FieldWithInfoIcon,
    },
    created() {
        this.$watch('validations.degrees.$dirty', () => this.touchAllInputs());
        this.$watch('validations.minutes.$dirty', () => this.touchAllInputs());
        this.$watch('validations.seconds.$dirty', () => this.touchAllInputs());
    },
    methods: {
        touchAllInputs() {
            if (!this.validations) {
                return;
            }
            if (this.validations.degrees) {
                this.validations.degrees.$touch();
            }
            if (this.validations.minutes) {
                this.validations.minutes.$touch();
            }
            if (this.validations.seconds) {
                this.validations.seconds.$touch();
            }
        },
    },
});
