
















import Vue from 'vue';
export default Vue.extend({
    name: 'StepNumber',
    props: {
        step: {
            type: Number,
            default: 1,
        },
        isRemaining: {
            type: Boolean,
            default: false,
        },
    },
});
