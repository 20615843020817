

























































































































































































import FormInputCount from '@/components/molecules/formInputCount/FormInputCount.vue';
import FormInputText from '@/components/atoms/formInputText/FormInputText.vue';
import FormField from '@/components/molecules/formField/FormField.vue';
import FormFileUpload from '@/components/molecules/formFileUpload/FormFileUpload.vue';
import FunctionalityButton, {
    FunctionalityButtonValueType,
} from '@/components/molecules/functionalityButton/FunctionalityButton.vue';
import { getFormInputMixin } from '@/mixins/formInputMixin';
import { StorageModuleFormGroup } from '@/types/forms/formGroups/plantPerformance/storage/storageModuleFormGroup';
import { StorageModuleFormGroupContent } from '@/api/interfaces/content/form/steps/plantPerformance/storageContent';
import type { ValidationsCommonType } from '@/services/form/validations';
import { number } from '@/validators/number';
import { interpolate } from '@/services/interpolationService';
import {
    formatGermanNumber,
    parseGermanFloat,
} from '@/utilities/germanFormats';
import { roundToDecimals } from '@/utilities/math';

export default getFormInputMixin<
    StorageModuleFormGroup,
    StorageModuleFormGroupContent
>().extend({
    name: 'StorageModuleFormGroup',
    components: {
        FormInputCount,
        FormInputText,
        FormField,
        FormFileUpload,
        FunctionalityButton,
    },
    props: {
        applicationId: {
            type: String,
            required: true,
        },
        showRemoveButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            functionalityButtonValue: 'remove' as FunctionalityButtonValueType,
        };
    },
    computed: {
        storageManufacturerAndStorageTypeValidations():
            | ValidationsCommonType
            | unknown {
            return this.validations?.storageManufacturerAndStorageType ?? {};
        },
        storageDischargePowerAndStorageCapacityValidations():
            | ValidationsCommonType
            | unknown {
            return (
                this.validations?.storageDischargePowerAndStorageCapacity ?? {}
            );
        },
        totalDischargePowerDisplayValue(): string {
            const value = this.model.storageTotalDischargePower.value ?? 0;

            return interpolate(this.content.storageTotalDischargePower.unit, {
                value: formatGermanNumber(value, {
                    decimals: 2,
                    thousandsSeparator: true,
                }),
            });
        },
        totalStorageCapacityDisplayValue(): string {
            const value = this.model.storageTotalStorageCapacity.value ?? 0;

            return interpolate(this.content.storageTotalStorageCapacity.unit, {
                value: formatGermanNumber(value, {
                    decimals: 2,
                    thousandsSeparator: true,
                }),
            });
        },
    },
    watch: {
        'model.storageQuantity.value': {
            handler(newValue: number | null) {
                this.setTotalDischargePower(
                    newValue,
                    this.model.storageDischargePowerAndStorageCapacity
                        .dischargePower.value,
                );
                this.setTotalStorageCapacity(
                    newValue,
                    this.model.storageDischargePowerAndStorageCapacity
                        .storageCapacity.value,
                );
            },
            immediate: true,
        },
        'model.storageDischargePowerAndStorageCapacity.dischargePower.value': {
            handler(newValue: string | null) {
                this.setTotalDischargePower(
                    this.model.storageQuantity.value,
                    newValue,
                );
            },
            immediate: true,
        },
        'model.storageDischargePowerAndStorageCapacity.storageCapacity.value': {
            handler(newValue: string | null) {
                this.setTotalStorageCapacity(
                    this.model.storageQuantity.value,
                    newValue,
                );
            },
            immediate: true,
        },
    },
    methods: {
        setTotalDischargePower(
            rawQuantity: number | null,
            rawDischargePower: string | null,
        ): void {
            // quantity as number; default: 0
            let quantity = 0;
            if (rawQuantity != null) {
                quantity = number(rawQuantity) ? rawQuantity : 0;
            }

            // dischargePower as number; default: 0
            let dischargePower = 0;
            if (rawDischargePower != null) {
                const parsedDischargePower = parseGermanFloat(
                    rawDischargePower,
                );

                dischargePower = number(parsedDischargePower)
                    ? parsedDischargePower
                    : 0;
            }

            this.model.storageTotalDischargePower.value = roundToDecimals(
                quantity * dischargePower,
            );
        },
        setTotalStorageCapacity(
            rawQuantity: number | null,
            rawStorageCapacity: string | null,
        ): void {
            // quantity as number; default: 0
            let quantity = 0;
            if (rawQuantity != null) {
                quantity = number(rawQuantity) ? rawQuantity : 0;
            }

            // storageCapacity as number; default: 0
            let storageCapacity = 0;
            if (rawStorageCapacity != null) {
                const parsedStorageCapacity = parseGermanFloat(
                    rawStorageCapacity,
                );

                storageCapacity = number(parsedStorageCapacity)
                    ? parsedStorageCapacity
                    : 0;
            }

            this.model.storageTotalStorageCapacity.value = roundToDecimals(
                quantity * storageCapacity,
            );
        },
    },
});
